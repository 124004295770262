export default [
  {
    id: 1,
    category: "fireside chat",
    title: "The Future of Creators, Artists & Brands",
    participants: ["MediaLink", "News Not Noise", "UTA"],
    vimeoId: "664046461",
    description:
      "Influence has always shaped the way humans interact with one another. As the business of influence extends far beyond social media and into new territories, what does it mean for the ways that brands can reach and partner with new audiences?",
    speakers: [
      "Jeremy Zimmer, CEO, UTA",
      "Michael Kassan, CEO, MediaLink",
      "Moderator: Jessica Yellin, Founder, News Not Noise",
    ],
    date: "Jan 10th 2022",
    path: "/1",
  },
  {
    id: 2,
    category: "Fireside Chat",
    title: "Next Gen Cadillac Marketing",
    participants: ["Cadillac", "MediaLink"],
    vimeoId: "663529992",
    description:
      "Cadillac is developing ID solutions for a cookie-free world. Learn how the automaker is thinking “audience first” to reach consumers in a rapidly evolving technology landscape.",
    speakers: [
      "Melissa Grady Dias, CMO, Cadillac",
      "Moderator: Michael Kassan, CEO, MediaLink",
    ],
    date: "Jan 10th 2022",
    path: "/2",
  },
  {
    id: 3,
    category: "panel",
    title: "Untangling the Web: Data, Technology & Privacy",
    participants: [
      "Insider Inc",
      "Marriott International",
      "MediaLink",
      "PHD US",
      "TransUnion",
    ],
    vimeoId: "663501231",
    description:
      "A conversation about the evolving role data plays in connecting brands to people. Hear how data gurus and solution-seekers are harnessing data while protecting consumer privacy to power the industry.",
    speakers: [
      "Jon Anselmo, Chief Innovation Officer, PHD US",
      "Nicolette Harper, VP Global Marketing and Media, Marriott International",
      "Jana Meron, SVP of Programmatic & Data Strategy, Insider Inc",
      "Andre Swanston, SVP, Media & Entertainment Vertical, TransUnion",
      "Moderator: Reshma Karnik, VP, MediaLink",
    ],
    date: "Jan 10th 2022",
    path: "/3",
  },
  {
    id: 4,
    category: "Fireside Chat",
    title: "CMO Perspective on Marketing & Innovation",
    participants: ["Discover Financial Services", "MediaLink", "Walgreens"],
    vimeoId: "663450789",
    description:
      "Faced with emerging consumer trends, adoption of new tech, new data and privacy regulation and so much more, CMOs were given a unique opportunity to spearhead change and make lasting impact on their brand and larger industry. Hear from powerhouse CMOs on what needs to be top of mind for marketers.",
    speakers: [
      "Patrick McLean, SVP & CMO, Walgreens",
      "Kate Manfred, SVP & CMO, Discover Financial Services",
      "Moderator: Michael Kassan, CEO, MediaLink",
    ],
    date: "Jan 10th 2022",
    path: "/4",
  },
  {
    id: 5,
    category: "Panel",
    title: "Next, Now: How Insights & Tech Elevate The Consumer Experience ",
    participants: [
      "Ad Age",
      "Initiative",
      "Instacart",
      "NBCUniversal",
      "Sanofi Consumer Health",
      "Smartly.io",
    ],
    vimeoId: "663520849",
    description:
      "With a myriad of learnings coming out of the pandemic, brands have new opportunities to build enduring consumer relationships. Hear how leading marketers are applying customer insights and redesigning the experience through digital excellence and emerging technologies.",
    speakers: [
      "Dana Bhargava, Head of Experience Planning & Media, Sanofi Consumer Health",
      "Joe Cady, EVP, Advanced Advertising & Partnerships, NBCUniversal",
      "Stacy DeRiso, US CEO, Initiative",
      "Ryanne Laredo, Chief Customer Officer, Smartly.io",
      "Josh Rider, VP, Brand Partnerships Strategy, Instacart",
      "Moderator: Garett Sloane, Technology, Digital & Media Reporter, Ad Age",
    ],
    date: "Jan 10th 2022",
    path: "/5",
  },
  {
    id: 6,
    category: "Panel",
    title: "Content & Consumers in a Streaming World",
    participants: ["MediaLink", "Spotter", "Stagwell Inc.", "Twitch"],
    vimeoId: "663522819",
    description:
      "Emerging technology & global platforms have fueled a content revolution. At the same time, consumer consumption habits are changing meaning marketers need to put purpose, innovation and agility at the center of everything they do. In this session, hear how marketers are reinventing their messaging and storytelling methods to resonate with consumers.",
    speakers: [
      "Walker Jacobs, CRO, Twitch",
      "Deirdre McGlashan, Global Chief Media Officer, Stagwell Inc.",
      "Nic Paul, COO, Spotter",
      "Moderator: Mark Wagman, Managing Director, MediaLink",
    ],
    date: "Jan 10th 2022",
    path: "/6",
  },
  {
    id: 7,
    category: "FIRESIDE CHAT",
    title: "Bridging Content & Commerce: Transformative Partnerships ",
    participants: ["Forbes", "NBCUniversal", "TikTok"],
    vimeoId: "663535676",
    description:
      "A whole new commerce ecosystem is blossoming at the intersection of media and technology. New partnerships are uniting content, advertising, product, and platforms. Streaming, social, and smart TVs are bringing livestream commerce directly into our living rooms, while AR and VR are transporting fans directly into their favorite content. Learn how consumers are sparking the reinvention of commerce and re-engineering of content across every screen.",
    speakers: [
      "Sandie Hawkins, GM, North America, Global Business Solutions, TikTok",
      "Linda Yaccarino, Chairman, Global Advertising & Partnerships, NBCUniversal",
      "Moderator: Marty Swant, Staff Writer and Editor, Forbes ",
    ],
    date: "Jan 10th 2022",
    path: "/7",
  },
  {
    id: 8,
    category: "Trends",
    title: "Trends in Ten: Retail",
    participants: ["Ascential"],
    vimeoId: "663489675",
    description:
      "Ascential delivers specialist information, analytics and ecommerce optimization for the world's leading consumer brands and their ecosystems. With the shift to digital commerce accelerating, to survive and thrive brands need the right technology, information and insight to help seize that opportunity. Duncan Painter provides a glimpse into the future of consumer purchasing, the disruptive digital trends impacting retail and what these mean for marketers.",
    speakers: ["Duncan Painter, CEO, Ascential"],
    date: "Jan 10th 2022",
    path: "/8",
  },
  {
    id: 9,
    category: "panel",
    title: "An Open Conversation on Bias in Advertising",
    participants: [
      "The Ad Council",
      "dentsu Americas",
      "IBM",
      "The Kellogg Company",
    ],
    vimeoId: "663482929",
    description:
      "The advertising industry is undergoing a major transformation, with changes to privacy regulations and greater demands for transparency fueling the emergence of new technologies. As brands look to rebuild, now is the time to address unwanted bias in advertising.",
    speakers: [
      "Robert Redmond, Design Principal, Head of AI Ad Product Design, IBM",
      "Elise James DeCruise, Chief Equity Officer, The Ad Council",
      "Christena Pyle, Chief Equity Officer, dentsu Americas",
      "Monica McGurk, Chief Global Growth Officer, The Kellogg Company",
      "Moderator: Bob Lord, IBM Senior Vice President, The Weather Company and Alliances",
    ],
    date: "Jan 10th 2022",
    path: "/9",
  },
  {
    id: 10,
    category: "panel",
    title: "Diverse Representation in Tech & Entertainment ",
    participants: [
      "Black Information Network/iHeartMedia",
      "MediaLink",
      "true[X]/Creative Spirit",
      "Univision",
    ],
    vimeoId: "664055238",
    description:
      "Representation matters. Learn how today’s leading tech and entertainment companies are driving and promoting diversity & inclusion, both within their organizations and in how they service the industry and grow consumer engagement and trust.",
    speakers: [
      "Tony Coles, President, Black Information Network/iHeartMedia",
      "Laurel Rossi, CMO, Gimbal | true[X] and Board Chair, Creative Spirit",
      "Sarah Squiers, EVP, Business Development, Univision",
      "Moderator: Gavin Presman, Global Executive Search Director, MediaLink",
    ],
    date: "Jan 10th 2022",
    path: "/10",
  },
  {
    id: 11,
    category: "Keynote",
    title:
      "Content to Commerce: How Technology Flipped the Customer Experience",
    participants: [
      "DAZN Group",
      "CNBC",
      "Salesforce",
      "McDonald's",
      "GM",
      "Instacart",
      "Target",
      "MediaLink",
    ],
    vimeoId: "663742727",
    description:
      "Digital transformation has completely reshaped consumers’ expectations of two things they love to indulge in: watch entertaining content and shop. Amid a global pandemic when people were restricted from shopping in their favorite stores, hanging out with friends, watching new box office releases in theaters and so much more, brands, technology platforms and entertainment companies were forced to innovate—and innovate again—to keep up with consumers. From new methods of gathering data & insights, building homegrown tools or inking new partnerships, hear how marketers have ramped up efforts to offer experiences that put consumers right where they belong: at the center.",
    speakersFireside: [
      "Michael Kassan, CEO, MediaLink",
      "Kevin Mayer, Chairman, DAZN Group",
      "Moderator: Julia Boorstin, Senior Media & Technology Correspondent, CNBC",
    ],
    speakersPanel: [
      "Sarah Franklin, President & CMO, Salesforce",
      "Tariq Hassan, Chief Marketing & Digital Customer Experience Officer, McDonald's",
      "Edward Kummer, Chief Digital Officer, General Motors",
      "Asha Sharma, Chief Operating Officer, Instacart",
      "Cara Sylvester, EVP & Chief Marketing and Digital Officer, Target",
      "Moderator: Michael Kassan, CEO, MediaLink",
    ],
    date: "Jan 10th 2022",
    path: "/11",
  },
]
