import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import useResizeObserver from "@react-hook/resize-observer"
import { isMobile as deviceMobile, isTablet } from "react-device-detect"
import { Helmet } from "react-helmet"
import TagManager from "react-gtm-module"
import classNames from "classnames"

import gsap, { TweenLite } from "gsap"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

import Logo from "../Logo"
import Contact from "../auxilllary/Contact"
import Expertise from "../Expertise/Expertise"
import Goods from "../Goods/Goods"
import Header from "../Header"
import Modal from "../Modal"
import People from "../People/People"
import Tab from "../home/Tab"

import Event from "../Event"

import "../../styles/index.scss"
import "../home/index.scss"
import "../Footer/Footer.scss"

import { handleViewTracking } from "../../helpers/gtm"
import {
  selectEvent,
  selectSearchOpen,
  selectSecondaryModalStatus,
  selectVisibleEvent,
  setCloseSecondary,
  setSearchOpen,
} from "../../state/reducers/utils"
import CES from "../auxilllary/CES"

export const TABS = {
  EXPERTISE: {
    id: "expertise",
    title: "Expertise",
    pageTitle: "Management Consulting | MediaLink",
    path: "/",
  },
  PEOPLE: {
    id: "people",
    title: "People",
    pageTitle: "People & Careers | MediaLink",
    path: "/people",
  },
  GOODS: {
    id: "goods",
    title: "The Goods",
    pageTitle: "The Goods | Insights, News & Updates | MediaLink",
    path: "/goods",
  },
}

export const auxpages = [
  "404",
  "privacy-policy",
  "terms-of-service",
  "cookie-policy",
  "client-privacy-policy",
]

/** On Desktop, all of the content is wrapped the Tabs system */
const HomeDesktop = ({
  isDisplayingModal,
  isDisplayingAuxPage,
  mainEl,
  modalContent,
  selectedTab,
  setSelectedTab,
  tabToMatch,
  setModalContent,
  showContactForm,
  setFinishedIntro,
  showNav,
  setServiceOpened,
  pageContext,
}) => {
  const event = useSelector(selectEvent)
  const [introFinished, setIntroFinished] = useState(false)
  const [tabClicked, setTabClicked] = useState(false)
  const tabs = Object.values(TABS)

  const tabButtonWidth = 59
  const tl = gsap.timeline({ repeat: false })

  const handleAnimation = (duration = 0) => {
    if (!introFinished || !selectedTab) return
    if (tabClicked) setTabClicked(false)
    const contentWidth = mainEl.current
      ? mainEl.current.offsetWidth - tabButtonWidth * tabs.length
      : null
    TweenLite.to(
      ["#expertise-tab-body", "#goods-tab-body", "#people-tab-body"],
      {
        duration: 0,
        width: contentWidth,
      }
    )
    switch (selectedTab.id) {
      case "expertise":
        tl.to("#expertise", { right: contentWidth, duration })
        tl.to(["#people", "#goods"], { right: 0, duration }, `-=${duration}`)
        break
      case "people":
        tl.to(`#expertise`, {
          duration,
          right: contentWidth,
        })
        tl.to("#people", { right: contentWidth, duration }, `-=${duration}`)
        tl.to("#goods", { right: 0, duration }, `-=${duration}`)
        break
      case "goods":
        tl.to(`#goods`, {
          duration,
          right: contentWidth,
        })
        tl.to(
          ["#expertise", "#people"],
          { right: contentWidth, duration },
          `-=${duration}`
        )
        break
      default:
        break
    }
  }

  useEffect(() => {
    handleAnimation(tabClicked ? 0.375 : 0)
  }, [selectedTab, introFinished])

  useEffect(() => {
    if (!introFinished && selectedTab) {
      const contentWidth = mainEl.current
        ? mainEl.current.offsetWidth - tabButtonWidth * tabs.length
        : null

      tl.to(mainEl.current, {
        left: 0,
        duration: 0.75,
        ease: "ease.power2",
      })
      const tabRight = mainEl.current.offsetWidth - tabButtonWidth * tabs.length
      tl.to(
        `#${selectedTab.id}`,
        {
          right: tabRight,
          duration: 0.5,
          onComplete: () => {
            if (window.innerWidth > 767) {
              setIntroFinished(true)
              setFinishedIntro(true)
            } else {
              setFinishedIntro(true)
            }
          },
        },
        "-=0.75"
      )
      tl.to(
        `#${selectedTab.id}-tab-body`,
        {
          duration: 0,
          width: contentWidth,
        },
        "-=0.5"
      )
    }
  }, [introFinished, selectedTab, event])

  useResizeObserver(mainEl.current, handleAnimation.bind(null, 0))

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: introFinished ? "intro-finished" : "",
        }}
      />

      {isDisplayingAuxPage ? (
        <></>
      ) : (
        tabs.map((tab) => (
          <Tab
            faded={isDisplayingModal && selectedTab.id !== tab.id}
            tabToMatch={tabToMatch}
            isSelected={tabToMatch && tabToMatch.id === tab.id}
            key={tab.id}
            modalOpen={!!modalContent}
            onSelect={(selected) => {
              // scroll tab to top before navigation
              document.getElementById(`${selected.id}-tab-body`).scrollTop = 0
              setTabClicked(true)
              setSelectedTab(selected)
              navigate(`${selected.path}`)
            }}
            setModalContent={setModalContent}
            showContactForm={showContactForm}
            tab={tab}
            showNav={showNav}
            setServiceOpened={setServiceOpened}
            pageContext={pageContext}
          />
        ))
      )}
    </>
  )
}

/** On Mobile, there are no tabs, but we're toggling content in a similar manner */
const HomeMobile = ({
  isDisplayingAuxPage,
  modalContent,
  tabToMatch,
  setModalContent,
  showContactForm,
  showNav,
  setServiceOpened,
  pageContext,
}) => {
  const goNext = (route) => {
    if (window.innerWidth < 768) {
      const tl = gsap.timeline({
        repeat: false,
        onComplete: () => {
          navigate(route)
          window.scrollTo(0, 0)
        },
      })
      tl.fromTo(
        ".home",
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.25,
        }
      )
      tl.play()
    } else {
      // navigate(route)
    }
  }

  const content = () => {
    if (isDisplayingAuxPage || !tabToMatch) return null
    switch (tabToMatch.id) {
      case TABS.EXPERTISE.id:
        return (
          <Expertise
            setModalContent={setModalContent}
            showContactForm={showContactForm}
            selected
            showNav={showNav}
            goToNextSection={() => goNext("/people")}
            setServiceOpened={setServiceOpened}
          />
        )
      case TABS.PEOPLE.id:
        return (
          <People
            goToNextSection={() => goNext("/goods")}
            modalOpen={!!modalContent}
            scroller="people-tab-body"
            selected
            showNav={showNav}
            setModalContent={setModalContent}
            showContactForm={showContactForm}
          />
        )
      case TABS.GOODS.id:
        return (
          <Goods
            goToNextSection={showContactForm}
            scroller="goods-tab-body"
            selected
            showNav={showNav}
            setModalContent={setModalContent}
            pageContext={pageContext}
          />
        )
      default:
        return <></>
    }
  }
  return content()
}

const MainContent = ({ pageContext }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const event = useSelector(selectEvent)
  const searchOpen = useSelector(selectSearchOpen)
  const [basePath, setBasePath] = useState(TABS.EXPERTISE.path)
  const [originalLocation, setOriginaLocation] = useState(null)
  const secondaryModalOpen = useSelector(selectSecondaryModalStatus)

  const indexEl = useRef(null)
  const mainEl = useRef(null)
  const prevScroll = useRef(null)

  const [isMobile, setIsMobile] = useState(null)
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false)
  const [auxModalContent, setAuxModalContent] = useState(null)
  const [auxPage, setAuxPage] = useState(null)
  const [modalContent, setModalContent] = useState(null)
  const [serviceOpened, setServiceOpened] = useState(false)

  const [tabToMatch, setTabToMatch] = useState(null)
  const [selectedTab, setSelectedTab] = useState(null)
  const [showNav, setShowNav] = useState(false)
  const [width, setWidth] = useState(null)
  const [showLandscapeWarning, setShowLandscapeWarning] = useState(false)
  const [loadingMkto, setLoadingMkto] = useState(false)
  const [inEvent, setInEvent] = useState(false)
  const [finishedIntro, setFinishedIntro] = useState(false)
  const [isLandscape, setIsLandscape] = useState(
    typeof window !== "undefined" &&
      window.innerHeight < window.innerWidth &&
      window.innerWidth < 1024 &&
      window.innerWidth > 610
  )

  const tabs = Object.values(TABS)
  const [hasEvent, setHasEvent] = useState(false)
  const visibleEvent = useSelector(selectVisibleEvent)

  useEffect(() => {
    const scriptElement = document.createElement("script")
    scriptElement.src =
      "https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
    scriptElement.strategy = "beforeInteractive"
    scriptElement.setAttribute(
      "data-domain-script",
      process.env.GATSBY_COOKIE_PRO_DOMAIN_ID
    )
    document.head.appendChild(scriptElement)
    const cookieProScript = document.createElement("script")
    cookieProScript.id = "cookie-pro"
    cookieProScript.strategy = "beforeInteractive"
    cookieProScript.text = `
          function OptanonWrapper() { }
        `
    document.head.appendChild(cookieProScript)
  }, [])

  useEffect(() => {
    if (!selectedTab) return
    if (modalContent) {
      if (window.innerWidth >= 768) {
        prevScroll.current = document.getElementById(
          `${selectedTab.id}-tab-body`
        ).scrollTop
      } else {
        prevScroll.current = window.scrollY
      }
      setTimeout(() => {
        document.body.classList.add("no-scroll")
      }, 500)
      // prevScroll.current =
    } else {
      document.body.classList.remove("no-scroll")
      if (
        window.innerWidth >= 768 &&
        document.getElementById(`${selectedTab.id}-tab-body`)
      ) {
        document.getElementById(`${selectedTab.id}-tab-body`).scrollTop =
          prevScroll.current
      } else {
        setTimeout(() => {
          window.scrollTo({ top: prevScroll.current, left: 0 })
        }, 100)
      }
    }
  }, [modalContent])

  useEffect(() => {
    if (!analyticsEnabled) {
      setOriginaLocation(location.href)
      setAnalyticsEnabled(true)
      const tagManagerArgs = {
        gtmId: "GTM-MLZXWTK",
      }
      TagManager.initialize(tagManagerArgs)
    }

    const scriptTag = document.createElement("script")
    scriptTag.src = "https://extend.vimeocdn.com/ga/97969717.js" // set the src attribute
    scriptTag.type = "text/javascript" // if you have an HTML5 website you may want to comment this line out
    scriptTag.defer = true
    const headTag = document.getElementsByTagName("head")[0]
    headTag.appendChild(scriptTag)
  }, [])

  // handles base event tracking
  useEffect(() => {
    if (analyticsEnabled && originalLocation && selectedTab) {
      handleViewTracking(originalLocation, selectedTab.pageTitle)
    }
  }, [location.pathname, location.hash, analyticsEnabled, selectedTab])

  useEffect(() => {
    setShowLandscapeWarning(isLandscape)
    setWidth(window.innerWidth)
  }, [isLandscape])

  useEffect(() => {
    if (!loadingMkto) {
      setLoadingMkto(true)
      setTimeout(() => {
        const scriptTag = document.createElement("script")
        scriptTag.src = "//app-sj11.marketo.com/js/forms2/js/forms2.min.js" // set the src attribute
        scriptTag.type = "text/javascript" // if you have an HTML5 website you may want to comment this line out
        scriptTag.async = true // the HTML5 async attribute
        const headTag = document.getElementsByTagName("head")[0]
        headTag.appendChild(scriptTag)
      }, 5000)
    }
  }, [loadingMkto])

  useEffect(() => {
    if (indexEl.current) setWidth(indexEl.current.innerWidth)
  }, [indexEl])

  const setVh = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    setIsLandscape(
      deviceMobile &&
        !isTablet &&
        typeof window !== "undefined" &&
        window.innerHeight < window.innerWidth &&
        window.innerWidth < 1024 &&
        window.innerWidth > 610
    )
  }

  useEffect(() => {
    if (width) {
      setIsMobile(width < 768)
    }
  }, [width])

  useEffect(() => {
    window.addEventListener("resize", setVh, false)
    window.addEventListener("orientationchange", setVh, false)
    setVh()
    return () => {
      window.removeEventListener("resize", setVh, false)
      window.removeEventListener("orientationchange", setVh, false)
    }
  }, [])

  useEffect(() => {
    const pathName = location.pathname.split("/")[1]
    if (finishedIntro && event && (event.is_public || pathName === "live")) {
      setTimeout(() => setHasEvent(true), pathName === "live" ? 0 : 500)
    }
  }, [event, finishedIntro])

  useEffect(() => {
    const pathName = location.pathname.split("/")[1]
    if (hasEvent && pathName === "live") {
      const liveObj = {
        id: "live",
        title: "Live",
        pageTitle: "Live | Insights, News & Updates | MediaLink",
        path: "/live",
      }
      setInEvent(true)
      setSelectedTab(liveObj)
      setTabToMatch(liveObj)
    }
  }, [location, hasEvent])

  useEffect(() => {
    const pathName = location.pathname.split("/")[1]
    if (pathName === "live") {
      setFinishedIntro(true)
    } else if ((pathName !== basePath || inEvent) && !serviceOpened) {
      // only reset everything if the base path has changed
      setInEvent(false)
      setBasePath(pathName)
      const selection = tabs.find((t) => t.id === pathName)
      setModalContent(null)
      setAuxModalContent(null)
      if (selectedTab) {
        if (window.innerWidth < 768) {
          setTabToMatch(selection || tabs[0])
          const tl = gsap.timeline({
            repeat: false,
          })
          tl.to(".home", {
            opacity: 1,
            duration: 0.25,
          })
          tl.play()
        } else {
          setSelectedTab(selection || tabs[0])
          const tl = gsap.timeline({
            repeat: false,
            onComplete: () => {
              setTabToMatch(selection || tabs[0])
            },
          })
          tl.to(`#${tabToMatch ? tabToMatch.id : "expertise"}-tab-body`, {
            opacity: 0,
            duration: 0.25,
          })
          tl.play()
        }
      } else {
        setSelectedTab(selection || tabs[0])
        setTabToMatch(selection || tabs[0])
      }
    }

    if (serviceOpened) {
      setServiceOpened(false)
    }
  }, [location])

  useEffect(() => {
    const pathName = location.pathname.split("/")[1]
    // if the pathname matches a string in auxpages array, we are viewing an auxillary page
    if (pathName && auxpages.includes(pathName)) {
      setAuxPage(pathName)
    } else {
      setAuxPage(null)
    }
  }, [location])

  useEffect(() => {
    if (!tabToMatch) {
      return
    }
    const tl = gsap.timeline({
      repeat: false,
    })
    tl.to(`.tab-body`, {
      opacity: 1,
      duration: 0,
    })
    tl.play()
  }, [tabToMatch, serviceOpened])

  useResizeObserver(indexEl.current, (m) => setWidth(m.contentRect.width))

  const isDisplayingModal = modalContent

  const showContactForm = () => setAuxModalContent(<Contact />)

  const selectTab = (t) => {
    if (window.innerWidth < 768) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    } else if (document.getElementById(`${t.id}-tab-body`)) {
      document
        .getElementById(`${t.id}-tab-body`)
        .scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
    setSelectedTab(t)
    navigate(`${t.path}`)
  }

  return (
    <div className="wrapper" ref={indexEl}>
      {!showLandscapeWarning ? (
        <>
          {!auxPage && (
            <Event
              setParentModalContent={setModalContent}
              setNoEvent={() => setSelectedTab(tabs[0])}
              setModalContent={setModalContent}
              inEvent={inEvent}
              navToEvent={() =>
                selectTab({ id: "live", path: `/live#${event.uid}` })
              }
              goHome={() =>
                selectTab({
                  id: "expertise",
                  title: "Expertise",
                  path: "/",
                })
              }
              finishedIntro={isMobile || finishedIntro}
            />
          )}
          <div className="main" id="main">
            <Header
              inEvent={inEvent || location.pathname.split("/")[1] === "live"}
              hasEvent={hasEvent}
              faded={!searchOpen && isDisplayingModal}
              mainEl={mainEl}
              selectTab={selectTab}
              showNav={showNav}
              toggleNav={() => setShowNav(!showNav)}
              tabs={Object.values(TABS)}
              finishedIntro={finishedIntro}
              auxPage={auxPage}
              cesPage={location.pathname.split("/")[1] === "ces"}
            />
            {auxPage === "ces" ? <CES /> : null}
            <div>
              {selectedTab && isMobile !== null && (
                <div
                  className={classNames("home", {
                    "home--in-aux": auxPage,
                    "home--modal-open": modalContent,
                  })}
                >
                  <main
                    id="main-container"
                    className={classNames("main-container", {
                      "main-container--visible-event": visibleEvent,
                    })}
                    ref={mainEl}
                    style={{ left: isMobile ? 0 : "100vw" }}
                  >
                    {mainEl &&
                      !auxPage &&
                      (isMobile || showLandscapeWarning ? (
                        <HomeMobile
                          isDisplayingAuxPage={auxPage}
                          isDisplayingModal={isDisplayingModal}
                          mainEl={mainEl}
                          modalContent={modalContent}
                          selectedTab={selectedTab}
                          tabToMatch={tabToMatch}
                          setModalContent={setModalContent}
                          setSelectedTab={setSelectedTab}
                          showContactForm={showContactForm}
                          showNav={showNav}
                          setServiceOpened={setServiceOpened}
                          pageContext={pageContext}
                        />
                      ) : (
                        <HomeDesktop
                          isDisplayingAuxPage={auxPage}
                          isDisplayingModal={isDisplayingModal}
                          mainEl={mainEl}
                          hasEvent={hasEvent}
                          modalContent={modalContent}
                          selectedTab={selectedTab}
                          tabToMatch={tabToMatch}
                          setModalContent={setModalContent}
                          setSelectedTab={setSelectedTab}
                          showContactForm={showContactForm}
                          showNav={showNav}
                          setFinishedIntro={setFinishedIntro}
                          setServiceOpened={setServiceOpened}
                          pageContext={pageContext}
                        />
                      ))}

                    {/* Section Modals */}
                    <Modal
                      modalSection={searchOpen ? "search" : selectedTab.id}
                      closeModal={() => {
                        dispatch(setSearchOpen(false))
                        if (secondaryModalOpen) {
                          dispatch(setCloseSecondary(true))
                        } else {
                          setModalContent(null)
                          if (selectedTab.id === TABS.EXPERTISE.id) {
                            navigate(TABS.EXPERTISE.path) // TODO change to basePath for more modals?
                          }
                          if (selectedTab.id === TABS.PEOPLE.id) {
                            navigate(TABS.PEOPLE.path) // TODO change to basePath for more modals?
                          }
                          if (selectedTab.id === TABS.GOODS.id) {
                            navigate(TABS.GOODS.path) // TODO change to basePath for more modals?
                          }
                        }
                      }}
                      content={modalContent}
                      withinTab={selectedTab ? selectedTab.id : null}
                    />

                    {/* Aux Modals */}
                    <Modal
                      closeModal={() => {
                        setAuxModalContent(null)
                      }}
                      content={auxModalContent}
                      isAux
                    />
                  </main>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="landscape-alert">
          <Logo fill="rgba(255,255,255, 0.25)" />
          <h3>Please rotate your phone.</h3>
          <h3>
            This site is best enjoyed in <br /> portrait mode.
          </h3>
        </div>
      )}
    </div>
  )
}

export default MainContent
