/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from "react"
import moment from "moment-timezone"
import gsap from "gsap"
import Prismic from "@prismicio/client"
import { navigate } from "gatsby"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "@reach/router"
import { PrismicText } from "@prismicio/react"
import { asText } from "@prismicio/helpers"
import classNames from "classnames"

import {
  selectEvent,
  selectFullScreenVideo,
  selectSecondaryModalStatus,
  selectVideoPlayer,
  setCloseSecondary,
  setEvent,
  setVisibleEvent,
} from "../../state/reducers/utils"
import SpeakersSeries from "./Speakers"
import FullScreenVideo from "../FullScreenVideo"
import EventPartners from "./Partners"
import RSVP from "./RSVP"
import { FooterContent } from "../Footer/Footer"
import Logo from "../Logo"
import ArrowIconWhite from "../../images/icons/arrow-down-icon-white.svg"
import ArrowIcon from "../../images/icons/arrow-down-icon.svg"
import VolumeOnIcon from "../../images/icons/volume-on-icon.svg"
import VolumeOffIcon from "../../images/icons/volume-off-icon.svg"
import VolumeOnBlackIcon from "../../images/icons/volume-on-black-icon.svg"
import VolumeOffBlackIcon from "../../images/icons/volume-off-black-icon.svg"
import EventGif from "../../images/event-icon.gif"
import Modal from "../Modal"
import "./index.scss"

gsap.registerPlugin(ScrollTrigger)

const client = Prismic.client(process.env.GATSBY_PRISMIC_API_ENDPOINT, {
  accessToken: process.env.GATSBY_PRISMIC_API_KEY,
})

const Event = ({
  navToEvent,
  goHome,
  setNoEvent,
  setParentModalContent,
  finishedIntro,
}) => {
  const dispatch = useDispatch()
  const secondaryModalOpen = useSelector(selectSecondaryModalStatus)
  const fullScreenVideo = useSelector(selectFullScreenVideo)
  const videoPlayer = useSelector(selectVideoPlayer)
  const topper = useRef(null)
  const lowerEl = useRef(null)
  const contentEl = useRef(null)
  const event = useSelector(selectEvent)
  const location = useLocation()
  const showContentTimeout = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [showRSVP, setShowRSVP] = useState(false)
  const [showLive, setShowLive] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [muted, setMuted] = useState(true)
  const [height, setHeight] = useState(true)
  const [width, setWidth] = useState(true)
  const [inLower, setInLower] = useState(false)
  const [whiteLogo, setWhiteLogo] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [belowDesktop, setBelowDesktop] = useState(false)
  const [eventChecked, setEventChecked] = useState(false)
  const [inEvent, setInEvent] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [hasPartners, setHasPartners] = useState(false)
  const [rsvped, setRsvped] = useState(false)
  const goToEvent = () => {
    navToEvent()
  }

  const handleVolume = (audioOn) => {
    if (!videoPlayer) return
    if (audioOn) {
      setMuted(false)

      if (videoPlayer.unMute) {
        videoPlayer.unMute()
        videoPlayer.setVolume(100)
      } else {
        videoPlayer.setVolume(1)
      }
    } else if (!audioOn) {
      setMuted(true)
      if (videoPlayer.unMute) {
        videoPlayer.mute()
        videoPlayer.setVolume(0)
      } else {
        videoPlayer.setVolume(0)
      }
    }
  }

  const watchNow = () => {
    handleVolume(true)
    setShowLive(true)
    setPlaying(true)
  }

  const retrieveEvent = () => {
    setEventChecked(true)
    client
      .query(Prismic.predicates.at("document.type", "live"), {
        fetchLinks: ["event.uid", "event.is_public"],
      })
      .then(async (data) => {
        const pathName = location.pathname.split("/")[1]
        const currentEvent =
          data && data.results.length && data.results[0].data.current_event.data
        if (!currentEvent || !currentEvent.is_public) {
          dispatch(setVisibleEvent(false))
        } else {
          dispatch(setVisibleEvent(true))
        }
        if (currentEvent) {
          client
            .query(Prismic.Predicates.at("my.event.uid", currentEvent.uid), {
              lang: "*",
              fetchLinks: [
                "person.name",
                "person.image",
                "person.uid",
                "person.position",
                "person.company",
                "person.linkedin",
                "person.twitter_url",
                "person.bio",
                "person.specialty",
                "person.goods",
                "person.uid",
                "media.image",
                "media.uid",
                "media.title",
                "guest.name",
                "guest.image",
                "guest.position",
                "guest.company",
                "guest.linkedin",
                "guest.twitter_url",
                "guest.bio",
                "guest.specialty",
                "guest.goods",
                "guest.uid",
              ],
            })
            .then((eventResponse) => {
              const eventResult = eventResponse && eventResponse.results[0]
              const eventToSet = {
                ...eventResult.data,
                speaker_series: eventResult.data.body,
                uid: currentEvent.uid,
              }
              delete eventToSet.body
              dispatch(setEvent(eventToSet))
            })
        } else if (pathName === "live") {
          setNoEvent()
          navigate("/")
        }
      })
  }

  useEffect(() => {
    let isMounted = true
    if (!eventChecked) {
      setTimeout(() => {
        if (isMounted) {
          retrieveEvent()
        }
      }, 1000)
    }
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (!lowerEl.current) return
    const scrollTriggerConfig = {
      start: "top-=100px center",
      toggleActions: "play play play reverse",
    }
    if (window.innerWidth >= 768) {
      scrollTriggerConfig.scroller = contentEl.current
    }
    gsap.timeline({
      scrollTrigger: {
        ...scrollTriggerConfig,
        trigger: lowerEl.current,
        start: "top-=30px top",
        onEnter: () => setInLower(true),
        onLeaveBack: () => setInLower(false),
      },
    })
  }, [])

  useEffect(() => {
    setWhiteLogo(showLive && !inLower)
  }, [showLive, inLower])

  useEffect(() => {
    const keyboardClose = (e) => {
      if (e.keyCode === 27 && showLive) {
        setShowLive(false)
      }
    }
    document.addEventListener("keydown", keyboardClose, false)
    return () => {
      document.removeEventListener("keydown", keyboardClose, false)
    }
  }, [showLive])

  useEffect(() => {
    if (showLive) {
      setPlaying(true)
    } else if (!showLive && !playing) {
      setMuted(true)
    }
  }, [showLive, playing])

  useEffect(() => {
    if (!playing) {
      setShowLive(false)
    }
  }, [playing])

  useEffect(() => {
    if (event && event.is_public && finishedIntro) {
      setLoaded(true)
    }
  }, [finishedIntro, event])

  useEffect(() => {
    const pathName = location.pathname.split("/")[1]
    if (pathName === "live") {
      setInEvent(true)
      setParentModalContent(null)
    } else {
      setModalContent(null)
    }
    if (!event) return
    if (event && event.is_public) {
      // setTimeout(() => setLoaded(true), 500)
    } else if (event && !event.is_public && pathName === "live") {
      setLoaded(true)
    } else {
      setLoaded(false)
    }
    if (pathName !== "live") {
      setShowRSVP(false)
    } else if (event.is_public) {
      setPlaying(true)
    }
    const hash = location.hash && location.hash.replace(/#/g, "")
    if (pathName === "live" && event && event.uid !== hash) {
      setNoEvent()
      navigate("/")
      setInEvent(false)
    } else if (pathName !== "live") {
      setInEvent(false)
    }
    if (event) {
      const filteredPartners = event.partnerships.filter(
        (partner) => partner.partner_link.url
      )
      setHasPartners(filteredPartners.length > 0)
    }
  }, [event, location])

  useEffect(() => {
    clearTimeout(showContentTimeout.current)
    if (inEvent) {
      setShowContent(true)
    } else {
      showContentTimeout.current = setTimeout(() => setShowContent(false), 750)
    }
  }, [inEvent])

  useEffect(() => {
    if (!event) return
    if (!inEvent) {
      setShowLive(false)
      setMuted(true)
    }
  }, [inEvent, event])

  const toggleMute = () => {
    handleVolume(muted)
    setPlaying(true)
  }

  const Trigger = () => (
    <div className="event__trigger" onClick={goToEvent}>
      <div className={classNames("icon", { "icon--live": event.live_or_rsvp })}>
        <div className="icon-inner">
          <svg viewBox="0 0 60 83">
            <path
              className="svg-bg"
              d="M0,0v84h60V0H0z M42,44c0,5.5-4.5,10-10,10h-4c-5.5,0-10-4.5-10-10v-4c0-5.5,4.5-10,10-10h4c5.5,0,10,4.5,10,10V44z"
            />
            <path
              className="svg-square"
              d="M42,44c0,5.5-4.5,10-10,10h-4c-5.5,0-10-4.5-10-10v-4c0-5.5,4.5-10,10-10h4c5.5,0,10,4.5,10,10V44z"
            />
          </svg>
          <img src={EventGif} alt="Event texture" />
        </div>
        <div className="back-fill" />
      </div>
      <span>Live</span>
    </div>
  )

  return (
    <>
      {event && (
        <div
          className={classNames("event", {
            "event--live": event.live_or_rsvp,
            "event--watching": showLive,
            "event--loaded": loaded,
            "event--in-modal": modalContent,
            "event--expanded": inEvent,
            "event--full-screen-open": fullScreenVideo,
          })}
        >
          <Trigger />
          <div className="event__content" ref={contentEl}>
            <div
              className="event__content__topper topper"
              ref={topper}
              style={{ opacity: showContent ? 1 : 0 }}
            >
              <Trigger />
              <FullScreenVideo
                autoPlay
                onPause={() => setShowLive(false)}
                onEnded={() => setShowLive(false)}
                showLive={showLive}
                belowDesktop={belowDesktop}
                setBelowDesktop={setBelowDesktop}
                parentMuted={muted}
                setParentMuted={() => handleVolume(muted)}
                parentPlaying={playing}
                setParentPlaying={setPlaying}
                setParentHeight={setHeight}
                setParentWidth={setWidth}
                loop={!event.live_or_rsvp}
                isRSVP={!event.live_or_rsvp}
                showControls={showLive}
                video={
                  event.live_or_rsvp
                    ? event.video
                    : {
                        embed_url:
                          "https://prismic-io.s3.amazonaws.com/medialink/0464a4db-7a4e-4b86-94b9-34e85655b8da_default-horizontal.mp4",
                        width: 1616,
                        height: 1072,
                      }
                }
              />
              <div className="topper__inner">
                <h1 style={belowDesktop ? { height, width } : {}}>
                  {event.event_title.map((line, index) => (
                    <span key={`h1-${index}`}>
                      <PrismicText field={line.event_title_line} />
                    </span>
                  ))}
                </h1>
                <div className="topper__meta">
                  <div className="topper__column">
                    {event.featured_partner_link.url && (
                      <div className="topper__partner">
                        <span>
                          {event.featured_partnership_label &&
                          asText(event.featured_partnership_label) !== "" ? (
                            <PrismicText
                              field={event.featured_partnership_label}
                            />
                          ) : (
                            "IN PARTNERSHIP WITH"
                          )}
                        </span>
                        <a
                          rel="noreferrer"
                          href={event.featured_partner_link.url}
                          target="_blank"
                        >
                          <img
                            className="desktop"
                            src={event.featured_partner_logo.url}
                            alt={asText(event.featured_partner_name)}
                          />
                          <img
                            className="below-desktop"
                            src={event.tablet_mobile_logo.url}
                            alt={asText(event.featured_partner_name)}
                          />
                        </a>
                      </div>
                    )}
                    {event.live_or_rsvp ? (
                      <>
                        <button
                          type="button"
                          className="topper__rsvp"
                          onClick={watchNow}
                        >
                          <span>Watch Now</span>
                        </button>
                        <button
                          type="button"
                          className="topper__mute"
                          onClick={toggleMute}
                        >
                          <span>{muted ? "Unmute" : "Mute"}</span>
                          <img
                            src={muted ? VolumeOffIcon : VolumeOnIcon}
                            alt="Volume Icon"
                            className="desktop"
                          />
                          <img
                            src={muted ? VolumeOffBlackIcon : VolumeOnBlackIcon}
                            alt="Volume Icon"
                            className="below-desktop"
                          />
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="topper__rsvp"
                        onClick={() => !rsvped && setShowRSVP(true)}
                      >
                        <span>{rsvped ? "SEE YOU THERE" : "RSVP"}</span>
                      </button>
                    )}
                  </div>
                  <div className="topper__column topper__column--right">
                    <img
                      src={ArrowIconWhite}
                      alt="Arrow Down"
                      className="desktop"
                    />
                    <img
                      src={ArrowIcon}
                      alt="Arrow Down"
                      className="below-desktop"
                    />
                    <div>
                      <span className="topper__dateline">
                        {moment(event.event_time)
                          .tz("America/New_York")
                          .format("MM/DD/YY | h:m z")}
                      </span>
                      <span className="topper__description">
                        <PrismicText field={event.event_description} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="event__content__lower"
              id="event__content__lower"
              ref={lowerEl}
            >
              <SpeakersSeries event={event} setModalContent={setModalContent} />
              {hasPartners && <EventPartners event={event} rsvped={rsvped} />}
              {!event.live_or_rsvp && !rsvped && (
                <div className="event__bigrsvp">
                  <button
                    type="button"
                    className="nextContainer"
                    onClick={() => setShowRSVP(true)}
                  >
                    <div className="nextContainer__inner">
                      <p>RSVP</p>
                      <p>
                        {event.event_title.map((line, index) => (
                          <span key={`footer-h1-${index}`}>
                            <PrismicText field={line.event_title_line} />
                          </span>
                        ))}
                      </p>
                    </div>
                  </button>
                </div>
              )}
            </div>
            <FooterContent
              selected={location.pathname.split("/")[1] === "live"}
              footerId="event"
            />
            {!event.live_or_rsvp && (
              <RSVP
                event={event}
                setRsvped={setRsvped}
                rsvpText={event.rsvp_text}
                showRSVP={showRSVP}
                hideRSVP={() => setShowRSVP(false)}
                mktoId={parseInt(asText(event.rsvp_marketo_id), 10)}
                dateTime={moment(event.event_time)
                  .tz("America/New_York")
                  .format("MM/DD/YY | h:m z")}
                description={asText(event.rsvp_note)}
              />
            )}
            <Modal
              modalSection="event"
              closeModal={() => {
                if (secondaryModalOpen) {
                  dispatch(setCloseSecondary(true))
                } else {
                  setModalContent(null)
                }
              }}
              content={modalContent}
              withinTab
            />
          </div>
          <div type="button" className="event__back-home" onClick={goHome}>
            <Logo fill={whiteLogo ? "#fff" : "#230F2C"} />
          </div>
        </div>
      )}
    </>
  )
}

export default Event
