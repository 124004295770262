import React, { useRef } from "react"
import "./PageHeader.scss"

const PageHeader = ({ text, headerRef }) => {
  const h1Ref = useRef(null)
  return (
    <div className="page-header" ref={headerRef}>
      <video
        src="https://prismic-io.s3.amazonaws.com/medialink/881f0714-25d2-44c0-ae67-63f73e91593e_hero.mp4"
        loop
        autoPlay
        muted
        playsInline
      />
      <h1 ref={h1Ref}>
        <div className="h1-inner">{text}</div>
      </h1>
    </div>
  )
}

export default PageHeader
