import React, { useRef } from "react"
import classNames from "classnames"
import { osVersion, isMobileSafari, isSafari } from "react-device-detect"

import mlLogo from "./images/ceslogo.jpg"
import arrowDownIcon from "../../../../images/icons/arrow-down-icon.svg"
import PageHeader from "../../../common/PageHeader"
import headerBG from "../../../../images/shared/header-bg.webp"
import headerBGPNG from "../../../../images/shared/header-bg.png"

import "./CESHero.scss"

const CESHero = ({ selected, heroRef }) => {
  const hero = useRef(null)
  return (
    <section className={classNames("expertise-hero")} ref={heroRef}>
      <PageHeader
        text="Welcome to MediaLink CES 2022"
        selected={selected}
        background={
          isSafari || (isMobileSafari && osVersion.match(/13\.|12\./g))
            ? headerBGPNG
            : headerBG
        }
      />
      <div className="expertise-hero__intro" ref={hero}>
        <img alt="MediaLink" src={mlLogo} />
        <div>
          <img alt="Arrow down icon" src={arrowDownIcon} />
          <p>
            Dive into our on demand “Marketing Reinvented” program track,
            featuring tech pioneers in marketing, entertainment, media and
            content exploring disruptive trends; as well as our official CES C
            Space Keynote. We’ve also partnered with Yahoo to bring you a
            virtual tour recorded directly from the CES show floor, giving you
            an up-close look at the exhibitors on the ground in Vegas making the
            biggest waves that will matter profoundly to the media and marketing
            community in 2022 and beyond.
          </p>
        </div>
      </div>
    </section>
  )
}

export default CESHero
