import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import gsap from "gsap"
import { TimelineMax } from "gsap/all"

function Number({
  id,
  pn,
  pni,
  currentIndex,
  setWidth,
  setHeight,
  animationComplete,
  startDelay,
}) {
  const ref = useRef(null)
  const prevIndex = useRef(currentIndex)
  const timeline = useRef(null)

  const [timelineAvailable, setTimelineAvailabe] = useState(false)

  useEffect(() => {
    if (currentIndex === -1 && pni === 0) {
      setWidth(ref.current.offsetWidth)
      setHeight(ref.current.offsetHeight + 30)
    }
    if (!timelineAvailable) return
    if (currentIndex === pni) {
      const delay =
        (prevIndex.current === 0 && currentIndex === -1) ||
        (prevIndex.current === -1 && currentIndex === 0)
          ? startDelay
          : 500
      setTimeout(() => {
        setWidth(ref.current.offsetWidth)
        setHeight(ref.current.offsetHeight + 30)
      }, delay)
      setTimeout(() => {
        timeline.current.play()
      }, delay * 2)
    } else if (currentIndex > pni) {
      timeline.current.timeScale(2)
      timeline.current.reverse()
    } else if (currentIndex < pni) {
      if (currentIndex > -1) {
        timeline.current.timeScale(2)
      }
      timeline.current.reverse()
    }
    prevIndex.current = currentIndex
  }, [currentIndex, timelineAvailable, pni])

  useEffect(() => {
    if (!timeline.current) {
      timeline.current = new TimelineMax({
        paused: true,
        onComplete: () => {
          setTimeout(animationComplete, 750)
          timeline.current.timeScale(1)
        },
        onReverseComplete: () => animationComplete,
      })
      setTimelineAvailabe(true)
      const tl = gsap.timeline({})
      pn.numberSplit.forEach((_, di) => {
        tl.to(
          ref.current.querySelector(`.numberFigureDigit${pni}${di}`),
          {
            y: 0,
            opacity: 1,
            duration: 0.5,
          },
          0.125 * (di + 1)
        )
      })
      tl.to(
        ref.current.querySelector(`.numberCaption${pni}`),
        {
          y: 10,
          opacity: 1,
          duration: 0.25,
        },
        0.5 + pn.numberSplit.length * 0.125
      )
      timeline.current.add(tl)
    }
  }, [timeline])

  return (
    <figure
      ref={ref}
      className={classNames("numberFigure", `numberFigure${pni}`, {
        "numberFigure--active": currentIndex === pni,
      })}
    >
      {pn.numberSplit.map((digit, di) => (
        <span
          key={`${id}digit${digit}${di}`}
          className={classNames(
            "numberFigureDigit",
            `numberFigureDigit${pni}${di}`
          )}
        >
          {digit}
        </span>
      ))}
      <figcaption
        className={classNames("numberCaption", `numberCaption${pni}`)}
        dangerouslySetInnerHTML={{ __html: pn.caption }}
      />
    </figure>
  )
}

export default Number
