import React from "react"
import classNames from "classnames"

import "./index.scss"

const Hamburger = ({ background = "#230F2C", isActive, onClick }) => (
  <button
    className={classNames("hamburger", {
      open: isActive,
      closed: !isActive,
    })}
    onClick={onClick}
    type="button"
  >
    <span style={{ background }} />
    <span style={{ background }} />
    <span style={{ background }} />
  </button>
)

export default Hamburger
