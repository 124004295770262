import React, { useRef } from "react"
import classNames from "classnames"
import { osVersion, isMobileSafari, isSafari } from "react-device-detect"

import openerImage from "../../../images/people/medialink.webp"
import openerImagePNG from "../../../images/people/medialink.jpg"

import "./Hero.scss"

function Hero() {
  const hero = useRef(null)

  return (
    <figure
      className={classNames("people-section__figure")}
      id="people-hero"
      ref={hero}
    >
      <div className="img-wrapper">
        <img
          src={
            isSafari || (isMobileSafari && osVersion.match(/13\.|12\./g))
              ? openerImagePNG
              : openerImage
          }
          alt="placeholder"
        />
        <div className="img-wrapper__mask" />
      </div>
    </figure>
  )
}

export default Hero
