import React, { useState, useRef } from "react"
import MouseTooltip from "react-sticky-mouse-tooltip"
import gsap from "gsap"
import "./HoverTooltip.scss"

function HoverTooltip({ text, style, children }) {
  const wrapperEl = useRef(null)
  const tipRef = useRef(false)
  const xRef = useRef(null)
  const yRef = useRef(null)
  const [tipVisible, setTipVisible] = useState(false)

  const showTip = () => {
    const supportsHover = matchMedia("(hover: hover)").matches
    if (!supportsHover) return
    setTipVisible(true)
    tipRef.current = true
    gsap.fromTo(
      wrapperEl.current.querySelector(".tooltip"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.375,
      }
    )
  }

  const hideTip = () => {
    setTipVisible(false)
    tipRef.current = false
  }

  const trackMouse = (e) => {
    xRef.current = e.screenX
    yRef.current = e.screenY
  }

  return (
    <div
      ref={wrapperEl}
      style={{ ...style }}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onMouseMove={trackMouse}
    >
      <MouseTooltip
        visible={tipVisible}
        offsetX={15}
        offsetY={10}
        className="tooltip"
      >
        <span className="tooltipText">{text}</span>
      </MouseTooltip>
      {children}
    </div>
  )
}

export default HoverTooltip
