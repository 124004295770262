import React, { useEffect, useRef, useState } from "react"
import { TweenLite } from "gsap"
import { always, cond, equals, T } from "ramda"
import classNames from "classnames"

import Expertise from "../../Expertise/Expertise"
import Goods from "../../Goods/Goods"
import People from "../../People/People"

import { handleScrollTracking } from "../../../helpers/gtm"

import "./index.scss"

export const TAB = {
  EXPERTISE: "expertise",
  PEOPLE: "people",
  GOODS: "goods",
}

const Tab = ({
  faded,
  isSelected,
  modalOpen,
  onSelect,
  setModalContent,
  showContactForm,
  tab,
  showNav,
  setServiceOpened,
  pageContext,
}) => {
  const [enabledScrollTracking, setEnabledScrollTracking] = useState(false)
  const { id, title } = tab

  const contentEl = useRef(null)

  useEffect(() => {
    if (!contentEl) return
    setEnabledScrollTracking(false)
    TweenLite.to(contentEl.current, {
      duration: 0.2,
      opacity: isSelected ? 1 : 0,
      onComplete: () => setEnabledScrollTracking(true),
    })
  }, [isSelected])

  const renderPeople = () => (
    <People
      goToNextSection={() => {
        document.getElementById("goods-tab-body").scrollTop = 0
        onSelect({ id: "goods", title: "The Goods", path: "/goods" })
      }}
      onSelect={onSelect}
      modalOpen={modalOpen}
      scroller="people-tab-body"
      selected={isSelected}
      setModalContent={setModalContent}
      showContactForm={showContactForm}
      showNav={showNav}
    />
  )

  const renderExpertise = () => (
    <Expertise
      goToNextSection={() => {
        document.getElementById("people-tab-body").scrollTop = 0
        onSelect({ id: "people", title: "People", path: "/people" })
      }}
      onSelect={onSelect}
      selected={isSelected}
      showContactForm={showContactForm}
      setModalContent={setModalContent}
      showNav={showNav}
      setServiceOpened={setServiceOpened}
    />
  )

  const renderGoods = () => (
    <Goods
      goToNextSection={showContactForm}
      scroller="goods-tab-body"
      selected={isSelected}
      setModalContent={setModalContent}
      showNav={showNav}
      pageContext={pageContext}
    />
  )

  const getContent = cond([
    [equals(TAB.EXPERTISE), always(renderExpertise())],
    [equals(TAB.PEOPLE), always(renderPeople())],
    [equals(TAB.GOODS), always(renderGoods())],
    [T, always(null)],
  ])

  const renderContent = () => isSelected && getContent(id)

  return (
    <div id={id} className="tab">
      <button
        className={classNames("tab-button", { faded })}
        onClick={onSelect.bind(null, tab)}
        type="button"
      >
        <span>{title}</span>
      </button>

      <div className="tab-content">
        <div
          onScroll={(e) => enabledScrollTracking && handleScrollTracking(e)}
          id={`${id}-tab-body`}
          className="tab-body"
        >
          <div className="tab-content__inner" ref={contentEl}>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab
