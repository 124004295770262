/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from "react"
import classNames from "classnames"
import { navigate } from "gatsby"
import Prismic from "@prismicio/client"
import HoverTooltip from "../../common/HoverTooltip"
import ExpertiseModal from "../ExpertiseModal"

import services from "../../../static/data/services.json"

import "./Services.scss"

const client = Prismic.client(process.env.GATSBY_PRISMIC_API_ENDPOINT, {
  accessToken: process.env.GATSBY_PRISMIC_API_KEY,
})

const Services = ({
  setModalContent,
  showContactForm,
  serviceLink,
  setServiceOpened,
  onSelect,
}) => {
  const bgVideos = [
    "https://prismic-io.s3.amazonaws.com/medialink/a49243a1-ced6-4b09-bd80-8e11b82f79c6_marketingtransformation-horizontal.mp4",
    "https://prismic-io.s3.amazonaws.com/medialink/01ea93f3-d5e6-4382-953c-7c80a6e79227_datatechsolutions-horizontal.mp4",
    "https://prismic-io.s3.amazonaws.com/medialink/7927d471-60cf-4e05-a15e-eefe48775751_growthstrategy-horizontal.mp4",
    "https://prismic-io.s3.amazonaws.com/medialink/43b79519-e41c-434b-ba00-97a461929b65_privateequity-horizontal.mp4",
    "https://prismic-io.s3.amazonaws.com/medialink/9f99d7e1-aa3a-4fb6-9317-f9117632e8a5_executivesearch-horizontal.mp4",
    "https://prismic-io.s3.amazonaws.com/medialink/480a4434-f777-4f6f-9aef-5a0b811c4a5c_uta-entertainment-culture-marketing-horizontal.mp4",
  ]
  // const mobileBgVideos = [
  //   "https://prismic-io.s3.amazonaws.com/mltest/a8dbe46d-5890-4e7f-9809-09c7cdb2c9ee_marketing-transformation.mp4",
  //   "https://prismic-io.s3.amazonaws.com/mltest/18c6bd06-1d0a-4238-a35e-9c7af7147194_data-tech-solutions.mp4",
  //   "https://prismic-io.s3.amazonaws.com/mltest/9ec6bcb4-0327-4e8f-a640-82b79716aff1_growth-strategy.mp4",
  //   "https://prismic-io.s3.amazonaws.com/mltest/1bbfda85-7d8a-4c63-834b-5ef7bc157184_private-equity.mp4",
  //   "https://prismic-io.s3.amazonaws.com/mltest/03bd4631-b171-4636-8004-d544b1e16380_executive-search.mp4",
  // ]
  const servicesRef = useRef(null)
  const [hoverIndex, setHoverIndex] = useState(null)
  const [serviceHash, setServiceHash] = useState(serviceLink)
  const [bgPosition, setBgPosition] = useState("top")
  const [servicesData, setServicesData] = useState(null)

  const closeModal = (e, goToGoods) => {
    e.stopPropagation()
    setModalContent(null)
    window.location.hash = ""
    setServiceHash(null)
    if (goToGoods) {
      if (onSelect) {
        setTimeout(() => {
          onSelect({ id: "goods", title: "The Goods", path: "/goods" })
        }, 750)
      } else {
        setTimeout(() => {
          navigate("/goods")
        }, 250)
      }
    }
  }

  const watchScroll = () => {
    const scrollEl =
      window.innerWidth < 768
        ? window
        : document.getElementById("expertise-tab-body")
    const offset =
      window.innerWidth < 768 ? window.scrollY : scrollEl && scrollEl.scrollTop
    if (offset > servicesRef.current.offsetTop) {
      if (
        offset + window.innerHeight >
        servicesRef.current.offsetTop + servicesRef.current.offsetHeight
      ) {
        setBgPosition("below")
      } else {
        setBgPosition("fixed")
      }
    } else {
      setBgPosition("top")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", watchScroll, true)
    return () => {
      window.removeEventListener("scroll", watchScroll, true)
    }
  })

  useEffect(() => {
    setServiceHash(serviceLink)
  }, [serviceLink])

  useEffect(() => {
    if (serviceHash) {
      const index = services.findIndex(
        (service) => service.slug === serviceHash.replace("#", "")
      )

      if (index > -1) {
        setModalContent(
          <ExpertiseModal
            data={services}
            serviceIndex={index}
            closeModal={closeModal}
            showContactForm={showContactForm}
            setServiceOpened={setServiceOpened}
            servicesData={servicesData}
          />
        )
      } else {
        setModalContent(null)
      }
    } else {
      setModalContent(null)
    }
  }, [serviceHash, servicesData])

  const watchHash = () => {
    if (window.location.hash && window.location.hash !== "") {
      setServiceHash(window.location.hash)
    } else {
      setServiceHash(null)
    }
  }

  useEffect(() => {
    window.addEventListener("hashchange", watchHash, false)
    return () => {
      window.removeEventListener("hashchange", watchHash, false)
    }
  })

  const loadServices = () => {
    client
      .query(Prismic.predicates.at("document.type", "practice_areas"))
      .then((data) => {
        setServicesData(data.results[0].data)
      })
  }

  useEffect(() => {
    loadServices()
  }, [])

  return (
    <section className="services" ref={servicesRef}>
      <div
        className={`services__background ${
          bgPosition === "fixed"
            ? "fixed"
            : bgPosition === "below"
            ? "below"
            : ""
        }`}
      >
        {services.map((service, i) => (
          <div
            key={service.id}
            className={classNames("services__background__image", {
              "has-hover": hoverIndex === i,
            })}
          >
            <video src={bgVideos[i]} loop autoPlay muted />
          </div>
        ))}
      </div>

      <div className="services__inner">
        <h2 className="services__heading">We help navigate change.</h2>
        <div className="services__body">
          <p className="services__body-p">
            No two clients are the same. That’s why we’ve never come up with the
            same solution twice. Instead, we identify your specific areas of
            opportunity and then tap into our team of best-in-class consultants
            to accelerate success.
          </p>
          <p className="services__body-p">
            Are you looking to change brand perception? Drive revenue? Stay
            ahead of the market? Or maybe all of the above? We do that. Plus a
            lot more. Sometimes with invention. Sometimes reinvention. But
            always by connecting instinct and intent with insight and access.
          </p>
        </div>

        <div className="services__content">
          <ol>
            {services.map((service, i) => (
              <li
                key={service.id}
                className={classNames("services__content-li", {
                  "fade-out": hoverIndex !== null && hoverIndex !== i,
                })}
              >
                <HoverTooltip text="Learn more">
                  <a href={`#${service.slug}`}>
                    <div
                      className="services__content__item"
                      role="button"
                      tabIndex={0}
                      onMouseOver={() => {
                        setHoverIndex(i)
                      }}
                      onMouseLeave={() => {
                        setHoverIndex(null)
                      }}
                      onFocus={() => {
                        setHoverIndex(i)
                      }}
                      onBlur={() => {
                        setHoverIndex(null)
                      }}
                    >
                      <p>{service.title}</p>
                      <p>{service.description}</p>
                    </div>
                  </a>
                </HoverTooltip>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </section>
  )
}

export default Services
