import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)
class GoodsAnimations {
  constructor() {
    this.destroy = this.destroyTimelines
    this.initDataAnimations = this.dataDrivenAnimations
    this.initFilterAnimations = this.filteredAnimations
    this.init()
  }

  init() {
    this.goodsEl = document.getElementById("goods-section")
    this.mediaBlockWrappers = null
    this.goodsTl = {}

    this.standardFrom = {
      opacity: 0,
      y: 20,
    }

    this.standardTo = {
      opacity: 1,
      y: 0,
      duration: 0.75,
    }

    this.initGoods()
  }

  destroyTimelines() {
    this.destoryGoods()
  }

  initGoods() {
    this.configDefault = {
      start: "top-=100px center",
      toggleActions: "play play play reverse",
    }
    if (window.innerWidth >= 768) {
      this.configDefault.scroller = "#goods-tab-body"
    }

    this.destroyGoods()

    this.goodsTl.opener = gsap.timeline()

    this.goodsTl.opener.to(
      this.goodsEl.querySelector(".page-header"),
      this.standardTo,
      0
    )

    this.goodsTl.opener.play()
  }

  dataDrivenAnimations() {
    const mainHeroEl = this.goodsEl.querySelector(".mainHero")
    this.goodsTl.hero = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: mainHeroEl,
        start: "top bottom-=80px",
      },
    })

    this.goodsTl.hero.to(
      mainHeroEl.querySelector(".mainHero__img"),
      this.standardTo,
      0.125
    )

    this.goodsTl.hero.to(
      mainHeroEl.querySelector(".img-wrapper__mask"),
      {
        bottom: "100%",
        duration: 0.625,
      },
      0.25
    )

    this.goodsTl.cta = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: this.goodsEl.querySelector(".ctaWrapper"),
        start: "center bottom-=80px",
      },
    })

    this.goodsTl.cta.fromTo(
      this.goodsEl.querySelector(".ctaWrapper"),
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
      },
      0
    )
    const heroMedia = this.goodsEl.querySelector(".heroesContainer")
    gsap.set(
      heroMedia.querySelectorAll(".mediaBlockWrapper"),
      this.standardFrom
    )
    this.mediaBlockWrappers = ScrollTrigger.batch(
      heroMedia.querySelectorAll(".mediaBlockWrapper"),
      {
        interval: 0.1,
        batchMax: 2,
        onEnter: (batch) =>
          gsap.fromTo(batch, this.standardFrom, {
            ...this.standardTo,
            stagger: { each: 0.25 },
            overwrite: true,
          }),
        onLeaveBack: (batch) =>
          gsap.to(batch, {
            ...this.standardFrom,
            duration: 0.75,
            stagger: { each: 0.25 },
            overwrite: true,
          }),
        start: "top bottom-=60px",
        scroller: this.configDefault.scroller,
      }
    )
  }

  filteredAnimations() {
    const mediaContainer = this.goodsEl.querySelector(".mediaContainer")
    gsap.set(
      mediaContainer.querySelectorAll(".mediaBlockWrapper"),
      this.standardFrom
    )
    this.filteredmediaBlockWrappers = ScrollTrigger.batch(
      mediaContainer.querySelectorAll(".mediaBlockWrapper"),
      {
        interval: 0.1,
        batchMax: 2,
        onEnter: (batch) =>
          gsap.fromTo(batch, this.standardFrom, {
            ...this.standardTo,
            stagger: { each: 0.25 },
            overwrite: true,
          }),
        onLeaveBack: (batch) =>
          gsap.to(batch, {
            ...this.standardFrom,
            duration: 0.75,
            stagger: { each: 0.25 },
            overwrite: true,
          }),
        start: "top bottom-=60px",
        scroller: this.configDefault.scroller,
      }
    )
  }

  destroyGoods() {
    Object.keys(this.goodsTl).forEach((tlKey) => {
      this.goodsTl[tlKey].kill()
    })
    if (this.mediaBlockWrappers) {
      this.mediaBlockWrappers.forEach((tl) => {
        tl.kill()
      })
    }
  }
}
export default GoodsAnimations
