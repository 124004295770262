import React, { useState, useEffect } from "react"
import { PrismicText } from "@prismicio/react"
import { asText } from "@prismicio/helpers"
import FeaturedPerson from "../../People/FeaturedPerson"
import Manager from "../../People/Manager"
import PeopleModal from "../../People/PeopleModal"
import "./index.scss"

const Speakers = ({ speakersData, setModalContent, seriesIndex }) => {
  const [featuredSpeaker, setFeaturedSpeaker] = useState(null)
  const [speakers, setSpeakers] = useState([])

  useEffect(() => {
    setFeaturedSpeaker(speakersData.primary.keynote_speaker.data)
    setSpeakers(speakersData.items.map((speaker) => speaker.speaker.data))
  }, [speakersData])

  const closeModal = () => {
    setModalContent(null)
  }

  const openPerson = (personIndex) => {
    setModalContent(
      <PeopleModal
        people={featuredSpeaker ? [featuredSpeaker, ...speakers] : speakers}
        personIndex={personIndex}
        closeModal={closeModal}
        inEvent
      />
    )
  }

  return (
    <div className="speakers">
      <h2>
        {asText(speakersData.primary.series_title) !== "" ? (
          <PrismicText field={speakersData.primary.series_title} />
        ) : (
          "Speakers"
        )}
      </h2>
      <div className="speakers__featured">
        {featuredSpeaker && (
          <FeaturedPerson
            person={featuredSpeaker}
            inEvent
            openModal={() => openPerson(0)}
          />
        )}
      </div>
      <div className="speakers__list">
        {speakers.map((speaker, index) =>
          speaker ? (
            <button
              type="button"
              key={seriesIndex + asText(speaker.name)}
              onClick={() => openPerson(featuredSpeaker ? index + 1 : index)}
            >
              <Manager person={speaker} inEvent />
            </button>
          ) : null
        )}
      </div>
    </div>
  )
}

const SpeakersSeries = ({ event, setModalContent }) => (
  <div className="speakers-series">
    {event.speaker_series.map((series, index) => (
      <Speakers
        key={`speaker-series-${index}`}
        speakersData={series}
        seriesIndex={index}
        setModalContent={setModalContent}
      />
    ))}
  </div>
)

export default SpeakersSeries
