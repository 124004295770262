import React, { useState, useEffect } from "react"
import classNames from "classnames"
import Prismic from "@prismicio/client"
import { asText } from "@prismicio/helpers"

import MediaBlock from "../../MediaBlock/MediaBlock"

import "./Hero.scss"

const client = Prismic.client(process.env.GATSBY_PRISMIC_API_ENDPOINT, {
  accessToken: process.env.GATSBY_PRISMIC_API_KEY,
})

const Hero = ({ onSelect, heroRef }) => {
  const [heroData, setHeroData] = useState(null)

  // fetches hero data
  useEffect(() => {
    if (!heroData) {
      client
        .query(Prismic.Predicates.at("document.type", "the_goods"))
        .then((pageData) => {
          const { data } = pageData.results[0]
          const articleIds = [
            data.first_highlighted_media.id,
            data.second_highlighted_media.id,
            data.third_highlighted_media.id,
          ]

          client
            .query(
              Prismic.Predicates.in(
                "document.id",
                articleIds.filter((id) => id)
              )
            )
            .then((articleData) => {
              setHeroData({
                heroBg: data.first_highlighted_media_image.url,
                mainHeroMediaTitle: asText(data.first_highlighted_media_title),
                heroArticles: articleData.results,
              })
            })
            .catch((error) => console.error(error))
        })
    }
  })

  if (heroData && heroData.heroArticles.length > 0) {
    return (
      <div className="heroesContainer" ref={heroRef}>
        <div className={classNames("mainHero")}>
          <img className="mainHero__img" alt="hero-bg" src={heroData.heroBg} />
          <div className="img-wrapper__mask" />
          {heroData.heroArticles[0] && (
            <MediaBlock
              onClick={() => onSelect(heroData.heroArticles[0])}
              mediaType={heroData.heroArticles[0].data.media_type}
              tag={heroData.heroArticles[0].data.filter_type}
              thumbnail={heroData.heroArticles[0].data.media_image.url}
              externalLink={
                heroData.heroArticles[0].data.external_link
                  ? heroData.heroArticles[0].data.external_link.url
                  : null
              }
              title={heroData.mainHeroMediaTitle}
            />
          )}
        </div>
        <div className="secondaryHeroRow">
          {heroData.heroArticles[1] && (
            <MediaBlock
              isLarge
              onClick={() => onSelect(heroData.heroArticles[1])}
              mediaType={heroData.heroArticles[1].data.media_type}
              tag={heroData.heroArticles[1].data.filter_type}
              thumbnail={heroData.heroArticles[1].data.media_image.url}
              externalLink={
                heroData.heroArticles[1].data.external_link
                  ? heroData.heroArticles[1].data.external_link.url
                  : null
              }
              title={asText(heroData.heroArticles[1].data.title)}
            />
          )}
          {heroData.heroArticles[2] && (
            <MediaBlock
              onClick={() => onSelect(heroData.heroArticles[2])}
              mediaType={heroData.heroArticles[2].data.media_type}
              tag={heroData.heroArticles[2].data.filter_type}
              thumbnail={heroData.heroArticles[2].data.media_image.url}
              externalLink={
                heroData.heroArticles[2].data.external_link
                  ? heroData.heroArticles[2].data.external_link.url
                  : null
              }
              title={asText(heroData.heroArticles[2].data.title)}
            />
          )}
        </div>
      </div>
    )
  }

  return null
}

export default Hero
