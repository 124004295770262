import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { useSelector } from "react-redux"
import classNames from "classnames"

import { selectEvent, selectVisibleEvent } from "../../state/reducers/utils"

import Hamburger from "./Hamburger"
import Logo from "../Logo"
import Navigation from "../Navigation"

import "./index.scss"

const Header = ({
  children,
  faded,
  mainEl,
  selectTab,
  showNav,
  tabs,
  toggleNav,
  auxPage,
  cesPage,
  // inEvent,
}) => {
  const visibleEvent = useSelector(selectVisibleEvent)
  const event = useSelector(selectEvent)
  const location = useLocation()
  const [animateHeader, setAnimateHeader] = useState(false)
  const [inEvent, setInEvent] = useState(false)
  const [offsetHeader, setOffsetHeader] = useState(
    location.pathname.split("/")[1] === "live"
  )
  useEffect(() => {
    const pathName = location.pathname.split("/")[1]
    if (
      visibleEvent
      // event &&
      // (event.is_public || (!event.is_public && pathName === "live"))
    ) {
      setOffsetHeader(true)
    } else {
      setOffsetHeader(false)
    }
    if (pathName === "live") {
      setInEvent(true)
    } else {
      setInEvent(false)
    }
  }, [event, location, visibleEvent])


  useEffect(() => {
    const pathName = location.pathname.split("/")[1]
    if (pathName !== "live") {
      setAnimateHeader(true)
    } else {
      setTimeout(() => setAnimateHeader(false), 750)
    }
  }, [location])
  return (
    <>
      <header
        className={classNames("header", {
          "header--has-event": offsetHeader,
          "header--animate": animateHeader,
          "header--in-event": inEvent,
          "header--in-aux": auxPage,
          "header--in-ces": cesPage,
          faded,
        })}
      >
        <Hamburger
          background={faded ? "rgba(255, 255, 255, 0.2)" : "#230F2C"}
          isActive={showNav}
          onClick={toggleNav}
        />
        <Logo
          onClick={() =>
            selectTab({ id: "expertise", title: "Expertise", path: "/" })
          }
          fill={faded ? "rgba(255, 255, 255, 0.2)" : "#230F2C"}
        />
        <Navigation
          isOpen={showNav}
          mainEl={mainEl}
          selectTab={selectTab}
          tabs={tabs}
          toggleNav={toggleNav}
        >
          <Hamburger
            background="#ffffff"
            isActive={showNav}
            onClick={toggleNav}
          />
        </Navigation>
        {children}
      </header>
    </>
  )
}

export default Header
