import classNames from "classnames"
import { asText } from "@prismicio/helpers"
import React from "react"
import "./index.scss"

const EventPartners = ({ event, rsvped }) => (
  <div
    className={classNames("event__partners", {
      "event__partners--rsvped": rsvped,
    })}
  >
    <h2>Partners</h2>
    <div className="event__partners__grid">
      {event.partnerships.map((partner) => (
        <a
          key={partner.partner_link.url}
          href={partner.partner_link.url}
          target="_blank"
          rel="noreferrer"
        >
          <div className="inner">
            <div className="image-wrapper">
              <img
                src={partner.partner_logo.url}
                alt={asText(partner.partner_name)}
              />
            </div>
          </div>
        </a>
      ))}
    </div>
  </div>
)

export default EventPartners
