import React, { useEffect, useRef, useState } from "react"
import classnames from "classnames"
import { PrismicText } from "@prismicio/react"
import { asText } from "@prismicio/helpers"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import HoverTooltip from "../../common/HoverTooltip"

import "./SeniorLeaders.scss"

gsap.registerPlugin(ScrollTrigger)

const SeniorLeaders = ({ seniors, openModal, seniorsRef }) => {
  const [leaders, setLeaders] = useState(null)
  const leaderAnimations = useRef(null)
  const leaderRefs = useRef({})
  useEffect(() => {
    if (seniors) {
      setLeaders(seniors)
    }
  }, [seniors])

  const killAnimations = () => {
    if (leaderAnimations.current) {
      leaderAnimations.current.forEach((tl) => {
        tl.kill()
      })
    }
  }

  useEffect(() => {
    killAnimations()
    if (leaders && Object.keys(leaderRefs.current).length === leaders.length) {
      gsap.set(".senior-leaders__person", { opacity: 0 })
      leaderAnimations.current = ScrollTrigger.batch(
        ".senior-leaders__person",
        {
          interval: 0.1, // time window (in seconds) for batching to occur.
          onEnter: (batch) =>
            gsap.to(batch, {
              opacity: 1,
              duration: 0.75,
              overwrite: true,
            }),
          onLeaveBack: (batch) =>
            gsap.to(batch, { opacity: 0, overwrite: true }),
          start: "top bottom-=50px",
          scroller: window.innerWidth >= 768 ? "#people-tab-body" : null,
        }
      )

      ScrollTrigger.addEventListener("refreshInit", () =>
        gsap.set(".senior-leaders__person", { opacity: 1 })
      )
    }
  })
  return (
    <div
      id="people-leaders"
      className={classnames("senior-leaders", {})}
      ref={seniorsRef}
    >
      <h2>Senior Leaders</h2>
      {leaders && (
        <div className="senior-leaders__columns">
          <ul className="senior-leaders__column">
            {leaders.map((leader, pi) => (
              <HoverTooltip key={asText(leader.name)} text="Read Full Bio">
                <li
                  className="senior-leaders__person"
                  ref={(div) => {
                    leaderRefs.current[pi] = div
                  }}
                >
                  <button type="button" onClick={() => openModal(leaders, pi)}>
                    <span>
                      <PrismicText field={leader.name} />
                    </span>
                    <div className="senior-leaders__person-imgwrapper">
                      <img src={leader.image.url} alt={leader.image.alt} />
                    </div>
                  </button>
                </li>
              </HoverTooltip>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default SeniorLeaders
