import React from "react"

import "./index.scss"

const Logo = ({ fill, onClick }) => (
  <svg
    onClick={onClick}
    width="21"
    height="22"
    viewBox="0 0 500 502"
    fill="none"
    className="logo"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0L249.95 388.56L301.37 308.62L102.85 0H0Z" fill={fill} />
    <path
      d="M336.29 254.51L500 0H396.81L284.69 174.3L336.29 254.51Z"
      fill={fill}
    />
    <path d="M89.91 501.1H0V108.35L89.91 248.25V501.1Z" fill={fill} />
    <path d="M410.09 501.1H500V108.35L410.09 248.25V501.1Z" fill={fill} />
  </svg>
)

export default Logo
