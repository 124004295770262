import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import gsap from "gsap"
import { useDispatch, useSelector } from "react-redux"

import {
  resetViewAllData,
  selectViewAllData,
  setSearchOpen,
} from "../../../state/reducers/utils"
import SearchIcon from "../../../images/icons/search-icon.svg"
import GoodSearch from "../Search"
import { filters } from "../filters"

import "./Filter.scss"

const Filter = ({
  onChange,
  data,
  activeFilter,
  selected,
  scroller,
  loaded,
  setModalContent,
  onMediaBlockSelect,
}) => {
  const tl = useRef(null)
  const stickyTl = useRef(null)
  const filtersContainer = useRef(null)
  const viewAllData = useSelector(selectViewAllData)
  const [contentLoaded, setContentLoaded] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [fixed, setFixed] = useState(false)
  const dispatch = useDispatch()
  const closeSearch = () => {
    setModalContent(null)
    dispatch(setSearchOpen(false))
  }
  const openSearch = () => {
    dispatch(setSearchOpen(true))
    setModalContent(
      <GoodSearch
        closeModal={closeSearch}
        viewAllData={viewAllData}
        onMediaBlockSelect={onMediaBlockSelect}
      />
    )
  }

  const destroyTimeline = () => {
    tl.current.kill()
    tl.current = null
  }

  useEffect(() => {
    if (activeFilter && window.innerWidth > 1200) {
      document.getElementById(scroller).scrollTo({
        top: filtersContainer.current.offsetTop + 60,
        left: 0,
        behavior: "smooth",
      })
    }
  }, [activeFilter])

  useEffect(() => {
    if (viewAllData) {
      setTimeout(() => {
        openSearch()
      }, 250)
      dispatch(resetViewAllData())
    }
  }, [viewAllData])

  useEffect(() => {
    setShowFilters(data && data.length)
  }, [data])

  useEffect(() => {
    if ((selected, contentLoaded) && window.innerWidth > 1200) {
      setTimeout(() => {
        const stickyScrollTrigger = {
          trigger: filtersContainer.current,
          start: "top top+=10px",
          scroller: `#${scroller}`,
          onEnter: () => {
            setFixed(true)
          },
          onLeaveBack: () => {
            setFixed(false)
          },
        }
        stickyTl.current = gsap.timeline({ scrollTrigger: stickyScrollTrigger })
      }, 500)
    } else if (stickyTl.current) {
      stickyTl.current.kill()
    }
    return () => {
      if (stickyTl.current) {
        stickyTl.current.kill()
      }
    }
  }, [contentLoaded])

  useEffect(() => {
    const scrollTriggerConfig = {
      trigger: "#goodsFilterContainer",
      start:
        window.innerWidth < 768 ? "top center+=25%" : "bottom bottom-=60px",
      toggleActions: "play play play reverse",
    }
    /*
    See: https://greensock.com/docs/v3/Plugins/ScrollTrigger/scroller
    */
    if (scroller !== "body" && window.innerWidth > 767) {
      scrollTriggerConfig.scroller = `#${scroller}`
    }
    if ((selected, contentLoaded)) {
      if (tl.current) destroyTimeline()
      if (document.getElementById("goodsFilterContainer")) {
        tl.current = gsap.timeline({ scrollTrigger: scrollTriggerConfig })
        filters.forEach((_, index) => {
          tl.current.to(
            `.filter-${index}`,
            {
              pointerEvents: "inherit",
              y: 0,
              opacity: 1,
              duration: 0.5,
            },
            0.125 * index
          )
        })
        tl.current.to(
          `.search-icon`,
          {
            pointerEvents: "inherit",
            y: 0,
            opacity: 1,
            duration: 0.5,
          },
          0.125 * filters.length
        )
      }
    } else if (tl && tl.current) tl.current.kill()
  }, [selected, contentLoaded])

  useEffect(() => {
    if (loaded) {
      setTimeout(() => setContentLoaded(true), 500)
    }
  }, [loaded])

  return (
    <div
      className={classNames("filtersWrapper", {
        "filtersWrapper--visible": showFilters,
        "filtersWrapper--fixed": fixed,
      })}
    >
      <div className="filtersWrapperInner">
        <span>Filter</span>
        <div ref={filtersContainer} className="filtersContainerWrapper">
          <div
            className={classNames("filtersContainer", {
              "filtersContainer--has-active": activeFilter,
            })}
            id="goodsFilterContainer"
          >
            {filters.map((filter, i) => (
              <button
                type="button"
                onClick={() => onChange(filter.name)}
                key={filter.name}
                className={classNames("filter", `filter-${i}`, {
                  "filter--active": filter.name === activeFilter,
                })}
              >
                <div>
                  <h2>{filter.name}</h2>
                </div>
                <p>{filter.description}</p>
              </button>
            ))}
            <button type="button" className="search-icon" onClick={openSearch}>
              <img src={SearchIcon} alt="search icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Filter
