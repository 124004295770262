import React from "react"

const SlicePullquote = ({ slice }) => (
  <div className="slice-pullquote">
    <div className="slice-pullquote__quote">
      <span className="slice-pullquote__quote--start">&ldquo;</span>
      {slice.primary.quote}
      <span className="slice-pullquote__quote--end">&rdquo;</span>
    </div>
    <div className="slice-pullquote__byline">
      &mdash;&nbsp;
      {slice.primary.byline}
    </div>
  </div>
)

export default SlicePullquote
