import React, { useEffect, useState, useRef } from "react"
import { useMeasure } from "react-use"
import { isMobile } from "react-device-detect"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Navigation } from "swiper/core"
import classNames from "classnames"

import chevronLeft from "../../../images/icons/chevron-left-icon.svg"
import chevronRight from "../../../images/icons/chevron-right-icon.svg"

import "swiper/swiper.scss"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import "./Carousel.scss"

SwiperCore.use([Pagination, Navigation])

const Carousel = (props) => {
  const { spaceBetween, spaceBetweenMobile, children } = props

  const [slideEl, { width }] = useMeasure()
  const [swiperInstance, setSwiperInstance] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [paginationEl, setPaginationEl] = useState(null)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const [hasHover, setHasHover] = useState(false)

  useEffect(() => {
    function handleResize() {
      if (swiperInstance && swiperInstance.slidesGrid)
        swiperInstance.snapGrid = [...swiperInstance.slidesGrid]
    }
    window.addEventListener("resize", handleResize)
  })

  useEffect(() => {
    if (swiperInstance) {
      setPaginationEl(swiperInstance.pagination.el)
    }
  }, [swiperInstance])

  useEffect(() => {
    if (paginationEl) paginationEl.style.width = `${width}px`
  }, [width])

  const handlePrevSlide = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev()
      setActiveIndex(swiperInstance.activeIndex)
    }
  }

  const handleNextSlide = () => {
    if (swiperInstance) {
      swiperInstance.slideNext()
      setActiveIndex(swiperInstance.activeIndex)
    }
  }

  const setSlideClass = (isActive, index) => {
    if (isActive) return "active"

    return index > activeIndex ? "next" : "prev"
  }

  return (
    <div className="carousel">
      <div
        className={classNames("carousel__arrows", { "has-hover": hasHover })}
      >
        <button
          type="button"
          className={classNames("carousel__arrow carousel__arrow--left", {
            "carousel__arrow--disabled": activeIndex === 0,
          })}
          ref={navigationPrevRef}
          onClick={handlePrevSlide}
          // onMouseOver={() => {
          //   setHasHover(true)
          // }}
          // onMouseLeave={() => {
          //   setHasHover(false)
          // }}
          onFocus={() => {
            setHasHover(true)
          }}
          onBlur={() => {
            setHasHover(false)
          }}
        >
          <img alt="Chevron left icon" src={chevronLeft} />
        </button>
        <button
          type="button"
          className={classNames("carousel__arrow carousel__arrow--right", {
            "carousel__arrow--disabled": activeIndex === children.length - 1,
          })}
          ref={navigationNextRef}
          onClick={handleNextSlide}
          // onMouseOver={() => {
          //   setHasHover(true)
          // }}
          // onMouseLeave={() => {
          //   setHasHover(false)
          // }}
          onFocus={() => {
            setHasHover(true)
          }}
          onBlur={() => {
            setHasHover(false)
          }}
        >
          <img alt="Chevron right icon" src={chevronRight} />
        </button>
      </div>
      <div className="carousel__content">
        <Swiper
          speed={800}
          spaceBetween={spaceBetweenMobile || 20}
          slidesPerView="auto"
          slideToClickedSlide
          autoHeight={isMobile}
          breakpoints={{
            800: {
              spaceBetween: spaceBetween || 100,
            },
          }}
          pagination={{
            type: "progressbar",
          }}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex)
            swiper.snapGrid = [...swiper.slidesGrid]
          }}
          onSwiper={(swiper) => {
            setSwiperInstance(swiper)
            swiper.snapGrid = [...swiper.slidesGrid] // fix for slides not aligning left on auto slidesPerView
          }}
        >
          {children.map((child, index) => (
            <SwiperSlide key={child.props.id}>
              {({ isActive }) => (
                <div
                  className={`carousel-slide ${setSlideClass(isActive, index)}`}
                  ref={slideEl}
                >
                  {child}
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default Carousel
