import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap/all"
import classNames from "classnames"
import { PrismicText } from "@prismicio/react"
import iCalIcon from "../../../images/icons/ical.png"
import googleCalIcon from "../../../images/icons/googlecal.png"
import office365Icon from "../../../images/icons/office365.png"
import outlookIcon from "../../../images/icons/outlook.png"

import CloseIcon from "../../../images/icons/close.svg"
import Form from "../../common/Form"
import "./index.scss"

const RSVP = ({
  hideRSVP,
  showRSVP,
  dateTime,
  description,
  mktoId,
  setRsvped,
  rsvpText,
  event,
}) => {
  const rsvp = useRef(null)
  const form = useRef(null)
  const tl = useRef(null)
  const thanks = useRef(null)
  const successTL = useRef(null)
  const formTL = useRef(null)
  const [submitText, setSubmitText] = useState("RSVP")
  const [success, setSuccess] = useState(false)
  const [calendarLinks, setCalendarLinks] = useState(false)

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    tl.current.fromTo(
      rsvp.current,
      {
        top: "100%",
        opacity: 0,
      },
      {
        top: 0,
        duration: 0.5,
        opacity: 1,
      }
    )
    return () => {
      tl.current.kill()
    }
  }, [])

  useEffect(() => {
    formTL.current = gsap.timeline({
      paused: true,
      onComplete: () => setSuccess(true),
    })
    formTL.current.to(form.current, { opacity: 0, duration: 0.5 })
    return () => {
      if (formTL.current) {
        formTL.current.kill()
      }
      if (successTL.current) {
        successTL.current.kill()
      }
    }
  }, [])

  useEffect(() => {
    successTL.current = gsap.timeline({ paused: true })
    successTL.current.to(thanks.current, { opacity: 1, duration: 0.5 })
    if (success) {
      successTL.current.play()
    }
  }, [success])

  useEffect(() => {
    if (showRSVP) {
      tl.current.play()
    } else {
      tl.current.reverse()
    }
  }, [showRSVP])

  useEffect(() => {
    const options = []
    if (event.ical_link) {
      options.push({
        alt: "iCal",
        icon: iCalIcon,
        link: event.ical_link.url,
      })
    }
    if (event.google_link) {
      options.push({
        alt: "Google Calendar",
        icon: googleCalIcon,
        link: event.google_link.url,
      })
    }
    if (event.office365_link) {
      options.push({
        alt: "Office365",
        icon: office365Icon,
        link: event.office365_link.url,
      })
    }
    if (event.outlook_link) {
      options.push({
        alt: "Outlook",
        icon: outlookIcon,
        link: event.outlook_link.url,
      })
    }
    setCalendarLinks(options)
  }, [event])

  const handleSubmission = (status) => {
    setSubmitText(status === "submitting" ? "Sending RSVP" : "RSVP Sent")
    // if (status === "success") {
    setRsvped(true)
    rsvp.current.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    formTL.current.play()
    // }
  }
  const formGroups = [
    {
      keys: ["FirstName", "LastName", "Email"],
    },
    {
      keys: ["declaredCompanyP", "Title", "Country"],
    },
    {
      keys: ["medialinkTermsConditions", "medialinkOptIn"],
    },
  ]

  return (
    <div className="event__rsvp" ref={rsvp}>
      <button
        type="button"
        className={classNames("event__rsvp-close", {
          "event__rsvp-close--fixed": showRSVP,
        })}
        onClick={hideRSVP}
      >
        <img src={CloseIcon} alt="Close Icon" />
      </button>
      <div className="event__rsvp-inner">
        <div className="h1">See you there!</div>
        <div className="date-time">{dateTime}</div>
        <div className="description">
          {description ||
            "We’re ready and waiting to make your goals a reality. Drop us a line with your specific needs and the right person will be in touch."}
        </div>
        {success ? (
          <div ref={thanks} className="success">
            <div className="h3">
              Your RSVP
              <br /> has been received!
            </div>
            <PrismicText field={rsvpText} />
            <div className="h4">Add to your calendar:</div>
            <div className="calendar-links">
              {calendarLinks &&
                calendarLinks.map((option) => (
                  <a
                    key={option.alt}
                    href={option.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={option.icon} alt={option.alt} />
                  </a>
                ))}
            </div>
          </div>
        ) : (
          <div ref={form}>
            <div className="form-group-label">Your Info</div>
            <Form
              formConfig={["//592-QPU-703.mktoweb.com", "592-QPU-703", mktoId]}
              formId="Event-RSVP"
              groups={formGroups}
              submitText={submitText}
              setFormStatus={handleSubmission}
            />
            <div className="form-disclaimer">
              By clicking the RSVP button, you agree to MediaLink Live’s&nbsp;
              <a
                href="https://medialink.com/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://medialink.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              . MediaLink may send you communications. We’ll never post without
              your permission.
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default RSVP
