import React from "react"

import "./PullQuote.scss"

const PullQuote = ({ children, caption }) => (
  <figure className="pullquote">
    <blockquote>{children}</blockquote>
    {caption && (
      <figcaption>
        <span>—</span> {caption}
      </figcaption>
    )}
  </figure>
)

export default PullQuote
