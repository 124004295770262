import React, { useState } from "react"
import { asText } from "@prismicio/helpers"
import classNames from "classnames"

import Form from "../../../common/Form"
import ArrowRightIcon from "../../../../images/icons/arrow-right-icon.svg"
import CheckIcon from "../../../../images/icons/cta-success-icon.svg"

const MarketoEmail = ({ selectedArticle }) => {
  const [showForm, setShowForm] = useState(false)
  const [submitText, setSubmitText] = useState("Submit")
  const [success, setSuccess] = useState(false)
  const formGroups = [{ keys: ["Email", "medialinkOptIn"] }]

  const handleSubmission = (status) => {
    setSubmitText("Submitting...")
    setSuccess(status === "success")
  }

  return (
    <div
      className={classNames("mkto-email-pdf", {
        "mkto-email-pdf--show-form": showForm,
        "mkto-email-pdf--success": success,
      })}
    >
      <div className="mkto-email-pdf__inner">
        <button
          type="button"
          className="contained-button continue"
          onClick={() => setShowForm(true)}
        >
          CONTINUE READING ARTICLE
          <img src={ArrowRightIcon} alt="Arrow right" />
        </button>
        <div className="mkto-email-pdf__success">
          <span>The article has been sent to your email</span>
          <img src={CheckIcon} alt="Check mark" />
        </div>
        <div className="mkto-email-pdf__form">
          <Form
            formConfig={[
              "//592-QPU-703.mktoweb.com",
              "592-QPU-703",
              parseInt(asText(selectedArticle.data.mkto_id), 10),
            ]}
            formId="Article-Email"
            groups={formGroups}
            submitText={submitText}
            setFormStatus={handleSubmission}
          />
        </div>
      </div>
    </div>
  )
}

export default MarketoEmail
