import React from "react"
import TextWithCTA from "../../common/TextWithCTA/TextWithCTA"

import "./GetInTouch.scss"

const GetInTouch = ({ showContactForm }) => (
  <section className="get-in-touch">
    <div className="get-in-touch__inner">
      <TextWithCTA
        data={{
          headline: "Ready to accelerate success? ",
          body:
            "We’re ready and waiting to make your goals a reality. Drop us a line with your specific needs and the right person will be in touch.",
          cta: "Get in touch",
        }}
        handleClick={showContactForm}
      />
    </div>
  </section>
)

export default GetInTouch
