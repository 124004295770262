/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react"
import ReactPlayer from "react-player"
import classNames from "classnames"
import { isIOS, isTablet } from "react-device-detect"
import { useDispatch } from "react-redux"

import { setVideoPlayer } from "../../state/reducers/utils"
import ExpandIcon from "../../images/icons/expand-icon.svg"
import FullScreenIcon from "../../images/icons/fullscreen-icon.svg"
import PlayIcon from "../../images/icons/play-icon.svg"
import PauseIcon from "../../images/icons/pause-icon.svg"
import CompressIcon from "../../images/icons/compress-icon.svg"
import VolumeOnIcon from "../../images/icons/volume-on-icon.svg"
import VolumeOffIcon from "../../images/icons/volume-off-icon.svg"
import TestimonialFullScreen from "../../images/icons/testimonial-full-screen-icon.svg"
import CloseIcon from "../../images/icons/close-white.svg"
import "./index.scss"

const FullScreenVideo = ({
  autoPlay,
  video,
  showLive,
  belowDesktop,
  setBelowDesktop,
  parentMuted,
  parentPlaying,
  setParentPlaying,
  loop,
  setParentHeight,
  setParentWidth,
  inDiv,
  showControls,
  fullScreen,
  setFullScreen,
  pauseOverlay,
  setPlayer,
  setParentMuted,
  isRSVP,
}) => {
  const dispatch = useDispatch()
  const fullScreenVideo = useRef(null)
  const videoRef = useRef(null)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(autoPlay)
  const [expanded, setExpanded] = useState(true)
  const [animated, setAnimated] = useState(false)

  useEffect(() => {
    try {
      if (window.__vimeoRefresh) {
        setTimeout(() => {
          window.__vimeoRefresh()
        }, 3000)
      }
    } catch (e) {
      console.log("error refreshing video tracking")
    }
  }, [])

  useEffect(() => {
    setMuted(parentMuted)
  }, [parentMuted])

  useEffect(() => {
    setPlaying(parentPlaying)
  }, [parentPlaying])

  const sizeVideo = () => {
    if (!video || !fullScreenVideo.current) return
    if (inDiv) {
      setHeight("100%")
      setWidth("100%")
    } else {
      const ratio = video.height / video.width
      if (setBelowDesktop) {
        setBelowDesktop(
          window.innerWidth <= 1152 && window.innerWidth < window.innerHeight
        )
      }
      if (
        window.innerWidth <= 1152 &&
        window.innerWidth < window.innerHeight &&
        !fullScreen
      ) {
        const heightToUse =
          window.innerWidth < 768
            ? window.innerHeight * 0.4
            : window.innerHeight * 0.6
        setWidth(heightToUse * (video.width / video.height))
        setHeight(heightToUse)
      } else if (fullScreen) {
        if (window.innerWidth * ratio < window.innerHeight) {
          setWidth(window.innerHeight * (video.width / video.height))
          setHeight(window.innerHeight)
        } else {
          setWidth(window.innerWidth)
          setHeight(window.innerWidth * ratio)
        }
      } else if (
        fullScreenVideo.current &&
        fullScreenVideo.current.offsetWidth * ratio <
          fullScreenVideo.current.offsetHeight
      ) {
        setHeight(fullScreenVideo.current.offsetHeight)
        setWidth(
          fullScreenVideo.current.offsetHeight * (video.width / video.height)
        )
      } else if (fullScreenVideo.current) {
        setWidth(fullScreenVideo.current.offsetWidth)
        setHeight(fullScreenVideo.current.offsetWidth * ratio)
      }
    }
  }

  useEffect(() => {
    sizeVideo()
  }, [inDiv, video])

  useEffect(() => {
    if (setParentHeight) {
      setParentHeight(height)
      setParentWidth(width)
    }
  }, [height, width])

  useEffect(() => {
    if (height) {
      setAnimated(true)
    }
  }, [height])

  useEffect(() => {
    sizeVideo()
    window.addEventListener("resize", sizeVideo)
    window.addEventListener("orientationchange", sizeVideo)
    return () => {
      window.removeEventListener("resize", sizeVideo)
      window.removeEventListener("orientationchange", sizeVideo)
    }
  }, [video])

  const goFull = () => {
    if (parentMuted) {
      setParentMuted(false)
    }
    if (isIOS && isTablet) {
      videoRef.current.wrapper.querySelector("iframe").webkitRequestFullscreen()
    } else {
      videoRef.current.wrapper.querySelector("iframe").requestFullscreen()
    }
  }

  const handleVideoClick = () => {
    if (showLive && setParentPlaying) {
      setParentPlaying(!playing)
    } else {
      setPlaying(!playing)
    }
  }

  const videoPaused = () => {
    setPlaying(false)
    if (setParentPlaying) {
      setParentPlaying(false)
    }
  }

  const videoReady = () => {
    if (setPlayer) {
      setPlayer(videoRef.current.getInternalPlayer())
    } else {
      dispatch(setVideoPlayer(videoRef.current.getInternalPlayer()))
    }
    if (setParentPlaying && autoPlay) {
      setParentPlaying(true)
    }
  }

  const exitFullScreen = (e) => {
    e.stopPropagation()
    setFullScreen(false)
    setParentPlaying(false)
  }

  return (
    <div
      className={classNames("fs-video", {
        "fs-video--watching": showControls,
        "fs-video--is-rsvp": isRSVP,
      })}
      id={`fs-video-${video.id}`}
      ref={fullScreenVideo}
      style={
        belowDesktop
          ? { height, width, marginBottom: showLive ? 0 : height * -1 }
          : {}
      }
    >
      <div
        className={classNames("fs-video__player", {
          "fs-video__player--compressed": !expanded,
          "fs-video__player--animated": animated,
        })}
        style={{
          maxHeight: height,
          maxWidth: width,
        }}
        onClick={!(isIOS && !isTablet) ? handleVideoClick : () => {}}
      >
        <ReactPlayer
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                rel: 0,
                controls: 0,
                showinfo: 0,
              },
            },
          }}
          ref={videoRef}
          loop={loop}
          controls={isIOS && !isTablet && !isRSVP}
          // controls={false}
          className="react-player"
          url={video.embed_url}
          onPause={videoPaused}
          onEnded={videoPaused}
          onReady={videoReady}
          playing={playing}
          muted
          playsinline
          width="100%"
          height="100%"
        />
        {!playing && pauseOverlay && !inDiv && pauseOverlay}
      </div>
      {!inDiv && setFullScreen && (
        <button type="button" onClick={exitFullScreen} className="close-button">
          <img src={CloseIcon} alt="Close" />
        </button>
      )}
      {!(isIOS && !isTablet) && (
        <div className="fs-video__player-controls">
          <div>
            <button type="button" onClick={() => setParentPlaying(!playing)}>
              <img src={playing ? PauseIcon : PlayIcon} alt="Play Icon" />
            </button>
            <button
              type="button"
              onClick={() => setParentMuted(!muted)}
              className="volume"
            >
              <img
                src={muted ? VolumeOffIcon : VolumeOnIcon}
                alt="Volume Icon"
              />
            </button>
          </div>
          <div>
            {setFullScreen && !isIOS ? (
              <button className="expand" type="button" onClick={setFullScreen}>
                <img src={TestimonialFullScreen} alt="Full Screen Icon" />
              </button>
            ) : (
              <>
                <button
                  className="expand"
                  type="button"
                  onClick={() => setExpanded(!expanded)}
                >
                  <img
                    src={expanded ? CompressIcon : ExpandIcon}
                    alt="Expand/Compress Icon"
                  />
                </button>
                <button type="button" onClick={goFull}>
                  <img src={FullScreenIcon} alt="FullScreen Icon" />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default FullScreenVideo
