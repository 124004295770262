/* eslint-disable import/prefer-default-export */

export const returnFormattedLink = (link) => {
  const url = new URL(link)

  return url.hostname
}

export const truncateTitle = (title, limit) => {
  if (title.length <= limit) return title
  return `${title.substring(0, limit)}...`
}
