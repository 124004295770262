import React from "react"

import "./ImageHighlight.scss"

const ImageHighlight = ({ children, bgImage }) => (
  <div
    className="image-highlight"
    style={{
      backgroundImage: `url(${bgImage})`,
    }}
  >
    <div className="image-highlight__inner">{children}</div>
  </div>
)

export default ImageHighlight
