/* eslint-disable no-prototype-builtins */
import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import moment from "moment"
import Prismic from "@prismicio/client"
import { PrismicRichText, PrismicText } from "@prismicio/react"
import { asText, asLink } from "@prismicio/helpers"
import gsap from "gsap"

import classNames from "classnames"
import MediaBlock from "../../MediaBlock/MediaBlock"
import ModalNav from "../../common/ModalNav"
import RichContent from "../ModalV2/slices/RichContent"

import { handleViewTracking } from "../../../helpers/gtm"

import linkedinIcon from "../../../images/icons/linkedin.svg"
import copyIcon from "../../../images/icons/media-copy-icon.svg"
import successIcon from "../../../images/icons/cta-success-icon.svg"

import "./Modal.scss"

const Modal = ({ selectedArticle, articles, insideModal, closeModal }) => {
  const [selectedArticleAuthor, setSelectedArticleAuthor] = useState(null)
  const [relatedArticles, setRelatedArticles] = useState(null)
  const [copied, setCopied] = useState(false)

  const [articleIndex, setArticleIndex] = useState(null)
  const [filteredArticles, setFilteredArticles] = useState(null)

  const client = Prismic.client(process.env.GATSBY_PRISMIC_API_ENDPOINT, {
    accessToken: process.env.GATSBY_PRISMIC_API_KEY,
  })

  const handleCopy = () => {
    setCopied(true)
    const { uid, id } = selectedArticle
    navigator.clipboard.writeText(
      `${window.location.origin}/goods/${uid || id || selectedArticle.data.id}`
    )
    setTimeout(() => {
      setCopied(false)
    }, 2500)
  }

  const timeline = gsap.timeline({ repeat: false })

  // gets author information
  useEffect(() => {
    if (filteredArticles) {
      setSelectedArticleAuthor(null)
      setRelatedArticles(null)
      // if linked author exists
      if (filteredArticles[articleIndex].data.author.id) {
        client
          .query(
            Prismic.predicates.at(
              "document.id",
              filteredArticles[articleIndex].data.author.id
            )
          )
          .then((author) => {
            // animation
            timeline.to("#article", {
              opacity: 1,
              duration: 0.45,
            })
            setSelectedArticleAuthor(author.results[0])
          })
      }
      // if guest author exists
      if (asText(filteredArticles[articleIndex].data.guest_author)) {
        // animation
        timeline.to("#article", {
          opacity: 1,
          duration: 0.45,
        })
        setSelectedArticleAuthor({
          data: {
            image: filteredArticles[articleIndex].data.guest_author_image,
            name: filteredArticles[articleIndex].data.guest_author,
            position: filteredArticles[articleIndex].data.guest_author_title,
            linkedin: filteredArticles[articleIndex].data.guest_author,
          },
        })
      }
    }
  }, [articleIndex])

  // gets related article information
  useEffect(() => {
    if (filteredArticles) {
      const articlesRelated = filteredArticles[
        articleIndex
      ].data.related_articles.map((article, index) => {
        if (index < 2) {
          return article.article.id
        }
        return null
      })
      // get relevant goods data only if they exist
      if (articlesRelated.length > 0 && !articlesRelated.includes(undefined)) {
        client
          .query(Prismic.Predicates.in("document.id", articlesRelated))
          .then((relevantGoods) => {
            setRelatedArticles(relevantGoods.results)
          })
      }
    }
  }, [articleIndex])

  useEffect(() => {
    if (articles.length > 0) {
      // filter out articles with external links (non-navigational)
      let formattedArticles = articles.filter(
        (article) =>
          typeof article.data !== "undefined" &&
          article.data.external_link.hasOwnProperty("url") === false
      )

      /**
       * if no article of that type found, default to single article on that type
       * NOTE: this occurs when the selected hero articles are not yet queried in the
       * total media query (due to throttling)
       */
      if (formattedArticles.length < 1) {
        formattedArticles = [selectedArticle]
      }
      let index
      // get currenty index of selected article based on this filtered array
      if (selectedArticle.uid) {
        index = formattedArticles.findIndex(
          (x) => x.uid === selectedArticle.uid
        )
      } else {
        index = formattedArticles.findIndex((x) => x.id === selectedArticle.id)
      }

      setArticleIndex(index)
      setFilteredArticles(formattedArticles)
    }
  }, [])

  // handle page view tracking
  useEffect(() => {
    const id = selectedArticle.uid ? selectedArticle.uid : selectedArticle.id

    if (window.dataLayer && window.dataLayer.length > 1) {
      handleViewTracking(
        window.dataLayer[1].originalLocation,
        "The Goods | Insights, News & Updates | MediaLink",
        `/goods/${id}`
      )
    }
  }, [])

  const handleRelatedUpdate = (article) => {
    const index = filteredArticles.findIndex((x) => x.id === article.id)

    timeline.to("#article", {
      opacity: 0,
      duration: 0.45,
      onComplete: () => {
        setArticleIndex(index)
        document.getElementById("article").scrollTop = 0
      },
    })
  }
  if (articleIndex !== null && filteredArticles) {
    return (
      <>
        {!insideModal && (
          <ModalNav
            items={filteredArticles}
            labelIndex={articleIndex}
            setCurrentIndex={(index) => {
              timeline.to("#article", {
                opacity: 0,
                duration: 0.45,
                onComplete: () => {
                  setArticleIndex(index)
                  const { uid, id } = filteredArticles[index]
                  navigate(`/goods/${uid || id}`)
                  document.getElementById("article").scrollTop = 0
                },
              })
            }}
            closeModal={closeModal}
            isGoods
          />
        )}
        <div id="article" className="articleContainer">
          <div className="articleContainer__inner">
            <p>{filteredArticles[articleIndex].data.filter_type}</p>
            <h2 className="modalArticleTitle">
              <PrismicText field={filteredArticles[articleIndex].data.title} />
            </h2>
            <div className="articleDetails">
              <div className="details">
                <p>
                  {moment(
                    filteredArticles[articleIndex].first_publication_date
                  ).format("MMMM DD, YYYY")}
                </p>
                {selectedArticleAuthor && (
                  <p>{selectedArticleAuthor.data.name.text}</p>
                )}
              </div>
              <div className="details">
                <p>SHARE</p>
                <button
                  type="button"
                  className={classNames("red", { "red--copied": copied })}
                  onClick={handleCopy}
                >
                  <div className="copy">
                    COPY LINK
                    <img alt="copy" src={copyIcon} />
                  </div>
                  <div className="copy-success">
                    <span>Copied</span>
                    <img alt="success-marker" src={successIcon} />
                  </div>
                </button>
              </div>
            </div>
            <div className="articleContent">
              {filteredArticles[articleIndex].data.content.length ? (
                <RichContent
                  slice={filteredArticles[articleIndex].data.content}
                />
              ) : (
                <PrismicRichText
                  field={
                    filteredArticles[articleIndex].data.body[0].primary
                      .rich_content
                  }
                />
              )}
            </div>
            {relatedArticles && (
              <div className="relatedMedia">
                <p>
                  More Like This <button type="button">VIEW ALL</button>
                </p>
                <div>
                  {relatedArticles.map((article, index) => (
                    <MediaBlock
                      key={article.id}
                      isLarge={index > 0}
                      onClick={() => handleRelatedUpdate(article)}
                      mediaType={article.data.media_type}
                      tag={article.data.filter_type}
                      thumbnail={article.data.media_image.url}
                      externalLink={
                        article.data.external_link
                          ? article.data.external_link.url
                          : null
                      }
                      title={asText(article.data.title)}
                    />
                  ))}
                </div>
              </div>
            )}
            {selectedArticleAuthor && (
              <div className="author">
                <p>About The Author</p>
                <div className="authorDetailsContainer">
                  <img alt="" src={selectedArticleAuthor.data.image.url} />
                  <div className="authorDetails">
                    <h2>{selectedArticleAuthor.data.name.text}</h2>
                    <p>{selectedArticleAuthor.data.position.text}</p>

                    {asLink(selectedArticleAuthor.data.linkedin) && (
                      <a
                        target="__blank"
                        rel="noopener noreferrer"
                        href={asLink(selectedArticleAuthor.data.linkedin)}
                      >
                        <img alt="linkedin" src={linkedinIcon} />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
  return null
}

export default Modal
