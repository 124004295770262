import React from "react"
import classNames from "classnames"

const Suggestion = ({
  suggestion,
  allTags,
  selectSuggestion,
  hidden,
  selectedTags,
}) => {
  const trimmedSuggestion = suggestion.trim()
  return (
    <button
      key={suggestion}
      className={classNames("search__suggestion", {
        "search__suggestion--selected":
          selectedTags.indexOf(trimmedSuggestion) > -1,
        visible: !hidden,
        hidden,
      })}
      type="button"
      onClick={() => selectSuggestion(trimmedSuggestion)}
    >
      {trimmedSuggestion}
      <span className="search__suggestion-count">
        {allTags[trimmedSuggestion] && allTags[trimmedSuggestion].count}
      </span>
    </button>
  )
}

export default Suggestion
