import React, { useRef, useState } from "react"
import classNames from "classnames"

import Form from "../../common/Form"

import "./Contact.scss"

const formGroups = [
  {
    label: "Your Info",
    keys: ["FirstName", "LastName", "Email"],
  },
  {
    keys: ["enquiryDetail"],
  },
  {
    keys: ["MktoPersonNotes", "medialinkOptIn"],
  },
]

const locations = [
  {
    title: "New York",
    phone: "+1 646 259 3001",
    address1: "888 7th Ave Fl. 7",
    address2: "New York, NY 10106",
  },
  {
    title: "Los Angeles",
    phone: "+1 310 424 4444",
    address1: "9336 Civic Center Drive",
    address2: "Beverly Hills, CA 90210",
  },
  {
    title: "Chicago",
    phone: "+1 312 690 5200",
    address1: "222 S. Riverside Plaza Suite 1500",
    address2: "Chicago, IL 60606",
  },
  {
    title: "London",
    phone: "+44 207 278 3331",
    address1: "1 Newman Street",
    address2: "London W1T 1PB",
  },
]

function Contact() {
  const contact = useRef(null)
  const [formStatus, setFormStatus] = useState(null)
  const [submitText, setSubmitText] = useState("Submit")

  const onSubmit = (status) => {
    setSubmitText(status === "success" ? "Submitting" : "")
    if (status === "success") {
      contact.current.scrollBy({
        left: 0,
        top: contact.current.scrollTop * -1,
        behavior: "smooth",
      })
    }
    setFormStatus(status)
  }

  return (
    <div
      className={classNames("contact", {
        "contact--error": formStatus === "error",
        "contact--success": formStatus === "success",
      })}
      ref={contact}
    >
      <div className="contact__inner">
        <h1>
          <span className="contact__h1--default">Let&apos;s make moves.</span>
          <span className="contact__h1--success">
            Your message
            <br /> has been sent.
          </span>
          <span className="contact__h1--error">
            Your message
            <br /> hasn’t been sent.
          </span>
        </h1>
        <p>
          <span className="contact__p--default">
            We&apos;re ready to make your goals a reality. Drop us a note, and
            we’ll be in touch.
          </span>
          <span className="contact__p--error">Try Again</span>
          <span className="contact__p--success">We’ll get back soon.</span>
        </p>
        <Form
          formConfig={["//592-QPU-703.mktoweb.com", "592-QPU-703", 1024]}
          formId="contact"
          submitText={submitText}
          groups={formGroups}
          setFormStatus={onSubmit}
        />
        <div className="contact__offices">
          <h1>Offices</h1>
          <p>
            MediaLink is a UTA company and works from offices around the world.
          </p>
          <div className="contact__offices-locations">
            {locations.map((location) => (
              <div className="contact__offices-location" key={location.title}>
                <h3>{location.title}</h3>
                <p>
                  <a href={`tel:${location.phone}`} className="phone">
                    {location.phone}
                  </a>
                  <span>{location.address1}</span>
                  <span>{location.address2}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
