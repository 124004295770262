import React from "react"

import "../MediaBlock.scss"

const Icon = ({ type }) => {
  const podcast = (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 18.25C1.5 9.27537 8.77537 2 17.75 2C26.7246 2 34 9.27537 34 18.25C34 27.2246 26.7246 34.5 17.75 34.5C8.77537 34.5 1.5 27.2246 1.5 18.25ZM17.75 0.5C7.94695 0.5 0 8.44695 0 18.25C0 28.0531 7.94695 36 17.75 36C27.5531 36 35.5 28.0531 35.5 18.25C35.5 8.44695 27.5531 0.5 17.75 0.5ZM13 11.75C13 11.3358 13.3358 11 13.75 11C14.1642 11 14.5 11.3358 14.5 11.75V24.25C14.5 24.6642 14.1642 25 13.75 25C13.3358 25 13 24.6642 13 24.25V11.75ZM21.75 12C21.3358 12 21 12.3358 21 12.75V23.25C21 23.6642 21.3358 24 21.75 24C22.1642 24 22.5 23.6642 22.5 23.25V12.75C22.5 12.3358 22.1642 12 21.75 12ZM17.75 14C17.3358 14 17 14.3358 17 14.75V21.25C17 21.6642 17.3358 22 17.75 22C18.1642 22 18.5 21.6642 18.5 21.25V14.75C18.5 14.3358 18.1642 14 17.75 14ZM9.75 16C9.33579 16 9 16.3358 9 16.75V19.25C9 19.6642 9.33579 20 9.75 20C10.1642 20 10.5 19.6642 10.5 19.25V16.75C10.5 16.3358 10.1642 16 9.75 16ZM25 16.75C25 16.3358 25.3358 16 25.75 16C26.1642 16 26.5 16.3358 26.5 16.75V19.25C26.5 19.6642 26.1642 20 25.75 20C25.3358 20 25 19.6642 25 19.25V16.75Z"
      />
    </svg>
  )

  const video = (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 17.75C1.5 8.77537 8.77537 1.5 17.75 1.5C26.7246 1.5 34 8.77537 34 17.75C34 26.7246 26.7246 34 17.75 34C8.77537 34 1.5 26.7246 1.5 17.75ZM17.75 0C7.94695 0 0 7.94695 0 17.75C0 27.5531 7.94695 35.5 17.75 35.5C27.5531 35.5 35.5 27.5531 35.5 17.75C35.5 7.94695 27.5531 0 17.75 0ZM24.125 18.3995C24.357 18.2655 24.5 18.018 24.5 17.75C24.5 17.4821 24.357 17.2345 24.125 17.1005L15.125 11.9043C14.893 11.7704 14.6071 11.7704 14.375 11.9043C14.143 12.0383 14 12.2859 14 12.5538L14 22.9462C14 23.2141 14.1429 23.4617 14.375 23.5957C14.6071 23.7296 14.8929 23.7296 15.125 23.5957L24.125 18.3995ZM15.5 13.8529L22.25 17.75L15.5 21.6471V13.8529Z"
      />
    </svg>
  )

  return <>{type === "video" ? video : podcast}</>
}

export default Icon
