/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react"
import Prismic from "@prismicio/client"
import gsap from "gsap/all"
import classNames from "classnames"
import { asText } from "@prismicio/helpers"
import { useDispatch } from "react-redux"
import { setSearchOpen } from "../../../state/reducers/utils"
import MediaBlock from "../../MediaBlock/MediaBlock"
import ModalNav from "../../common/ModalNav"
import SearchSuggestions from "./Suggestions"
import Suggestion from "./Suggestion"
import PageHeader from "../../common/PageHeader"
import "./index.scss"

const client = Prismic.client(process.env.GATSBY_PRISMIC_API_ENDPOINT, {
  accessToken: process.env.GATSBY_PRISMIC_API_KEY,
})

const GoodSearch = ({ viewAllData, closeModal, onMediaBlockSelect }) => {
  const dispatch = useDispatch()
  const noResultsRef = useRef(null)
  const noResultsTL = useRef(null)
  const searchInput = useRef(null)
  const resultsEl = useRef(null)
  const suggestedList = useRef(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [searchedTags, setSearchedTags] = useState([])
  const [didYouMeans, setDidYouMeans] = useState(null)
  const [media, setMedia] = useState(null)
  const [orderedTags, setOrderedTags] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [allMedia, setAllMedia] = useState([])
  const [complete, setComplete] = useState(false)
  const [allTags, setAllTags] = useState([])
  const [authorIds, setAuthorIds] = useState(null)
  const [suggested, setSuggested] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [noResults, setNoResults] = useState(false)

  const autoGrow = () => {
    searchInput.current.style.height = "5px"
    searchInput.current.style.height = `${searchInput.current.scrollHeight}px`
  }

  const watchSearch = () => {
    setMedia(null)
    setSelectedTags([])
    setSearchedTags([])
    setSearchValue(searchInput.current.value)
    autoGrow()
  }

  const selectSuggestion = (suggestion, isSuggestion) => {
    if (isSuggestion) {
      searchInput.current.value = suggestion
      autoGrow()
      setSearchValue(suggestion)
    }
    const index = selectedTags.indexOf(suggestion)
    setSearchedTags([])
    if (index > -1) {
      const existing = [...selectedTags]
      existing.splice(index, 1)
      setSelectedTags(existing)
    } else {
      setSelectedTags([...selectedTags, suggestion])
    }
  }

  const loadAllMedia = () => {
    client
      .query(Prismic.predicates.at("document.type", "media"), {
        fetchLinks: ["person.name"],
        pageSize: 100,
        page: currentPage,
      })
      .then((results) => {
        if (results.results) {
          setAllMedia([...allMedia, ...results.results])
          if (currentPage === results.total_pages) {
            setComplete(true)
          } else {
            setCurrentPage(currentPage + 1)
          }
        } else {
          setComplete(true)
        }
      })
  }

  useEffect(() => {
    if (searchValue && (!didYouMeans || didYouMeans.length === 0)) {
      setNoResults(true)
    } else {
      setNoResults(false)
    }
  }, [searchValue, didYouMeans])

  useEffect(() => {
    if (searchValue) {
      const filtered = []
      orderedTags.forEach((t) => {
        const index = t.toLowerCase().indexOf(searchValue.toLowerCase())
        if (index > -1) {
          filtered.push({ tag: t, index })
        }
      })
      filtered.sort((a, b) => (a.index > b.index ? 1 : -1))
      setDidYouMeans(filtered.map((f) => f.tag))
    } else {
      setDidYouMeans(null)
    }
  }, [searchValue])

  useEffect(() => {
    if (viewAllData && orderedTags.length && authorIds) {
      searchInput.current.focus()
      searchInput.current.value = viewAllData.tagId
      autoGrow()
      setSearchValue(viewAllData.tagId)
      setSelectedTags([viewAllData.tagId])
    }
  }, [viewAllData, orderedTags, authorIds])

  useEffect(() => {
    loadAllMedia()
  }, [currentPage])

  useEffect(() => {
    const authors = {}
    const authorArticles = {}
    const tags = {}
    allMedia.forEach((mediaItem) => {
      mediaItem.tags.forEach((tag) => {
        tags[tag] = tags[tag]
          ? { ...tags[tag], count: tags[tag].count + 1 }
          : { id: tag, label: tag, count: 1 }
      })
      if (
        mediaItem.data.author &&
        mediaItem.data.author.data &&
        mediaItem.data.author.data.name
      ) {
        const authorName = asText(mediaItem.data.author.data.name)
          .replace(/"/g, "")
          .trim()
        authors[authorName] = authors[authorName]
          ? { ...authors[authorName], count: authors[authorName].count + 1 }
          : {
              id: authorName,
              label: authorName,
              count: tags[authorName] ? tags[authorName].count : 1,
            }

        authorArticles[authorName] = authorArticles[authorName]
          ? [...authorArticles[authorName], mediaItem.id]
          : [mediaItem.id]
      }
      if (
        mediaItem.data.authors &&
        mediaItem.data.authors.data &&
        mediaItem.data.authors.data.name
      ) {
        const authorName = asText(mediaItem.data.author.data.name)
          .replace(/"/g, "")
          .trim()
        authors[authorName] = authors[authorName]
          ? { ...authors[authorName], count: authors[authorName].count + 1 }
          : {
              id: authorName,
              label: authorName,
              count: tags[authorName] ? tags[authorName].count : 1,
            }
        authorArticles[authorName] = authorArticles[authorName]
          ? [...authorArticles[authorName], mediaItem.id]
          : tags[authorName]
          ? [...tags[authorName], mediaItem.id]
          : [mediaItem.id]
      }
    })
    setAuthorIds(authorArticles)
    setAllTags({ ...tags, ...authors })
  }, [complete])

  const loadFeatureAndOrdered = () => {
    client
      .query(Prismic.predicates.at("document.type", "search_tags"))
      .then((faoData) => {
        setSuggested(
          faoData.results[0].data.suggested_tags.map((t) => asText(t.tag))
        )
        setOrderedTags(
          faoData.results[0].data.ordered_tags.map((t) => asText(t.tag))
        )
      })
  }

  useEffect(() => {
    loadFeatureAndOrdered()
  }, [])

  const setContent = (content) => {
    const ids = []
    const deduped = []
    content.forEach((item) => {
      if (ids.indexOf(item.id) === -1) {
        deduped.push(item)
      }
    })
    const slices = []
    for (let si = 0; si < Math.ceil(deduped.length / 8); si += 1) {
      if (si === 0) {
        slices.push(...[deduped.slice(0, 10)])
      } else {
        slices.push(...[deduped.slice(si * 10, si * 10 + 10)])
      }
    }
    setMedia({
      all: deduped,
      slices,
    })
  }

  useEffect(() => {
    if (!noResultsTL.current) return
    if (noResults) {
      noResultsTL.current.play()
    } else {
      noResultsTL.current.reverse()
    }
  }, [noResults])

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus({
        preventScroll: true,
      })
    }
    noResultsTL.current = gsap.timeline({ paused: true })
    noResultsTL.current.to(noResultsRef.current, {
      opacity: 1,
      y: 0,
      duration: 0.5,
    })
    return () => {
      if (noResultsTL.current) {
        noResultsTL.current.kill()
      }
    }
  }, [])

  useEffect(() => {
    if (selectedTags.length === 0 && searchedTags.length === 0) {
      setMedia(null)
    } else {
      let tagsToUse = selectedTags.length > 0 ? selectedTags : searchedTags
      tagsToUse = tagsToUse.map((t) => t.trim())
      client
        .query(
          [
            Prismic.predicates.at("document.type", "media"),
            Prismic.Predicates.any("document.tags", tagsToUse),
          ],
          {
            fetchLinks: [
              "person.name",
              "person.image",
              "person.position",
              "guest.name",
              "guest.image",
              "guest.position",
            ],
            orderings: "[document.first_publication_date desc]",
          }
        )
        .then((goodsData) => {
          const contents = goodsData.results
          const selectedAuthors = tagsToUse.filter((tag) => authorIds[tag])
          if (selectedAuthors.length) {
            let aIds = []
            selectedAuthors.forEach((a) => {
              aIds = [...aIds, ...authorIds[a]]
            })
            client
              .query(
                [
                  Prismic.predicates.at("document.type", "media"),
                  Prismic.Predicates.any("document.id", aIds),
                ],
                {
                  orderings: "[document.first_publication_date desc]",
                }
              )
              .then((authorResults) => {
                const authorContents = authorResults.results
                setContent([...contents, ...authorContents])
              })
          } else {
            setContent(contents)
          }
        })
    }
  }, [selectedTags, searchedTags])

  useEffect(() => {
    if (resultsEl.current) {
      const tl = gsap.timeline()
      resultsEl.current
        .querySelectorAll(".mediaBlockWrapper")
        .forEach((el, index) => {
          tl.to(
            el,
            {
              opacity: 1,
              y: 0,
              duration: 0.5,
            },
            index * 0.125
          )
        })
    }
  }, [media])

  const watchInputKeydown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      setSelectedTags([])
      setSearchedTags(didYouMeans)
      if (didYouMeans.length) {
        setNoResults(false)
      }
    }
  }

  return (
    <>
      <ModalNav
        items={[]}
        labelIndex={0}
        closeModal={closeModal}
        isSearch
        // disabled={goodOpen}
      />
      <div className="search">
        <div className="search__topper">
          <span className="search__slug">Search</span>
          <div className="search__input-wrapper">
            <textarea
              className="search__input"
              type="text"
              ref={searchInput}
              onChange={watchSearch}
              placeholder="Goods to search"
              onInput={autoGrow}
              onKeyDown={watchInputKeydown}
              spellCheck={false}
            />
          </div>
          <div className="search__suggestions">
            <span className="search__suggestions-slug">
              {didYouMeans && !noResults ? "Did you mean:" : "Suggested"}
            </span>
            <div
              className={classNames("search__suggestions-list", {
                "search__suggestions-list--searching":
                  didYouMeans && !noResults,
              })}
              ref={suggestedList}
            >
              {didYouMeans && !noResults ? (
                <>
                  {didYouMeans.map((suggestion) => (
                    <Suggestion
                      key={suggestion}
                      suggestion={suggestion}
                      allTags={allTags}
                      selectSuggestion={selectSuggestion}
                      selectedTags={selectedTags}
                      hidden={false}
                    />
                  ))}
                </>
              ) : (
                <SearchSuggestions
                  suggested={suggested}
                  allTags={allTags}
                  selectSuggestion={selectSuggestion}
                  selectedTags={selectedTags}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames("search__results", {
            "search__results--none": noResults,
          })}
          ref={resultsEl}
        >
          <PageHeader
            headerRef={noResultsRef}
            text="Sorry, we could not find results for your search"
          />
          {media &&
            media.all &&
            media.slices &&
            [...Array(Math.ceil(media.all.length / 10))].map((_, gi) => (
              <div
                key={`mcrb-${gi}`}
                className="mediaContainer mediaContainer--group"
              >
                {[...Array(Math.ceil(media.slices[gi].length / 2))].map(
                  (__, i) => (
                    <div className="mediaContainerRow" key={`mcr-${i}`}>
                      {media.slices[gi]
                        .slice(i * 2, i * 2 + 2)
                        .map((medium, index) => (
                          <MediaBlock
                            key={medium.id}
                            onClick={() => {
                              dispatch(setSearchOpen(false))

                              onMediaBlockSelect(medium)
                            }}
                            isLarge={
                              (i === 0 && index === 1) ||
                              (i === 2 && index === 0) ||
                              (i === 4 && index === 1)
                            }
                            mediaType={medium.data.media_type}
                            tag={medium.data.filter_type}
                            thumbnail={medium.data.media_image.url}
                            externalLink={
                              medium.data.external_link
                                ? medium.data.external_link.url
                                : null
                            }
                            title={asText(medium.data.title)}
                            date={medium.publishDateForSorting}
                          />
                        ))}
                    </div>
                  )
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default GoodSearch
