import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import JumpTo from "../JumpTo"

import "./FixedNav.scss"

function FixedNav({
  jumpToLinks,
  scrollingId,
  navId,
  modalOpen,
  showNav,
  selected,
}) {
  const scrollTimeout = useRef(null)
  const mobileTablet =
    window.innerWidth <= 767 && window.innerWidth < window.innerHeight

  const [active, setActive] = useState(null)
  const [navTranslation, setNavTranslation] = useState(
    mobileTablet ? "-100%" : "100%"
  )
  const [navOpacity, setNavOpacity] = useState(0)
  const [changing, setChanging] = useState(false)

  const watchActive = () => {
    const elToWatch =
      window.innerWidth > 767 ? document.getElementById(scrollingId) : window
    if (elToWatch) {
      const scrollPos =
        window.innerWidth > 767 ? elToWatch.scrollTop : elToWatch.scrollY
      let activeTab = null
      jumpToLinks.forEach((link) => {
        const section = document.getElementById(link.tag)
        const offsetAdjustment = window.innerWidth > 767 ? 220 : 120
        if (
          section &&
          scrollPos >= section.offsetTop - offsetAdjustment &&
          scrollPos < section.offsetTop + section.offsetHeight
        ) {
          activeTab = link.tag
        }
        setActive(activeTab)
      })
    }
  }

  const watchScroll = () => {
    if (!selected) {
      return
    }
    clearTimeout(scrollTimeout.current)
    scrollTimeout.current = setTimeout(() => {
      setChanging(false)
    }, 100)
    if (window.innerWidth < 768 || document.getElementById(scrollingId)) {
      const elToWatch =
        window.innerWidth > 767 ? document.getElementById(scrollingId) : window
      const scrollPos =
        window.innerWidth > 767 ? elToWatch.scrollTop : elToWatch.scrollY
      const jumpLinks = document.getElementById(navId)
      if (!elToWatch || !jumpLinks) return
      const scrollDiff = mobileTablet
        ? scrollPos - jumpLinks.offsetTop - jumpLinks.offsetHeight / 2
        : scrollPos - jumpLinks.offsetTop
      const ratio = scrollDiff / jumpLinks.offsetHeight
      if (scrollDiff >= 0 && ratio < 1) {
        setNavOpacity(ratio)
        if (mobileTablet) {
          setNavTranslation(`${(100 - ratio * 100) * -1}%`)
        } else {
          setNavTranslation(`${100 - ratio * 100}%`)
        }
      } else if (scrollDiff < 0) {
        if (mobileTablet) {
          setNavTranslation("-100%")
        } else {
          setNavTranslation("100%")
        }
        setNavOpacity(0)
      } else if (ratio > 1) {
        setNavTranslation(0)
        setNavOpacity(1)
      }

      // Set Active
      if (!changing) {
        watchActive()
      }
    }
  }

  useEffect(() => {
    if (!changing) {
      watchActive()
    } else {
      setActive(null)
    }
  }, [changing])

  useEffect(() => {
    if (!selected) {
      setNavTranslation("100%")
      setNavOpacity(0)
    }
  }, [selected])

  useEffect(() => {
    if (modalOpen) {
      setNavOpacity(0)
    } else {
      watchScroll()
    }
  }, [modalOpen])

  useEffect(() => {}, [active])

  useEffect(() => {
    if (document.getElementById("people-tab-body") && window.innerWidth > 767) {
      document
        .getElementById("people-tab-body")
        .addEventListener("scroll", watchScroll, { passive: true })
    } else if (window.innerWidth <= 767) {
      window.addEventListener("scroll", watchScroll, { passive: true })
    }
    return () => {
      if (document.getElementById("people-tab-body")) {
        document
          .getElementById("people-tab-body")
          .removeEventListener("scroll", watchScroll, { passive: true })
      } else if (window.innerWidth <= 767) {
        window.removeEventListener("scroll", watchScroll, { passive: true })
      }
    }
  })
  return (
    <div
      className={classNames("fixed-nav", {
        "fixed-nav--disabled": modalOpen,
        "fixed-nav--nav-open": showNav,
      })}
    >
      <div
        className="fixed-nav__inner"
        id="fixed-nav__inner"
        style={{
          transform: `translateY(${navTranslation})`,
          opacity: navOpacity,
        }}
      >
        <JumpTo
          jumpToLinks={jumpToLinks}
          noscroller
          id="fixedPeopleJump"
          active={active}
          setChanging={setChanging}
        />
      </div>
    </div>
  )
}

export default FixedNav
