import React from "react"

import "./TextWithCTA.scss"

const TextWithCTA = (props) => {
  const { data, handleClick } = props

  return (
    <div className="text-with-cta">
      <p className="headline">{data.headline}</p>
      <p>{data.body}</p>
      <button
        className="primaryButton primaryButton--blue"
        onClick={handleClick}
        type="button"
      >
        {data.cta}
      </button>
    </div>
  )
}

export default TextWithCTA
