/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from "react"
import { TweenLite as tween } from "gsap"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { selectEvent, selectFullScreenVideo } from "../../state/reducers/utils"

import iconClose from "../../images/icons/close.svg"

import "./index.scss"

const Modal = ({ closeModal, content, withinTab, modalSection, isAux }) => {
  const modalEl = useRef(null)
  const modalBackdropEl = useRef(null)
  const liveEvent = useSelector(selectEvent)
  const fullScreenVideo = useSelector(selectFullScreenVideo)

  const [customStyle, setCustomStyle] = useState()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (content) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [content])

  const setPositions = () => {
    let pos = null
    if (window.innerWidth >= 768) {
      if (modalSection === "search") {
        pos = {
          left: liveEvent && liveEvent.is_public ? "175px" : "115px",
          right: 0,
        }
      } else {
        switch (withinTab) {
          case "expertise":
            pos = {
              left: liveEvent && liveEvent.is_public ? "60px" : 0,
              right: "118px",
            }
            break
          case "people":
            pos = {
              left: liveEvent && liveEvent.is_public ? "120px" : "60px",
              right: "59px",
            }
            break
          case "goods":
            pos = {
              left: liveEvent && liveEvent.is_public ? "175px" : "115px",
              right: 0,
            }
            break
          case "aux":
            pos = { left: 0, right: 0 }
            break
          default:
            pos = { left: 0, right: 0 }
            break
        }
      }
    }
    setCustomStyle(pos)
  }

  useEffect(() => {
    if (!modalEl || !modalBackdropEl) return
    // scroll to top by default
    modalEl.current.scrollTop = 0
    let modalHeight = 0
    if (isOpen) {
      modalHeight =
        window.innerWidth < 768
          ? "100%"
          : withinTab === "goods"
          ? "calc(100% - 60px)"
          : "calc(100% - 60px)"
    }
    tween.to(modalEl.current, {
      height: modalHeight,
      duration: 0.5,
    })
    tween.to(modalBackdropEl.current, {
      display: isOpen ? "block" : "none",
      opacity: isOpen ? 1 : 0,
      duration: 0.2,
    })
  }, [isOpen])

  useEffect(() => {
    setPositions()
  }, [withinTab, modalSection, liveEvent])

  useEffect(() => {
    const resizeSetPositions = () => {
      setPositions()
    }
    const keyboardClose = (event) => {
      if (event.keyCode === 27 && isOpen) {
        closeModal()
      }
    }
    window.addEventListener("resize", resizeSetPositions, false)
    document.addEventListener("keydown", keyboardClose, false)
    return () => {
      window.removeEventListener("resize", setPositions, false)
      document.removeEventListener("keydown", keyboardClose, false)
    }
  }, [liveEvent, isOpen])

  return (
    <div
      className={classNames(`home-modal home-modal--${modalSection}`, {
        "full-width": withinTab && modalSection !== "search",
        "home-modal--has-event": liveEvent && liveEvent.is_public,
        "home-modal--full-screen": fullScreenVideo,
        "home-modal--auxillary": isAux,
      })}
      ref={modalEl}
      style={customStyle}
    >
      <div className="home-modal-content">{content}</div>
      <button className="home-modal-close" onClick={closeModal} type="button">
        <img alt="Close" src={iconClose} />
      </button>
      {withinTab && (
        <div className="home-modal-backdrop" ref={modalBackdropEl} />
      )}
    </div>
  )
}

export default Modal
