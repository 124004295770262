import React from "react"
import { PrismicText } from "@prismicio/react"
import "./index.scss"

const Manager = ({ person, inEvent }) => (
  <figure className="management-figure__inner">
    <div className="img-wrapper">
      <img src={person.image.url} alt={person.image.alt} />
    </div>
    <figcaption>
      <span className="name">
        <PrismicText field={person.name} />
      </span>
      <span className="position">
        <PrismicText field={person.position} />
      </span>
      {inEvent && (
        <span className="company">
          {person.company ? (
            <PrismicText field={person.company} />
          ) : (
            "MediaLink"
          )}
        </span>
      )}
    </figcaption>
  </figure>
)

export default Manager
