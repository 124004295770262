import React, { useRef, useEffect } from "react"
import classNames from "classnames"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import "./JumpTo.scss"

function JumpTo({
  selected,
  jumpToLinks,
  scroller,
  noscroller,
  id,
  active,
  setChanging,
}) {
  gsap.registerPlugin(ScrollTrigger)
  const tl = useRef(null)
  const el = useRef(null)

  const destroyTimeline = () => {
    tl.current.kill()
    tl.current = null
  }

  const goToSection = (e, tag, jump) => {
    if (e) {
      e.preventDefault()
    }
    const elToScrollTo = document.getElementById(tag)
    const scrollEl =
      window.innerWidth < 768
        ? window
        : document.getElementById("people-tab-body")
    const offset =
      (window.innerWidth < 768 ? window.scrollY : scrollEl.scrollTop) + 60

    if (
      window.innerWidth < 768 &&
      document.getElementById("fixed-nav__inner")
    ) {
      if (e) {
        document.getElementById("fixed-nav__inner").scrollTo({
          left:
            e.target.offsetLeft +
            e.target.offsetWidth / 2 -
            window.innerWidth / 2 +
            16,
          top: 0,
          behavior: "smooth",
        })
      }
    }

    const scrollObj = {
      left: 0,
      top: elToScrollTo.offsetTop - offset,
    }
    if (setChanging) {
      setChanging(true)
    }
    if (!jump) {
      scrollObj.behavior = jump ? null : "smooth"
    }
    scrollEl.scrollBy(scrollObj)
  }

  useEffect(() => {
    if (selected && window.goToWorkingAt) {
      window.goToWorkingAt = false
      setTimeout(() => {
        document.getElementById("jumpto-people-working-at").click()
      }, 750)
    }
  }, [selected])

  useEffect(() => {
    if (
      !noscroller &&
      (window.innerWidth < 768 || document.getElementById("people-tab-body"))
    ) {
      const scrollTriggerConfig = {
        trigger: `#${id}`,
        start: "bottom bottom-=60px",
        toggleActions: "play play play reverse",
      }
      if (scroller !== "body" && window.innerWidth >= 768) {
        scrollTriggerConfig.scroller = "#people-tab-body"
      }
      if (selected) {
        if (tl.current) {
          destroyTimeline()
        }
        tl.current = gsap.timeline({
          scrollTrigger: scrollTriggerConfig,
        })
        jumpToLinks.forEach((_, index) => {
          tl.current.to(
            `.jumpToLink-${index}`,
            {
              y: 0,
              opacity: 1,
              duration: 0.5,
            },
            0.125 * index
          )
        })
      } else if (tl && tl.current) tl.current.kill()
    }
  }, [selected])

  return (
    <nav
      id={id}
      className={classNames("jumpTo", { "jumpTo--noscroll": noscroller })}
      ref={el}
    >
      <span>JUMP TO</span>
      <div className="jumpToLinks" id={`${id}Links`}>
        {jumpToLinks.map((link, index) => (
          <a
            className={classNames(`jumpToLink-${index}`, {
              "jumpToLink--active": active === link.tag,
            })}
            id={`jumpto-${link.tag}`}
            key={link.tag}
            href={`#${link.tag}`}
            onClick={(e) => goToSection(e, link.tag)}
          >
            {link.label}
          </a>
        ))}
      </div>
    </nav>
  )
}

export default JumpTo
