import gsap from "gsap/all"
import React, { useLayoutEffect, useRef, useState } from "react"
import Suggestion from "../Suggestion"

const SearchSuggestions = ({
  suggested,
  allTags,
  selectSuggestion,
  selectedTags,
}) => {
  const belowDesktop =
    (window.innerWidth <= 1024 && window.innerWidth < window.innerHeight) ||
    window.innerWidth < 1024
  const tl = useRef(null)
  const showMoreRef = useRef(false)
  const showMoreButton = useRef(null)
  const suggestedList = useRef(null)
  const [visibleSuggestionCount, setVisibleSuggestionCount] = useState(null)
  const [showMoreOffset, setShowMoreOffset] = useState(0)

  useLayoutEffect(() => {
    if (!suggestedList.current) return
    let fromHeight = 10
    let toHeight = 10
    if (belowDesktop) {
      suggestedList.current
        .querySelectorAll(".search__suggestion")
        .forEach((el, index) => {
          if (index < 3) {
            fromHeight += el.offsetHeight
          }
          toHeight += el.offsetHeight
        })
    } else {
      fromHeight = 45
      toHeight = suggestedList.current.scrollHeight
    }
    // const toHeight = belowDesktop ? fromHeight * suggestedList.current.querySelectorAll(".search__suggestion").length : suggestedList.current.scrollHeight
    if (tl.current) {
      tl.current.seek(0)
      tl.current.kill()
    }
    tl.current = gsap.timeline({
      paused: true,
      onComplete: () => {
        if (suggestedList.current) {
          suggestedList.current.classList.add("search__suggestions--visible")
        }
      },
      onReverseComplete: () => {
        if (suggestedList.current) {
          suggestedList.current.classList.remove("search__suggestions--visible")
        }
      },
    })
    tl.current.fromTo(
      suggestedList.current,
      {
        height: fromHeight,
        duration: belowDesktop ? 0.25 : 0.1,
      },
      {
        height: toHeight,
        duration: belowDesktop ? 0.25 : 0.1,
      },
      0
    )
    suggestedList.current.querySelectorAll(".hidden").forEach((el, index) => {
      tl.current.to(
        el,
        {
          opacity: 1,
          duration: belowDesktop ? 0.25 : 0.5,
        },
        belowDesktop ? 0.25 + index * 0.125 : index * 0.125
      )
    })
  })

  useLayoutEffect(() => {
    if (!suggestedList.current || allTags.length === 0) return
    let offset = 0
    let visibleCount = 0
    if (belowDesktop) {
      setVisibleSuggestionCount(3)
    } else {
      // setTimeout(() => {
      if (!suggestedList.current) return
      suggestedList.current
        .querySelectorAll(".search__suggestion")
        .forEach((el) => {
          if (el.offsetTop === 0) {
            offset += el.offsetWidth
            el.style.opacity = 1
            visibleCount += 1
          }
        })

      setVisibleSuggestionCount(visibleCount)
      setShowMoreOffset(offset)
      // }, 750)
    }
  }, [suggested, allTags])

  const showMore = () => {
    if (showMoreRef.current) {
      showMoreRef.current = false
      showMoreButton.current.textContent = "See More"
      tl.current.reverse()
    } else {
      tl.current.play()
      showMoreRef.current = true
      showMoreButton.current.textContent = "See Less"
    }
  }
  return (
    <>
      <div className="search__suggestions-inner" ref={suggestedList}>
        {suggested &&
          suggested.map((suggestion, index) => (
            <Suggestion
              key={suggestion}
              suggestion={suggestion}
              allTags={allTags}
              selectSuggestion={(sug) => selectSuggestion(sug, true)}
              selectedTags={selectedTags}
              hidden={index >= visibleSuggestionCount}
            />
          ))}
      </div>
      <button
        ref={showMoreButton}
        type="button"
        className="showMore"
        onClick={showMore}
        style={{ opacity: showMoreOffset ? 1 : 0, left: showMoreOffset }}
      >
        See More
      </button>
    </>
  )
}

export default SearchSuggestions
