/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react"

const SliceGallery = ({ id, slice }) => {
  const [imageRows, setImageRows] = useState([])
  useEffect(() => {
    const rowArrays = []
    const items = [...slice.items]
    while (items.length > 0) {
      rowArrays.push(items.splice(0, 2))
    }
    setImageRows(rowArrays)
  }, [slice])

  return (
    <div className="slice-gallery">
      {imageRows.map((row, index) => (
        <div
          key={`gallery-row-${id}-${index}`}
          className={`
            slice-gallery__row 
            ${
              index % 3 === 2
                ? "slice-gallery__row--third"
                : index % 3 === 1
                ? "slice-gallery__row--second"
                : "slice-gallery__row--first"
            }
          `}
        >
          {row.map((image) => (
            <div
              key={id + image.image.url + index}
              className={`slice-gallery__img-wrapper slice-gallery__img-wrapper--${image.image_size.toLowerCase()}`}
            >
              <div className="slice-gallery__img-inner">
                <img
                  className="slice-gallery__img"
                  src={image.image.url}
                  alt={image.image.alt}
                />
              </div>
              {image.caption.text}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default SliceGallery
