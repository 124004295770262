import React, { useState } from "react"
import Form from "../common/Form"
import successIcon from "../../images/icons/cta-success-icon.svg"

import "./CTA.scss"

const CTA = ({ selected, ctaId }) => {
  const [success, setSuccess] = useState(null)
  const [submitText, setSubmitText] = useState("Subscribe")

  const handleSubmission = (status) => {
    setSubmitText(status === "success" ? "Subscribing" : "Subscribe")
    setSuccess(status === "success")
    return setSuccess(true)
  }

  const formGroups = [
    {
      keys: ["Email"],
    },
  ]

  return (
    <div className="ctaWrapper">
      <h2>Get the Goods.</h2>
      <p>
        Consider our newsletter a white glove insight delivery service.
        Interested?
      </p>
      {success ? (
        <div className="success">
          <p>You&apos;ve subscribed successfully.</p>
          <img alt="success-marker" src={successIcon} />
        </div>
      ) : (
        <>
          {selected && (
            <Form
              formConfig={["//592-QPU-703.mktoweb.com", "592-QPU-703", 1020]}
              formId={`CTA${ctaId}`}
              groups={formGroups}
              submitText={submitText}
              setFormStatus={handleSubmission}
            />
          )}
        </>
      )}
    </div>
  )
}

export default CTA
