/* eslint-disable no-use-before-define */
import { navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { useLocation } from "@reach/router"
import { useDispatch, useSelector } from "react-redux"
import { PrismicText, PrismicRichText } from "@prismicio/react"
import { asText, asLink } from "@prismicio/helpers"

import services from "../../../static/data/services.json"
import GoodModal from "../../Goods/Modal/Modal"
import ModalGoods from "./Goods"
import ModalNav from "../../common/ModalNav"

import "./PeopleModal.scss"

import linkedIn from "../../../images/icons/linkedin.svg"
import ExpertiseModal from "../../Expertise/ExpertiseModal"
import {
  selectSecondaryModalClose,
  setCloseSecondary,
  setSecondaryModalOpen,
} from "../../../state/reducers/utils"

const attributes = [
  {
    label: "Total Years of Experience",
    key: "experience",
  },
  {
    label: "Favorite sport to play <br/>(or watch)",
    key: "sport",
  },
  {
    label: "Language(s) you speak",
    key: "languages",
  },
  {
    label: "Favorite travel destination",
    key: "travel_destination",
  },
  {
    label: "Favorite animal",
    key: "animal",
  },
  {
    label: "Favorite book",
    key: "book",
  },
  {
    label: "Favorite artist",
    key: "artist",
  },
  {
    label: "Favorite hobby <br/>or pastime",
    key: "hobby",
  },
  {
    label: "Favorite movie",
    key: "movie",
  },
  {
    label: "Do you have a<br/> hidden talent?",
    key: "hidden_talent",
  },
]

function PeopleModal({
  people,
  personIndex,
  closeModal,
  showContactForm,
  inEvent,
  onSelect,
}) {
  const dispatch = useDispatch()
  const serviceSlugMap = {}
  services.forEach((service) => {
    serviceSlugMap[service.title] = service.slug
  })
  const location = useLocation()
  const peopleEl = useRef(null)
  const secondaryModalClose = useSelector(selectSecondaryModalClose)
  const [currentIndex, setCurrentIndex] = useState(inEvent ? personIndex : null)
  const [goodOpen, setGoodOpen] = useState(false)
  const [showService, setShowService] = useState(false)
  const [serviceOpen, setServiceOpen] = useState(false)
  const [serviceIndex, setServiceIndex] = useState(false)
  const [goodModalContent, setGoodModalContent] = useState(null)
  const [hasEducation, setHasEducation] = useState(false)
  const [personAttributes, setPersonAttributes] = useState(null)
  const [showGoodModal, setShowGoodModal] = useState(false)
  const [person, setPerson] = useState(null)
  // people && typeof personIndex !== "undefined" ? people[personIndex] : null
  const [hasGoods, setHasGoods] = useState(false)
  const [changing, setChanging] = useState(true)

  const watchHashChange = () => {
    if (window.location.hash === "") {
      closeSecondary()
    }
  }

  const openServiceModal = (serviceSlug) => {
    const si = services.findIndex((service) => service.slug === serviceSlug)
    setServiceIndex(si)
    setServiceOpen(true)
    dispatch(setSecondaryModalOpen(true))
    window.addEventListener("hashchange", watchHashChange)
  }

  const openGoodModal = (good) => {
    setGoodOpen(true)
    setTimeout(() => {
      setGoodModalContent(good)
    }, 500)
  }

  const handleClose = (e, goToGoods) => {
    e.stopPropagation()
    if (serviceOpen) {
      closeSecondary()
    } else if (!inEvent && !serviceOpen) {
      navigate("/people")
      closeModal()
    } else {
      closeModal()
    }
    if (goToGoods) {
      if (onSelect) {
        setTimeout(() => {
          onSelect({ id: "goods", title: "The Goods", path: "/goods" })
        }, 500)
      } else {
        navigate("/goods")
      }
    }
  }

  const renderAttributeLabel = (label) => ({ __html: label })

  const closeSecondary = () => {
    setShowService(false)
    setTimeout(() => {
      setServiceOpen(false)
    }, 500)
    dispatch(setCloseSecondary(false))
    window.removeEventListener("hashchange", watchHashChange)
  }
  useEffect(() => {
    if (secondaryModalClose && showService && !inEvent) {
      window.history.back()
    } else if (secondaryModalClose && showService) {
      closeSecondary()
    }
  }, [secondaryModalClose])

  useEffect(() => {
    if (!person) return
    if (person && person.education && asText(person.education) !== "") {
      setHasEducation(true)
    } else {
      setHasEducation(false)
    }
    const currentAttributes = []
    attributes.forEach((attribute) => {
      if (person[attribute.key] && asText(person[attribute.key]) !== "") {
        currentAttributes.push({
          label: attribute.label,
          value: person[attribute.key],
        })
      }
    })
    setPersonAttributes(currentAttributes)
    const actualGoods =
      person.goods &&
      person.goods.filter(
        (good) => good.media.id && good.media.type !== "broken_type"
      )
    setHasGoods(actualGoods && actualGoods.length > 0)
  }, [person])

  useEffect(() => {
    if (goodModalContent) {
      setTimeout(() => {
        setShowGoodModal(true)
      }, 250)
    }
  }, [goodModalContent])

  useEffect(() => {
    if (serviceOpen) {
      setShowService(true)
    }
  }, [serviceOpen])

  useEffect(() => {
    if (inEvent && currentIndex !== null && !person) {
      setPerson(people[currentIndex])
    }
  }, [currentIndex, inEvent])

  useEffect(() => {
    if (!changing && currentIndex !== null) {
      setChanging(true)
      setTimeout(() => {
        if (peopleEl.current) {
          peopleEl.current.scrollTo(0, 0)
        }
        if (showService) {
          closeSecondary()
        }
        const currentPeople = people[currentIndex]
        if (!currentPeople) return
        const { uid, id } = currentPeople
        if (location.pathname !== `/people/${uid || id}` && !inEvent) {
          navigate(`/people/${uid || id}`)
        }
        setPerson(people[currentIndex])
      }, 250)
      setTimeout(() => {
        setChanging(false)
      }, 500)
    } else {
      setChanging(false)
    }
    setGoodOpen(false)
    setTimeout(() => setGoodModalContent(null), 1000)
  }, [currentIndex])

  useEffect(() => {
    if (!location.pathname.includes("/people")) return
    if (
      (location.pathname === "/people/" || location.pathname === "/people") &&
      person
    ) {
      closeModal()
    } else if (inEvent) {
      setCurrentIndex(personIndex)
    } else {
      const personId = location.pathname.split("/")[2]
      const index = people.findIndex((pers) => pers?.uid === personId)
      setCurrentIndex(index)
      if (!personId) {
        navigate(`/people/${people[personIndex].uid}`)
      } else {
        if (personId && !person) {
          setPerson(people[index])
        }
        setCurrentIndex(index)
      }
    }
  }, [location])

  return (
    <>
      <ModalNav
        items={people}
        labelIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        closeModal={handleClose}
        isPeople
        // disabled={goodOpen}
      />
      {serviceOpen && (
        <div
          className={classNames("person-specialty-modal", {
            "person-specialty-modal--visible": showService,
          })}
        >
          <ExpertiseModal
            noNav
            data={services}
            serviceIndex={serviceIndex}
            inEvent={inEvent}
            closeModal={() => {
              setServiceOpen(false)
            }}
            showContactForm={showContactForm}
            setServiceOpened={setServiceOpen}
          />
        </div>
      )}
      {goodModalContent && (
        <div
          className={classNames("person-good-modal", {
            "person-good-modal--visible": showGoodModal,
          })}
        >
          {/* <div className="person-good-modal__action-bar">
              <ContainedButton text={`Close Article`} onClick={closeGood} />
            </div> */}
          <GoodModal
            insideModal
            selectedArticle={{ data: goodModalContent }}
            articles={[{ data: goodModalContent }]}
          />
        </div>
      )}
      {person && !goodModalContent && (
        <div
          className={classNames("people-modal", {
            "people-modal--hidden": goodOpen,
            "people-modal--blur": showService,
          })}
          ref={peopleEl}
        >
          <div
            className={classNames("people-modal__inner", {
              "people-modal__inner--changing": changing,
            })}
          >
            <div className="people-modal__top">
              <div className="people-modal__namebio">
                <h2>
                  <PrismicText field={person.name} />
                </h2>
                <h3>
                  <PrismicText field={person.position} />
                </h3>
                {inEvent && (
                  <h3 className="company">
                    {person.company ? (
                      <PrismicText field={person.company} />
                    ) : (
                      "MediaLink"
                    )}
                  </h3>
                )}
                {asLink(person.linkedin) && (
                  <div className="people-modal__namebio-social">
                    <a
                      href={asLink(person.linkedin)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={linkedIn} alt="LinkedIn icon" />
                    </a>
                  </div>
                )}
                <div className="people-modal__image">
                  <div className="people-modal__image-wrapper">
                    <span
                      className="img-sizer"
                      style={{
                        paddingTop: `${
                          (person.image.dimensions.height /
                            person.image.dimensions.width) *
                          100
                        }%`,
                      }}
                    />
                    <img src={person.image.url} alt={person.image.alt} />
                  </div>
                </div>
                <div className="people-modal__namebio-bio">
                  <PrismicRichText field={person.bio} />
                </div>
              </div>
              <div className="people-modal__imagemeta">
                <div className="people-modal__image">
                  <div className="people-modal__image-wrapper">
                    <span
                      className="img-sizer"
                      style={{
                        paddingTop: `${
                          (person.image.dimensions.height /
                            person.image.dimensions.width) *
                          100
                        }%`,
                      }}
                    />
                    <img src={person.image.url} alt={person.image.alt} />
                  </div>
                </div>
                {personAttributes && personAttributes.length > 0 && (
                  <div className="people-modal__imagemeta-meta">
                    <table>
                      <tbody>
                        {personAttributes &&
                          personAttributes.map((attribute) => (
                            <tr key={attribute.label + attribute.value}>
                              <td
                                aria-label={attribute.label}
                                dangerouslySetInnerHTML={renderAttributeLabel(
                                  attribute.label
                                )}
                              />
                              <td aria-label={attribute.value}>
                                <PrismicText field={attribute.value} />
                              </td>
                            </tr>
                          ))}
                        {hasEducation && (
                          <tr>
                            <td>Education</td>
                            <td aria-label={person.education}>
                              <PrismicText field={person.education} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                {person.specialty &&
                  person.specialty.length > 0 &&
                  asText(person.specialty[0].specialty_area) !== "" && (
                    <div className="people-modal__imagemeta-specialties">
                      <strong>Specialties</strong>
                      {person.specialty.map((specialty) => (
                        <button
                          type="button"
                          onClick={() =>
                            openServiceModal(
                              serviceSlugMap[
                                asText(specialty.specialty_area).trim()
                              ]
                            )
                          }
                          key={
                            asText(person.name) +
                            asText(specialty.specialty_area)
                          }
                          aria-label={asText(specialty.specialty_area)}
                        >
                          <PrismicText field={specialty.specialty_area} />
                        </button>
                      ))}
                    </div>
                  )}
              </div>
            </div>
            {hasGoods && (
              <div className="people-modal__bottom">
                <ModalGoods
                  person={person}
                  openGoodModal={openGoodModal}
                  personId={person.uid}
                  closeModal={handleClose}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default PeopleModal
