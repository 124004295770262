/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames"
import { PrismicText } from "@prismicio/react"
import { asText } from "@prismicio/helpers"
import React, { useEffect, useState, useRef } from "react"
import { isIOS } from "react-device-detect"
import { useDispatch } from "react-redux"
import PlayIcon from "../../../../images/icons/play-icon.svg"
import { setFullScreenVideo } from "../../../../state/reducers/utils"
import FullScreenVideo from "../../../FullScreenVideo"
import "./index.scss"

const PauseOverlay = ({ video, setPlaying }) => (
  <div className="pause-overlay">
    <div className="pause-overlay__backdrop" />
    <div className="pause-overlay__content">
      <div className="h1">
        <span>
          <PrismicText field={video.first_name} />
        </span>
        <span>
          <PrismicText field={video.last_name} />
        </span>
      </div>
      <div className="company-title">
        <PrismicText field={video.company_title} />
      </div>
      <button
        type="button"
        onClick={() => setPlaying(true)}
        className="contained-button"
      >
        <span>Play</span>
        <img src={PlayIcon} alt="Play" />
      </button>
    </div>
  </div>
)

const TestimonialVideo = ({ video, openTestimonialVideo, activeVideo }) => {
  const dispatch = useDispatch()
  const currentVid = useRef("")
  const fsState = useRef(null)
  const [showVideo, setShowVideo] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [fullScreen, setFullScreen] = useState(true)
  const [player, setPlayer] = useState(null)
  const [muted, setMuted] = useState(false)

  const handleVolume = (audioOn) => {
    if (!player) return
    if (audioOn) {
      if (player.unMute) {
        player.unMute()
        player.setVolume(100)
      } else {
        player.setVolume(1)
      }
    } else if (!audioOn) {
      if (player.unMute) {
        player.mute()
        player.setVolume(0)
      } else {
        player.setVolume(0)
      }
    }
  }

  const playVideo = () => {
    setShowVideo(true)
    setPlaying(true)
    handleVolume(true)
  }

  useEffect(() => {
    handleVolume(!muted)
  }, [muted])

  useEffect(() => {
    if (activeVideo !== video.id && playing) {
      setPlaying(false)
    }
  }, [activeVideo])

  useEffect(() => {
    if (currentVid.current !== video.id) {
      currentVid.current = video.id
      setPlaying(false)
      setShowVideo(false)
      setFullScreen(true)
    }
  }, [video])

  useEffect(() => {
    if (playing) {
      openTestimonialVideo(video.id)
    }
  }, [playing])

  useEffect(() => {
    dispatch(setFullScreenVideo(fullScreen && showVideo))
  }, [fullScreen, showVideo])

  useEffect(() => {
    if (fullScreen && fsState.current !== null) {
      openTestimonialVideo(video.id)
    } else if (!fullScreen) {
      fsState.current = false
    }
  }, [fullScreen])
  return (
    <>
      <div className="expertise-modal__videos-item">
        <div
          className={classNames("expertise-modal__videos-item-image", {
            "expertise-modal__videos-item-image--playing": showVideo,
            "expertise-modal__videos-item-image--fullscreen":
              !isIOS && fullScreen,
          })}
        >
          <button
            type="button"
            onClick={playVideo}
            className="expertise-modal__videos-item-image-button"
          >
            <img
              src={video.image.url}
              alt={asText(video.first_name) + asText(video.last_name)}
            />
            <span className="play-button">
              <img src={PlayIcon} alt="play" />
            </span>
          </button>
          {video.video && (
            <FullScreenVideo
              video={video.video}
              className="video"
              inDiv={!fullScreen || isIOS}
              showControls
              setFullScreen={() => setFullScreen(!fullScreen)}
              fullScreen={fullScreen}
              setPlayer={setPlayer}
              pauseOverlay={
                <PauseOverlay video={video} setPlaying={setPlaying} />
              }
              setParentPlaying={setPlaying}
              parentPlaying={playing}
              parentMuted={muted}
              setParentMuted={() => setMuted(!muted)}
            />
          )}
        </div>
        <div className="expertise-modal__videos-item-info">
          <span className="name">
            {`
              ${asText(video.first_name)}
              ${asText(video.last_name)}
            `}
          </span>
          <span className="title">
            <PrismicText field={video.company_title} />
          </span>
        </div>
      </div>
    </>
  )
}

export default TestimonialVideo
