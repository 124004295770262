import { PrismicRichText } from "@prismicio/react"
import React from "react"

// slice.richText is the format for legacy content
// slice.primary.rich_content.richText is the format for new content
const RichContent = ({ slice }) => (
  <div className="slice-rich-content">
    <PrismicRichText
      field={slice.richText || slice.primary?.rich_content?.richText || slice}
    />
  </div>
)

export default RichContent
