import { navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { asText } from "@prismicio/helpers"
import { osVersion, isMobileSafari, isSafari } from "react-device-detect"

import PageHeader from "../common/PageHeader"
import MediaBlock from "../MediaBlock/MediaBlock"
import CTA from "../CTA/CTA"
import Footer from "../Footer/Footer"

import Filter from "./Filter/Filter"
import Hero from "./Hero/Hero"
import Modal from "./ModalV2/Modal"

import GoodsAnimations from "./animations"

import "./Goods.scss"

import headerBG from "../../images/shared/header-bg.webp"
import headerBGPNG from "../../images/shared/header-bg.png"

// import LoadMoreIcon from "../../images/icons/load-more-icon.svg"

const Goods = ({
  goToNextSection,
  scroller,
  selected,
  setModalContent,
  showNav,
  pageContext,
}) => {
  const [mediaDataTop, setMediaDataTop] = useState(null)
  const [mediaData, setMediaData] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState(null)

  const [allMedia, setAllMedia] = useState(null)
  const [switching, setSwitching] = useState(false)
  const [animationsSet, setAnimationsSet] = useState(false)
  const [animations, setAnimations] = useState(false)
  const [dataAnimationsSet, setDataAnimationsSet] = useState(false)
  const [mediaByFilter, setMediaByFilter] = useState([])
  const goodsWrapper = useRef(null)
  const mediaSlices = useRef(null)
  const heroRef = useRef(null)
  const headerRef = useRef(null)

  const closeGoodsModal = () => {
    setModalContent(null)
  }

  useEffect(() => {
    if (
      !selected &&
      document.getElementById("goods-tab-body") &&
      window.innerWidth > 767
    ) {
      setTimeout(() => {
        document.getElementById("goods-tab-body").scrollTo(0, 0)
      }, 500)
    }
  }, [selected])

  useEffect(() => {
    const { articles, article } = pageContext
    if (articles && articles.length) {
      setAllMedia(articles)
      // group articles by their filters for easy filtering
      const queryResults = articles.reduce((result, item) => {
        const { filter_type: filterType } = item.data
        if (!result[filterType]) {
          result[filterType] = []
        }
        result[item.data.filter_type].push(item)
        return result
      }, {})
      setMediaByFilter(queryResults)
    }
    if (article) {
      setModalContent(
        <Modal
          selectedArticle={article}
          articles={[article]}
          closeModal={closeGoodsModal}
          setModalContent={setModalContent}
        />
      )
    }
  }, [pageContext])

  useEffect(() => {
    if (selected && headerRef.current && !animationsSet) {
      setAnimationsSet(true)
      setTimeout(
        () => {
          const goodsAnimations = new GoodsAnimations()
          setAnimations(goodsAnimations)
        },
        document.body.classList.contains("intro-finished") ? 0 : 500
      )
    } else if (animations && !headerRef.current) {
      animations.destroy()
      setAnimationsSet(false)
      setAnimations(null)
    } else if (animations && heroRef.current && !dataAnimationsSet) {
      setDataAnimationsSet(true)
      animations.initDataAnimations()
      animations.initFilterAnimations()
    }
  })

  useEffect(() => {
    if (allMedia && !switching) {
      const slices = []
      const filteredMedia = selectedFilter
        ? mediaByFilter[selectedFilter]
        : allMedia
      setSwitching(true)
      for (let si = 0; si < Math.ceil(filteredMedia.length / 8); si += 1) {
        if (si === 0) {
          slices.push(...[filteredMedia.slice(4, 10)])
        } else {
          slices.push(...[filteredMedia.slice(si * 10, si * 10 + 10)])
        }
      }
      setTimeout(() => {
        setMediaDataTop(filteredMedia.slice(0, 4))
        setMediaData({
          all: filteredMedia.slice(4, filteredMedia.length),
          slices,
        })
        setTimeout(() => {
          setSwitching(false)
          if (animations) {
            animations.initFilterAnimations()
          }
        }, 250)
      }, 250)
    }
  }, [selectedFilter, allMedia])

  // const loadMore = () => {
  //   if (prismicData) {
  //     const { page, total_pages: totalPages } = prismicData
  //     if (page < totalPages) retrieveMedia(page + 1)
  //   }
  // }

  const setRoute = (media) => {
    const { uid, id } = media
    navigate(`/goods/${uid || id}`)
  }

  const onHeroSelect = (media) => {
    setRoute(media)
    setModalContent(
      <Modal
        selectedArticle={media}
        articles={mediaDataTop.concat(mediaData.all)}
        closeModal={closeGoodsModal}
        setModalContent={setModalContent}
      />
    )
  }

  const onMediaBlockSelect = (media) => {
    setRoute(media)
    setModalContent(
      <Modal
        selectedArticle={media}
        articles={mediaDataTop.concat(mediaData.all)}
        closeModal={closeGoodsModal}
        setModalContent={setModalContent}
      />
    )
  }

  return (
    <div className="goodsWrapper" id="goods-section" ref={goodsWrapper}>
      <div className="goodsContainer">
        <PageHeader
          selected={selected}
          headerRef={headerRef}
          background={
            isSafari || (isMobileSafari && osVersion.match(/13\.|12\./g))
              ? headerBGPNG
              : headerBG
          }
          text="Insights, updates and more good stuff."
        />
        <Hero selected={selected} heroRef={heroRef} onSelect={onHeroSelect} />
        <Filter
          onChange={(filter) =>
            selectedFilter === filter
              ? setSelectedFilter(null)
              : setSelectedFilter(filter)
          }
          data={allMedia}
          mediaByFilter={mediaByFilter}
          activeFilter={selectedFilter}
          scroller={scroller}
          selected={mediaData && selected}
          loaded={!!allMedia}
          setModalContent={setModalContent}
          onMediaBlockSelect={onMediaBlockSelect}
        />
        {/* TODO: handle media data grid gracefully... */}
        <div
          className={classNames("mediaContainer mediaContainer--top", {
            "mediaContainer--switching": switching,
          })}
        >
          {mediaDataTop &&
            [...Array(Math.ceil(mediaDataTop.length / 2))].map((_, i) => (
              <div key={`mcrt-${i}`} className="mediaContainerRow">
                {mediaDataTop.slice(i * 2, i * 2 + 2).map((media, index) => (
                  <MediaBlock
                    key={media.id}
                    onClick={() => {
                      onMediaBlockSelect(media)
                    }}
                    isLarge={i !== 1 && index === 0}
                    mediaType={media.data.media_type}
                    tag={media.data.filter_type}
                    thumbnail={media.data.media_image.url}
                    externalLink={
                      media.data.external_link
                        ? media.data.external_link.url
                        : null
                    }
                    title={asText(media.data.title.richText)}
                    date={media.publishDateForSorting}
                  />
                ))}
              </div>
            ))}
        </div>
        <CTA selected={selected} ctaId="goods" />
        {mediaData &&
          [...Array(Math.ceil(mediaData.all.length / 10))].map((_, gi) => (
            <div
              key={`mcrb-${gi}`}
              ref={mediaSlices}
              className={classNames("mediaContainer mediaContainer--group", {
                "mediaContainer--switching": switching,
              })}
            >
              {mediaData &&
                mediaData.all &&
                mediaData.slices &&
                [...Array(Math.ceil(mediaData.slices[gi].length / 2))].map(
                  (__, i) => (
                    <div className="mediaContainerRow" key={`mcr-${i}`}>
                      {mediaData.slices[gi]
                        .slice(i * 2, i * 2 + 2)
                        .map((media, index) => (
                          <MediaBlock
                            key={media.id}
                            onClick={() => {
                              onMediaBlockSelect(media)
                            }}
                            isLarge={
                              (i === 0 && index === 1) ||
                              (i === 2 && index === 0) ||
                              (i === 4 && index === 1)
                            }
                            mediaType={media.data.media_type}
                            tag={media.data.filter_type}
                            thumbnail={media.data.media_image.url}
                            externalLink={
                              media.data.external_link
                                ? media.data.external_link.url
                                : null
                            }
                            title={asText(media.data.title.richText)}
                            date={media}
                          />
                        ))}
                    </div>
                  )
                )}
            </div>
          ))}
        {/* <button
          onClick={loadMore}
          type="button"
          className={classNames("loadMoreContainer", {
            "loadMoreContainer--disabled":
              (prismicData && prismicData.page >= prismicData.total_pages) ||
              selectedFilter,
          })}
        >
          <h3>Load more</h3>
          <img alt="load more" src={LoadMoreIcon} />
        </button> */}
      </div>
      <Footer
        nextSection="Contact Us"
        onSectionChange={goToNextSection}
        showNav={showNav}
        selected={selected}
      />
    </div>
  )
}

export default Goods
