import React from "react"

import "./InfoCard.scss"

const InfoCard = (props) => {
  const { data } = props

  return (
    <div className="info-card">
      <p className="eyebrow">{data.eyebrow}</p>
      <p className="title">{data.headline}</p>
      <p>{data.body}</p>
    </div>
  )
}

export default InfoCard
