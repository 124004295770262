import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"
import gsap from "gsap/gsap-core"

import NumberFigure from "./Number"
import ProgressBar from "./Progress"

import "./AnimatedNumber.scss"

const AnimatedNumber = ({
  id,
  numbers,
  size,
  sizing,
  displayWhenVisible,
  scroller,
}) => {
  const element = useRef(null)
  const numbersEl = useRef(null)
  const triggerPositions = useRef({})
  const animating = useRef(false)
  const animationCompleted = useRef(true)

  const [processedNumbers, setProcessedNumbers] = useState(null)
  const [elWidth, setElWidth] = useState(null)
  const [elHeight, setElHeight] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(-1)

  const setWidth = (newWidth) => {
    if (window.innerWidth < 768) {
      newWidth = window.innerWidth - 80
    }
    if (newWidth !== elWidth) {
      setElWidth(newWidth)
    }
  }
  const setHeight = (newHeight) => {
    if (newHeight !== elHeight) {
      setElHeight(newHeight)
    }
  }

  const setPositions = () => {
    if (displayWhenVisible && numbers.length === 1) {
      triggerPositions.current[0] =
        numbersEl.current.offsetTop +
        numbersEl.current.offsetHeight -
        window.innerHeight * (1 - displayWhenVisible.offset / 100)
    } else {
      switch (numbers.length) {
        case 3:
          triggerPositions.current[0] =
            numbersEl.current.offsetTop +
            numbersEl.current.offsetHeight / 2 -
            window.innerHeight * 0.8
          triggerPositions.current[1] =
            numbersEl.current.offsetTop +
            numbersEl.current.offsetHeight / 2 -
            window.innerHeight * 0.6
          triggerPositions.current[2] =
            numbersEl.current.offsetTop +
            numbersEl.current.offsetHeight / 2 -
            window.innerHeight * 0.3
          break
        case 2:
          triggerPositions.current[0] =
            numbersEl.current.offsetTop +
            numbersEl.current.offsetHeight / 2 -
            window.innerHeight * 1.2
          triggerPositions.current[1] =
            numbersEl.current.offsetTop +
            numbersEl.current.offsetHeight / 2 -
            window.innerHeight * 0.5
          triggerPositions.current[2] = null
          break
        default:
          triggerPositions.current[0] =
            numbersEl.current.offsetTop +
            numbersEl.current.offsetHeight / 2 -
            window.innerHeight * 0.66
          triggerPositions.current[1] = null
          triggerPositions.current[2] = null
          break
      }
    }
  }

  const watchPosition = () => {
    if (!element.current || animating.current || !animationCompleted.current) {
      return
    }
    const scrollPos = document.getElementById(scroller)
      ? document.getElementById(scroller).scrollTop
      : window.scrollY
    setPositions()
    if (numbers.length > 2 && scrollPos > triggerPositions.current[2]) {
      setCurrentIndex(2)
    } else if (numbers.length > 1 && scrollPos > triggerPositions.current[1]) {
      setCurrentIndex(1)
    } else if (scrollPos > triggerPositions.current[0]) {
      setCurrentIndex(0)
    } else if (scrollPos < triggerPositions.current[0]) {
      setCurrentIndex(-1)
    }
  }

  const markAnimationComplete = () => {
    animationCompleted.current = true
  }

  useEffect(() => {
    const sizeTL = gsap.timeline()
    if (sizing.type === "dynamic") {
      sizeTL.to(
        numbersEl.current,
        {
          maxWidth: `${elWidth}px`,
          maxHeight: `${elHeight}px`,
          duration: 0.375,
        },
        0
      )
    } else {
      sizeTL.to(
        numbersEl.current,
        {
          maxWidth: sizing.maxWidth,
          maxHeight: `${elHeight}px`,
          duration: 0,
        },
        0
      )
    }
  }, [elWidth, elHeight, sizing])

  useEffect(() => {
    if (processedNumbers) {
      return
    }
    const pns = numbers.map((numberObj) => ({
      number: numberObj.number,
      numberSplit: numberObj.number.split(""),
      caption: numberObj.caption,
    }))
    setProcessedNumbers(pns)
  }, [numbers])

  useEffect(() => {
    if (currentIndex >= 0 && !animating.current) {
      animationCompleted.current = false
      animating.current = true
      setTimeout(() => {
        animating.current = false
      }, 1000)
    }
  }, [currentIndex, animating])

  useEffect(() => {
    if (document.getElementById(scroller)) {
      document
        .getElementById(scroller)
        .addEventListener("scroll", watchPosition, { passing: true })
    } else {
      window.addEventListener("scroll", watchPosition, { passing: true })
    }
    return () => {
      if (document.getElementById(scroller)) {
        document
          .getElementById(scroller)
          .removeEventListener("scroll", watchPosition, {
            passing: true,
          })
      } else {
        window.removeEventListener("scroll", watchPosition)
      }
    }
  }, [])

  return (
    <div
      ref={element}
      className={classNames(
        "animatedNumber",
        `animatedNumber--${size}`,
        `animatedNumber--${sizing.type}`
      )}
    >
      {numbers.length > 1 && (
        <ProgressBar count={numbers.length} currentIndex={currentIndex} />
      )}
      <div className="animatedNumberFigures" ref={numbersEl}>
        {processedNumbers &&
          processedNumbers.map((pn, pni) => (
            <NumberFigure
              id={id}
              key={`${id}animatedNumber${pn.number}`}
              pn={pn}
              pni={pni}
              currentIndex={currentIndex}
              setWidth={setWidth}
              setHeight={setHeight}
              animationComplete={markAnimationComplete}
            />
          ))}
      </div>
    </div>
  )
}

export default AnimatedNumber
