import React from "react"

import MainContent from "../components/MainContent"

const Layout = ({ children, pageContext }) => (
  <>
    {children}
    <MainContent pageContext={pageContext} />
  </>
)

export default Layout
