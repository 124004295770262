import React, { useEffect, useRef, useState } from "react"
import classnames from "classnames"
import Prismic from "@prismicio/client"
import { asText } from "@prismicio/helpers"
import { osVersion, isMobileSafari, isSafari } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import {
  selectSecondaryModalStatus,
  setCloseSecondary,
} from "../../state/reducers/utils"
import headerBG from "../../images/shared/header-bg.webp"
import headerBGPNG from "../../images/shared/header-bg.png"
import PeopleHero from "./Hero"
import PageHeader from "../common/PageHeader"
import JumpTo from "./JumpTo"
import Management from "./Management"
import SeniorLeaders from "./SeniorLeaders"
import WorkingAtMediaLink from "./WorkingAtMediaLink"
import Footer from "../Footer/Footer"
import PeopleModal from "./PeopleModal"
import FixedNav from "./FixedNav"

import PeopleAnimations from "./animations"

import "./People.scss"

const client = Prismic.client(process.env.GATSBY_PRISMIC_API_ENDPOINT, {
  accessToken: process.env.GATSBY_PRISMIC_API_KEY,
})

const People = ({
  selected,
  scroller,
  setModalContent,
  modalOpen,
  goToNextSection,
  showNav,
  showContactForm,
  onSelect,
}) => {
  const jumpToLinks = [
    { tag: "people-management", label: "Management" },
    { tag: "people-leaders", label: "Senior Leaders" },
    { tag: "people-working-at", label: "Working at MediaLink" },
  ]

  const dispatch = useDispatch()
  const peopleSection = useRef(null)
  const [jobListings, setJobListings] = useState(null)
  const [ceo, setCeo] = useState(null)
  const [management, setManagement] = useState(null)
  const [seniors, setSeniors] = useState(null)
  const [animationsSet, setAnimationsSet] = useState(false)
  const [animations, setAnimations] = useState(false)
  const [initialId, setInitialId] = useState(false)
  const secondaryModalOpen = useSelector(selectSecondaryModalStatus)

  const headerRef = useRef(null)
  const seniorsRef = useRef(null)

  const closeModal = () => {
    if (secondaryModalOpen) {
      dispatch(setCloseSecondary(true))
    } else {
      setModalContent(null)
    }
  }

  const openModal = (people, personIndex) => {
    setModalContent(
      <PeopleModal
        people={people}
        personIndex={personIndex}
        closeModal={closeModal}
        showContactForm={showContactForm}
        onSelect={onSelect}
      />
    )
  }

  const processPerson = (person) => {
    const { data } = person
    data.id = person.id
    data.uid = person.uid
    const personGoods = data.goods
    const allowedGoods = personGoods
      ? personGoods.filter((good) => good.media && good.media.id)
      : []
    if (allowedGoods.length === 0) {
      delete data.goods
    }
    return data
  }

  const sortPeople = (people) =>
    people.sort((a, b) => {
      const aSplit = asText(a.name).trim().split(" ")
      const bSplit = asText(b.name).trim().split(" ")
      if (aSplit[aSplit.length - 1] < bSplit[bSplit.length - 1]) {
        return -1
      }
      if (aSplit[aSplit.length - 1] > bSplit[bSplit.length - 1]) {
        return 1
      }
      return 0
    })

  const parsePeople = (peopleResults) => {
    if (peopleSection.current) {
      let ceoObj = peopleResults.find(
        (person) => person.tags.indexOf("ceo") >= 0
      )
      if (ceoObj) {
        ceoObj = processPerson(ceoObj)
        setCeo(ceoObj)
      }
      setManagement(
        sortPeople(
          peopleResults
            .filter((person) => person.tags.indexOf("management") >= 0)
            .map(processPerson)
        )
      )
      setSeniors(
        sortPeople(
          peopleResults
            .filter((person) => person.tags.indexOf("senior_leaders") >= 0)
            .map(processPerson)
        )
      )
    }
  }

  useEffect(() => {
    if (initialId && management && seniors && ceo) {
      if (initialId === ceo.uid) {
        openModal([ceo, ...management], 0)
      } else {
        const managementIndex = management.findIndex((x) => x.uid === initialId)
        const seniorsIndex = seniors.findIndex((x) => x.uid === initialId)
        if (managementIndex > -1) {
          openModal([ceo, ...management], managementIndex + 1)
        } else if (seniorsIndex > -1) {
          openModal(seniors, seniorsIndex)
        }
      }
    }
  }, [initialId, management, seniors, ceo])

  useEffect(() => {
    if (!jobListings) {
      client
        .query(Prismic.Predicates.at("document.type", "people"), {
          pageSize: 100,
        })
        .then((pageData) => {
          const { data } = pageData.results[0]
          const { jobs } = data
          if (peopleSection.current) {
            setJobListings(jobs)
          }
        })
    }
    if (!ceo) {
      client
        .query(Prismic.Predicates.at("document.type", "person"), {
          pageSize: 100,
        })
        .then((res) => {
          parsePeople(res.results)
          const id = window.location.pathname.split("people/")[1]
          if (id) {
            setInitialId(id.replace("/", ""))
          }
        })
    }
  }, [])

  useEffect(() => {
    if (
      !selected &&
      document.getElementById("people-tab-body") &&
      window.innerWidth > 767
    ) {
      setTimeout(() => {
        document.getElementById("people-tab-body").scrollTo(0, 0)
      }, 500)
    }
  }, [selected])

  useEffect(() => {
    if (selected && headerRef.current && !animationsSet) {
      setAnimationsSet(true)
      setTimeout(
        () => {
          const peopleAnimations = new PeopleAnimations()
          setAnimations(peopleAnimations)
        },
        document.body.classList.contains("intro-finished") ? 0 : 500
      )
    } else if (animations && !headerRef.current) {
      animations.destroy()
      setAnimationsSet(false)
      setAnimations(null)
    }
  })

  return (
    <section
      id="people-section"
      className={classnames("people-section", { activeSection: selected })}
      ref={peopleSection}
    >
      <FixedNav
        jumpToLinks={jumpToLinks}
        scrollingId="people-tab-body"
        navId="peopleJumpToLinks"
        modalOpen={modalOpen}
        showNav={showNav}
        selected={selected}
      />
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 5555,
        }}
      />
      <div className="people-section__inner">
        <div className="people-section__heading">
          <PageHeader
            headerRef={headerRef}
            selected={selected}
            text="People"
            background={
              isSafari || (isMobileSafari && osVersion.match(/13\.|12\./g))
                ? headerBGPNG
                : headerBG
            }
          />
        </div>
        <PeopleHero selected={selected} />
        <p className="people-section__intro">
          Our team is made up of the most talented solutions-specialists in the
          game—a team that isn&apos;t satisfied with handing off strategies and
          leaving the table. We advise throughout the entirety of each
          engagement, like a great partner should.
        </p>
        <div className="people-section__subintro-wrapper">
          <p className="people-section__subintro">
            Our combination of diverse backgrounds also ensures the right person
            for your job is on the job, every time. With over 200 renowned
            subject matter experts onboard, we bring unparalleled experience and
            a vast network of connections to tap into.
          </p>
        </div>
        <JumpTo
          selected={selected}
          scroller={scroller}
          jumpToLinks={jumpToLinks}
          id="peopleJumpTo"
        />
        <Management ceo={ceo} managers={management} openModal={openModal} />
        <SeniorLeaders
          seniorsRef={seniorsRef}
          seniors={seniors}
          openModal={openModal}
        />
        <WorkingAtMediaLink jobListings={jobListings} scroller={scroller} />
      </div>
      <Footer
        nextSection="The Goods"
        onSectionChange={goToNextSection}
        showNav={showNav}
        selected={selected}
      />
    </section>
  )
}

export default People
