/* eslint-disable import/prefer-default-export */

export const filters = [
  {
    name: "Good Ideas",
    description: "A peek into how we think differently",
  },
  {
    name: "Good Chats",
    description: "Recent discussions with industry leaders",
  },
  {
    name: "Good News",
    description: "Noteworthy articles worth your time",
  },
]
