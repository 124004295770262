import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)
class ExpertiseAnimations {
  constructor() {
    this.destroy = this.destroyTimelines
    this.init()
  }

  init() {
    this.mediaBlockWrappers = null
    this.sectionIntros = null
    this.expertiseTl = {}
    this.expertiseEl = document.getElementById("expertise-section")
    this.initExpertise()
  }

  destroyTimelines() {
    this.destroyExpertise()
  }

  initExpertise() {
    this.destroyExpertise()

    const standardFrom = {
      opacity: 0,
      y: 20,
    }

    const standardTo = {
      opacity: 1,
      y: 0,
      duration: 0.75,
    }

    // if theres nothing to animate, bail out.
    if (!this.expertiseEl) return

    const servicesEl = this.expertiseEl.querySelector(".services")
    const scrollTriggerConfig = {
      start: "top-=100px center",
      toggleActions: "play play play reverse",
    }
    if (window.innerWidth >= 768) {
      scrollTriggerConfig.scroller = "#expertise-tab-body"
    }

    this.expertiseTl.opener = gsap.timeline({
      onComplete: () => {
        this.expertiseTl.services = gsap.timeline({
          scrollTrigger: {
            ...scrollTriggerConfig,
            trigger: servicesEl,
            start: "top bottom-=80px",
          },
        })
        this.expertiseTl.services.to(servicesEl, { opacity: 1 }, 0)

        this.expertiseTl.serviceHeading = gsap.timeline({
          scrollTrigger: {
            ...scrollTriggerConfig,
            trigger: servicesEl.querySelector(".services__heading"),
            start: "top bottom-=80px",
          },
        })

        this.expertiseTl.serviceHeading.fromTo(
          servicesEl.querySelector(".services__heading"),
          standardFrom,
          standardTo,
          0.25
        )
      },
    })

    this.expertiseTl.opener.to(
      this.expertiseEl.querySelector(".page-header"),
      standardTo,
      0
    )
    this.expertiseTl.opener.fromTo(
      this.expertiseEl.querySelector(".expertise-hero__intro"),
      standardFrom,
      standardTo,
      0.125
    )

    this.expertiseTl.opener.play()

    const wwd = this.expertiseEl.querySelector(".wwd")

    this.expertiseTl.wwd = gsap.timeline({
      scrollTrigger: {
        ...scrollTriggerConfig,
        trigger: wwd.querySelector(".wwd__heading"),
        start: "top bottom-=80px",
      },
    })

    this.expertiseTl.wwd.fromTo(
      wwd.querySelector(".wwd__heading"),
      standardFrom,
      standardTo,
      0.125
    )

    this.expertiseTl.carousel = gsap.timeline({
      scrollTrigger: {
        ...scrollTriggerConfig,
        trigger: this.expertiseEl.querySelector(".carousel"),
        start: "top bottom-=80px",
      },
    })

    this.expertiseTl.carousel.fromTo(
      this.expertiseEl.querySelector(".carousel"),
      standardFrom,
      standardTo,
      0.125
    )

    this.expertiseTl.git = gsap.timeline({
      scrollTrigger: {
        ...scrollTriggerConfig,
        trigger: this.expertiseEl.querySelector(".get-in-touch"),
        start: "top bottom-=80px",
      },
    })

    this.expertiseTl.git.fromTo(
      this.expertiseEl.querySelector(".get-in-touch"),
      standardFrom,
      standardTo,
      0.125
    )

    this.expertiseTl.serviceIntro = gsap.timeline({
      scrollTrigger: {
        ...scrollTriggerConfig,
        trigger: this.expertiseEl.querySelector(".services__body"),
        start: "top bottom-=80px",
      },
    })

    this.expertiseTl.serviceIntro.fromTo(
      this.expertiseEl.querySelectorAll(".services__body-p"),
      standardFrom,
      standardTo,
      0.125
    )

    gsap.set(servicesEl.querySelectorAll(".services__content-li"), {
      opacity: 0,
    })
    this.mediaBlockWrappers = ScrollTrigger.batch(
      servicesEl.querySelectorAll(".services__content-li"),
      {
        interval: 0.1,
        onEnter: (batch) =>
          gsap.to(batch, {
            opacity: 1,
            duration: 0.25,
            stagger: { each: 0.25 },
            overwrite: true,
          }),
        onLeaveBack: (batch) =>
          gsap.to(batch, {
            opacity: 0,
            duration: 0.75,
            stagger: { each: 0.25 },
            overwrite: true,
          }),
        start: "top bottom-=20px",
        scroller: scrollTriggerConfig.scroller,
      }
    )
  }

  destroyExpertise() {
    Object.keys(this.expertiseTl).forEach((tlKey) => {
      this.expertiseTl[tlKey].kill()
    })
    if (this.mediaBlockWrappers) {
      this.mediaBlockWrappers.forEach((tl) => {
        tl.kill()
      })
    }
    if (this.sectionIntros) {
      this.sectionIntros.forEach((tl) => {
        tl.kill()
      })
    }
  }
}
export default ExpertiseAnimations
