import "../auxillary.scss"
import "./ces.scss"
import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import Vimeo from "@u-wave/react-vimeo"

import CESFooter from "./CESFooter/CESFooter"
import Modal from "../../Modal"
import CESHero from "./CESHero"

import YahooImg from "../../../images/yahoo-ces.png"

import Data from "./data"

const CES = () => {
  const location = useLocation()
  const [modalContent, setModalContent] = useState(null)

  useEffect(() => {
    const pathElements = location.pathname.split("/")
    // const pathName = pathElements[1]
    const subPathName = pathElements[2]
    if (subPathName) {
      setModalContent(<CESVideo id={subPathName} />)
    }
  }, [location])

  return (
    <>
      <section className="aux-ces">
        <div className="auxillary ces-main">
          {/* <PageHeader
            text="Welcome to MediaLink CES 2022"
            background={
              isSafari || (isMobileSafari && osVersion.match(/13\.|12\./g))
                ? headerBGPNG
                : headerBG
            }
          /> */}
          <CESHero />
          <div className="auxillary__inner ces-inner">
            <ul className="mediaContainer">
              {Data.map((d) => (
                <CESItem setModalContent={setModalContent} data={d} />
              ))}
              <li className="ces-data-item">
                <a
                  className="ces-button"
                  href="https://adtechb2b.yahooinc.com/ces-2022-tour-video?utm_source=medialink&utm_medium=email&utm_campaign=ces2022&utm_id=7017V000000xfccQAA"
                  target="new"
                >
                  <div className="ces-header">
                    <div className="ces-header-left">
                      <h3 className="ces-category">VIRTUAL TOUR</h3>
                      <svg
                        className="ces-play-icon"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1.5 17.75C1.5 8.77537 8.77537 1.5 17.75 1.5C26.7246 1.5 34 8.77537 34 17.75C34 26.7246 26.7246 34 17.75 34C8.77537 34 1.5 26.7246 1.5 17.75ZM17.75 0C7.94695 0 0 7.94695 0 17.75C0 27.5531 7.94695 35.5 17.75 35.5C27.5531 35.5 35.5 27.5531 35.5 17.75C35.5 7.94695 27.5531 0 17.75 0ZM24.125 18.3995C24.357 18.2655 24.5 18.018 24.5 17.75C24.5 17.4821 24.357 17.2345 24.125 17.1005L15.125 11.9043C14.893 11.7704 14.6071 11.7704 14.375 11.9043C14.143 12.0383 14 12.2859 14 12.5538L14 22.9462C14 23.2141 14.1429 23.4617 14.375 23.5957C14.6071 23.7296 14.8929 23.7296 15.125 23.5957L24.125 18.3995ZM15.5 13.8529L22.25 17.75L15.5 21.6471V13.8529Z" />
                      </svg>
                    </div>
                    <div
                      className="thumbnail"
                      style={{
                        backgroundImage: `url(${YahooImg})`,
                      }}
                    />
                  </div>
                  <h2 className="ces-title">
                    MediaLink and Yahoo’s CES 2022 Floor Tour
                  </h2>
                  <div className="participants">MediaLink, Yahoo</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <CESFooter />
      <Modal
        closeModal={() => setModalContent(null)}
        content={modalContent}
        withinTab="aux"
      />
    </>
  )
}

const CESItem = ({ setModalContent, data }) => (
  <li className="ces-data-item">
    <button
      className="ces-button"
      type="button"
      onClick={() => setModalContent(<CESVideo data={data} />)}
    >
      <div className="ces-header">
        <div className="ces-header-left">
          <h3 className="ces-category">{data.category}</h3>
          <svg
            className="ces-play-icon"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5 17.75C1.5 8.77537 8.77537 1.5 17.75 1.5C26.7246 1.5 34 8.77537 34 17.75C34 26.7246 26.7246 34 17.75 34C8.77537 34 1.5 26.7246 1.5 17.75ZM17.75 0C7.94695 0 0 7.94695 0 17.75C0 27.5531 7.94695 35.5 17.75 35.5C27.5531 35.5 35.5 27.5531 35.5 17.75C35.5 7.94695 27.5531 0 17.75 0ZM24.125 18.3995C24.357 18.2655 24.5 18.018 24.5 17.75C24.5 17.4821 24.357 17.2345 24.125 17.1005L15.125 11.9043C14.893 11.7704 14.6071 11.7704 14.375 11.9043C14.143 12.0383 14 12.2859 14 12.5538L14 22.9462C14 23.2141 14.1429 23.4617 14.375 23.5957C14.6071 23.7296 14.8929 23.7296 15.125 23.5957L24.125 18.3995ZM15.5 13.8529L22.25 17.75L15.5 21.6471V13.8529Z" />
          </svg>
        </div>
        <div
          className="thumbnail"
          style={{
            backgroundImage: `url(https://vumbnail.com/${data.vimeoId}_medium.jpg)`,
          }}
        />
      </div>
      <h2 className="ces-title">{data.title}</h2>
      <div className="participants">
        {data.participants ? data.participants.sort().join(", ") : null}
      </div>
    </button>
  </li>
)

const CESVideo = ({ data }) => {
  useEffect(() => {
    try {
      setTimeout(() => {
        // eslint-disable-next-line no-underscore-dangle
        window.__vimeoRefresh()
      }, 3000)
    } catch (e) {
      console.log("error refreshing video tracking")
    }
  }, [])
  const getSpeakers = (key) => {
    if (!data[key]) return null
    let title = ""
    switch (key) {
      case "speakersPanel":
        title = "Speakers: Panel"
        break
      case "speakersFireside":
        title = "Speakers: Fireside Chat"
        break
      default:
        title = "Speakers"
    }
    return (
      <>
        <div className="ces-modal-speakers-title">{title}</div>
        <ul>
          {data[key].map((s) => (
            <li id={`ces-speaker-${title}`}>{s}</li>
          ))}
        </ul>
      </>
    )
  }
  return (
    <div className="auxillary-modal">
      <div className="ces-modal">
        <div className="ces-modal-category">
          CES 2022 // {data.category || null}
        </div>
        <div className="ces-modal-title">{data.title || null}</div>
        <div className="ces-modal-date">{data.date || null}</div>
        <Vimeo
          video={data.vimeoId}
          className="ces-video"
          width="100%"
          responsive
        />
        <div className="ces-modal-description">{data.description || null}</div>
        <div className="ces-modal-speakers">
          {getSpeakers("speakers")}
          {getSpeakers("speakersFireside")}
          {getSpeakers("speakersPanel")}
        </div>
      </div>
    </div>
  )
}

export default CES
