/* eslint-disable import/prefer-default-export */

import TagManager from "react-gtm-module"

/**
 * Handles google scroll tracking logic and datalayer configuration setting
 * @param {Event} e
 */
export const handleScrollTracking = (e) => {
  const elScrollPos = e.target.scrollTop + window.innerHeight - 60 // 60 denotes the height of the navigation element (outside of the tab view)
  const elScrollHeight = e.target.scrollHeight
  const { pathname } = window.location
  const { hash } = window.location

  const checkPosition = (range1, range2, eventLabel) => {
    if (
      elScrollPos >= elScrollHeight * range1 &&
      elScrollPos <= elScrollHeight * range2
    ) {
      if (
        !window.dataLayer.find(
          (el) =>
            el.eventLabel === eventLabel && el.eventAction === pathname + hash
        )
      ) {
        TagManager.dataLayer({
          dataLayer: {
            event: "generalEvent",
            eventCategory: "scroll depth",
            eventAction: pathname + hash,
            eventLabel,
          },
        })
      }
    }
  }

  checkPosition(0.25, 0.5, "25%")
  checkPosition(0.5, 0.75, "50%")
  checkPosition(0.75, 1, "75%")
  checkPosition(1, 1, "100%")
}

export const handleViewTracking = (originalLocation, pageTitle, customPath) => {
  const { pathname, hash } = window.location
  const pagePath = customPath || pathname + hash
  if (
    !window.dataLayer.find(
      (el) =>
        el.pagePath &&
        el.pagePath.replaceAll("/", "") === pagePath.replaceAll("/", "") &&
        el.originalLocation === originalLocation
    )
  ) {
    TagManager.dataLayer({
      dataLayer: {
        event: "virtualPageView",
        pagePath,
        pageTitle,
        originalLocation,
      },
    })
  }
}
