import React from "react"
import Carousel from "../../common/Carousel/Carousel"
import InfoCard from "../../common/InfoCard/InfoCard"

import "./WhatWeDo.scss"

const cardsData = [
  {
    id: 0,
    eyebrow: "Marketing Transformation",
    headline: "Streamlining key processes for a Fortune 50 powerhouse",
    body:
      "We designed a new marketing organization structure for one of the world’s largest telecom brands that helped them realize efficiencies, streamline marketing resources, and achieve a more impactful brand presence. The best part? We could quickly validate everything, allowing for immediate business impact. ",
  },
  {
    id: 1,
    eyebrow: "Data & Tech Solutions",
    headline: "Modernizing precision marketing for a global auto giant",
    body:
      "After assessing the existing infrastructure of a global auto brand, we identified several opportunities for a more sophisticated usage of data. By developing and implementing a robust precision marketing framework, we were able to improve customer experience, drive workflow efficiencies, and deliver over $400M in value to our client.",
  },
  {
    id: 2,
    eyebrow: "Growth Strategy",
    headline:
      "Defining clear infrastructure needs for new economic development",
    body:
      "An economic planning board came to us for strategic guidance on the digital infrastructure needs for a region in the UK, in the midst of major development. We delivered a comprehensive recommendation for government structure, construction, transportation, energy, and partner selection—all aimed at supporting rapid social and economic growth.",
  },
  {
    id: 3,
    eyebrow: "Private Equity Advisory",
    headline: "Helping a leading TMT firm assess their investment",
    body:
      "A top-10 technology, media and telecom private equity firm partnered with MediaLink to define and assess the investment merits of a leading ad verification platform. After supporting the deal team and operating partners with pre-acquisition diligence—including market sizing, competitive analysis, product and technology due diligence—we were engaged to refine and implement organic and inorganic growth objectives.",
  },
  {
    id: 4,
    eyebrow: "Talent Advisory & Executive Search",
    headline:
      "Surfacing transformational executive talent for a Fortune 50 multinational",
    body:
      "Following the development of a new marketing organization structure, we worked exclusively with the global CEO to define a crucial profile for the company’s first-ever global Chief Marketing and Growth Officer. With our finger on the pulse of innovative marketing leadership, we were instrumental in identifying, assessing and ultimately securing a highly sought-after female executive for the role.",
  },
  {
    id: 5,
    eyebrow: "Marketing Transformation",
    headline: "Leading a global agency review that drove millions in savings",
    body:
      "A complex portfolio brand with even more complex agency relationships was looking to streamline partners to complement their own in-house programmatic buying team. We designed a new global agency model and led the review to identify the strongest partners to deliver on the diverse needs of the brand. The new structure resulted in $80MM+ in media savings in the first year, and 35% in agency savings year over year.",
  },
  {
    id: 6,
    eyebrow: "Data & Tech Solutions",
    headline: "Helping a massive CPG overhaul precision marketing",
    body:
      "A global CPG knew their approach to precision marketing and data strategy had room for improvement, but a wide range of products and divisions with varying needs made this challenging. We developed a framework for progress across data resources, technology partners, and internal processes that resulted in decreased reliance on agency teams, dramatically reduced data compliance risk, and increased funding for implementation.",
  },
  {
    id: 7,
    eyebrow: "Growth Strategy",
    headline:
      "Designing the roadmap for an iconic retailer’s disruptive media business",
    body:
      "A major US retailer was struggling to bring new products and capabilities to market, and was investing resources into areas with unclear growth opportunities. We were brought in to evaluate product strategy, define a new end-to-end commercialization process, and establish a clear go-to-market strategy. The result? An executive-approved roadmap to $1B in revenue including product strategy changes and rightsizing areas of previous over- and under-investment.",
  },
  {
    id: 8,
    eyebrow: "Private Equity Advisory",
    headline:
      "Informing a purchase opportunity with unmatched insights & guidance",
    body:
      "As a private equity firm contemplated the purchase of a leading digital media platform, MediaLink was engaged to refine their understanding of the market opportunity and the targets’ value proposition & right to win. Amid changing market conditions,  we provided a keen understanding of the marketplace to quantify the opportunities and risks likely to impact the targets’ business, including evolving client priorities, new regulatory frameworks, and convergent competition.",
  },
  {
    id: 9,
    eyebrow: "Marketing Transformation",
    headline: "Optimizing the operating model of a leading beverage brand",
    body:
      "A leading beverage CPG was facing challenges related to underperforming programmatic media investments, legacy partnerships, and an abundance of internal silos. So we came in to assess and define a new optimized operating model. The result? A 10% increase in operational efficiency savings, and a 20% increase in media-generated revenue.",
  },
]

const WhatWeDo = () => (
  <section className="wwd">
    <h2 className="wwd__heading">Success Stories</h2>
    <Carousel spaceBetween={130} spaceBetweenMobile={38}>
      {cardsData.map((card) => (
        <InfoCard key={card.id} data={card} id={card.id} />
      ))}
    </Carousel>
  </section>
)

export default WhatWeDo
