/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import moment from "moment"
import Prismic from "@prismicio/client"
import gsap from "gsap"
import classNames from "classnames"

import MediaBlock from "../../MediaBlock/MediaBlock"
import ModalNav from "../../common/ModalNav"

import { handleViewTracking } from "../../../helpers/gtm"

import linkedinIcon from "../../../images/icons/linkedin.svg"
import copyIcon from "../../../images/icons/media-copy-icon.svg"
import successIcon from "../../../images/icons/cta-success-icon.svg"

import "./Modal.scss"
import RichContent from "./slices/RichContent"
import SliceImage from "./slices/Image"
import SliceGallery from "./slices/Gallery"
import SliceVimeo from "./slices/Vimeo"
import SlicePullquote from "./slices/Pullquote"
import MarketoEmail from "./slices/MarketoEmail"

import SliceApplePodcast from "./slices/ApplePodcast"

const Modal = ({
  selectedArticle,
  articles,
  insideModal,
  closeModal,
  setModalContent,
}) => {
  const [relatedArticles, setRelatedArticles] = useState(null)
  const [copied, setCopied] = useState(false)
  const [authors, setAuthors] = useState(null)
  const [articleIndex, setArticleIndex] = useState(null)
  const [filteredArticles, setFilteredArticles] = useState(null)
  const [viewedArticles, setViewedArticles] = useState({})

  const client = Prismic.client(process.env.GATSBY_PRISMIC_API_ENDPOINT, {
    accessToken: process.env.GATSBY_PRISMIC_API_KEY,
  })

  const timeline = gsap.timeline({ repeat: false })

  // useEffect(() => {
  //   const locationUID = location.pathname.split("/goods/")[1]
  //     ? location.pathname.split("/goods/")[1].replace("/", "")
  //     : null
  //   if (!locationUID && Object.keys(viewedArticles).length > 1) {
  //     setViewedArticles({})
  //     closeModal()
  //   } else if (
  //     locationUID !== selectedArticle.uid &&
  //     viewedArticles[locationUID]
  //   ) {
  //     timeline.to("#article", {
  //       opacity: 0,
  //       duration: 0.45,
  //       onComplete: () => {
  //         document.getElementById("article").scrollTop = 0
  //         setModalContent(
  //           <Modal
  //             selectedArticle={viewedArticles[locationUID]}
  //             articles={filteredArticles}
  //             closeModal={() => setModalContent(null)}
  //             setModalContent={setModalContent}
  //           />
  //         )
  //         timeline.to("#article", {
  //           opacity: 1,
  //           duration: 0.45,
  //         })
  //       },
  //     })
  //   }
  // }, [location])

  const handleCopy = () => {
    setCopied(true)
    const { uid, id } = selectedArticle
    navigator.clipboard.writeText(
      `${window.location.origin}/goods/${uid || id || selectedArticle.data.id}`
    )
    setTimeout(() => {
      setCopied(false)
    }, 2500)
  }

  useEffect(() => {
    if (filteredArticles) {
      setRelatedArticles(null)
      timeline.to("#article", {
        opacity: 1,
        duration: 0.45,
      })
    }
  }, [articleIndex])

  // gets related article information
  useEffect(() => {
    if (selectedArticle) {
      setViewedArticles({
        ...viewedArticles,
        [selectedArticle.uid]: selectedArticle,
      })
      let articlesRelated = selectedArticle.data.related_articles.map(
        (article, index) => {
          if (index < 2) {
            return article.article?.id
          }
          return null
        }
      )
      articlesRelated =
        articlesRelated && articlesRelated.filter((art) => art !== null)
      // get relevant goods data only if they exist
      if (articlesRelated.length > 0 && !articlesRelated.includes(undefined)) {
        client
          .query(Prismic.Predicates.in("document.id", articlesRelated), {
            fetchLinks: [
              "person.name",
              "person.image",
              "person.position",
              "person.company",
              "guest.name",
              "guest.image",
              "guest.position",
              "guest.company",
            ],
          })
          .then((relevantGoods) => {
            setRelatedArticles(relevantGoods.results)
          })
      } else {
        setRelatedArticles(null)
      }
    }
  }, [selectedArticle])

  useEffect(() => {
    if (articles.length > 0) {
      // filter out articles with external links (non-navigational)
      let formattedArticles = articles.filter(
        (article) =>
          typeof article.data !== "undefined" &&
          article.data.external_link?.hasOwnProperty("url") === false
      )

      /**
       * if no article of that type found, default to single article on that type
       * NOTE: this occurs when the selected hero articles are not yet queried in the
       * total media query (due to throttling)
       */
      if (formattedArticles.length < 1) {
        formattedArticles = [selectedArticle]
      }
      let index
      // get currenty index of selected article based on this filtered array
      if (selectedArticle.uid) {
        index = formattedArticles.findIndex(
          (x) => x.uid === selectedArticle.uid
        )
      } else {
        index = formattedArticles.findIndex((x) => x.id === selectedArticle.id)
      }
      setArticleIndex(index)
      setFilteredArticles(formattedArticles)
    }
  }, [])

  // handle page view tracking
  useEffect(() => {
    const id = selectedArticle.uid ? selectedArticle.uid : selectedArticle.id
    if (window.dataLayer && window.dataLayer.length > 1) {
      handleViewTracking(
        window.dataLayer[1].originalLocation,
        "The Goods | Insights, News & Updates | MediaLink",
        `/goods/${id}`
      )
    }
  }, [])

  useEffect(() => {
    const authorsToSet = selectedArticle.data.authors
      ? selectedArticle.data.authors.filter(
          ({ author }) => typeof author?.document !== "undefined"
        )
      : selectedArticle.data.author && selectedArticle.data.author.document.data
      ? [selectedArticle.data]
      : []
    setAuthors(authorsToSet.map((aObj) => aObj.author.document))
  }, [selectedArticle])

  const handleRelatedUpdate = (article) => {
    const index = relatedArticles.findIndex((x) => x.id === article.id)
    timeline.to("#article", {
      opacity: 0,
      duration: 0.45,
      onComplete: () => {
        document.getElementById("article").scrollTop = 0
        navigate(`/goods/${relatedArticles[index].uid}`)
        setViewedArticles({
          ...viewedArticles,
          [relatedArticles[index].uid]: relatedArticles[index],
        })
        setModalContent(
          <Modal
            selectedArticle={relatedArticles[index]}
            articles={filteredArticles}
            closeModal={() => setModalContent(null)}
            setModalContent={setModalContent}
          />
        )
        timeline.to("#article", {
          opacity: 1,
          duration: 0.45,
        })
      },
    })
  }
  if (articleIndex !== null && filteredArticles) {
    return (
      <>
        {!insideModal && (
          <ModalNav
            items={filteredArticles}
            labelIndex={articleIndex}
            setCurrentIndex={(index) => {
              timeline.to("#article", {
                opacity: 0,
                duration: 0.45,
                onComplete: () => {
                  setArticleIndex(index)
                  const { uid, id } = filteredArticles[index]
                  navigate(`/goods/${uid || id}`)
                  document.getElementById("article").scrollTop = 0
                },
              })
            }}
            closeModal={closeModal}
            isGoods
          />
        )}
        <div id="article" className="articleContainer">
          <div className="articleContainer__inner">
            <p>{selectedArticle.data.filter_type}</p>
            <h2 className="modalArticleTitle">
              {selectedArticle.data.title.text}
            </h2>
            <div className="articleDetails">
              <div className="details">
                <p>
                  {moment(selectedArticle.first_publication_date).format(
                    "MMMM DD, YYYY"
                  )}
                </p>
                {authors && authors.length
                  ? authors.map((author) => {
                      if (author && author.data) {
                        return (
                          <p key={author.data.name.text}>
                            {author.data.name.text}
                          </p>
                        )
                      }
                      return null
                    })
                  : null}
              </div>
              <div className="details">
                <p>SHARE</p>
                <button
                  type="button"
                  className={classNames("red", { "red--copied": copied })}
                  onClick={handleCopy}
                >
                  <div className="copy">
                    COPY LINK
                    <img alt="copy" src={copyIcon} />
                  </div>
                  <div className="copy-success">
                    <span>Copied</span>
                    <img alt="success-marker" src={successIcon} />
                  </div>
                </button>
              </div>
            </div>
            <div className="articleContent">
              {selectedArticle.data.content.text ? (
                <div className="legacy-content">
                  <RichContent slice={selectedArticle.data.content} />
                </div>
              ) : (
                <>
                  {selectedArticle.data.body.map((slice, index) => {
                    switch (slice.slice_type) {
                      case "content":
                        return (
                          <div key={slice.slice_type + index}>
                            <RichContent slice={slice} />
                          </div>
                        )
                      case "image":
                        return (
                          <div key={slice.slice_type + index}>
                            <SliceImage slice={slice} />
                          </div>
                        )
                      case "gallery":
                        return (
                          <div key={slice.slice_type + index}>
                            <SliceGallery
                              id={slice.slice_type + index}
                              slice={slice}
                            />
                          </div>
                        )
                      case "vimeo_embed":
                        return (
                          <div key={slice.slice_type + index}>
                            {slice.primary.embed.embed_url.includes(
                              "podcasts.apple"
                            ) ? (
                              <SliceApplePodcast slice={slice} />
                            ) : (
                              <SliceVimeo slice={slice} />
                            )}
                          </div>
                        )
                      case "blockquote":
                        return (
                          <div key={slice.slice_type + index}>
                            <SlicePullquote slice={slice} />
                          </div>
                        )
                      default:
                        return <div key={slice.slice_type + index} />
                    }
                  })}
                </>
              )}
              {selectedArticle.data.mkto_id &&
                selectedArticle.data.mkto_id !== "" && (
                  <MarketoEmail selectedArticle={selectedArticle} />
                )}
            </div>

            {relatedArticles && (
              <div className="relatedMedia">
                <p>
                  More Like This <button type="button">VIEW ALL</button>
                </p>
                <div>
                  {relatedArticles.map((article, index) => (
                    <MediaBlock
                      key={`${selectedArticle.uid}-${article.uid}`}
                      isLarge={index > 0}
                      onClick={() => handleRelatedUpdate(article)}
                      mediaType={article.data.media_type}
                      tag={article.data.filter_type}
                      thumbnail={article.data.media_image.url}
                      externalLink={
                        article.data.external_link
                          ? article.data.external_link.url
                          : null
                      }
                      title={article.data.title[0].text}
                    />
                  ))}
                </div>
              </div>
            )}
            {authors && authors.length && authors[0] ? (
              <div className="author">
                <p>
                  About The Author
                  {authors.length >= 1 ? "s" : ""}
                </p>
                <div className="authors">
                  {authors.map((author) =>
                    author && author.data ? (
                      <div
                        key={`authors-${author?.data.name.text}`}
                        className="authorDetailsContainer"
                      >
                        {author.data.image && (
                          <div className="authorDetailsImageWrapper">
                            <img
                              src={author.data.image.url}
                              alt={author.data.image.alt}
                            />
                          </div>
                        )}
                        <div className="authorDetails">
                          <h2>{author.data.name.text}</h2>
                          <p>
                            {author.data.position.text}
                            <span className="company">
                              {author.data.company && author.data.company
                                ? author.data.company
                                : "MediaLink"}
                            </span>
                          </p>

                          {author.data.linkedin && (
                            <a
                              target="__blank"
                              rel="noopener noreferrer"
                              href={author.data.linkedin.url}
                            >
                              <img alt="linkedin" src={linkedinIcon} />
                            </a>
                          )}
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    )
  }
  return null
}

export default Modal
