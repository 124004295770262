import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)
class PeopleAnimations {
  constructor() {
    this.heroTl = {}
    this.managementTl = {}
    this.managementPeople = null
    this.seniorTl = {}
    this.seniorPeople = null
    this.workingTl = {}
    this.workingJobs = null
    this.workingSlides = null
    this.workingColumns = null
    this.destroy = this.destroyTimelines
    this.initManagementAnimations = this.initManagement
    this.initDataAnimations = this.initDataAnims
    this.peopleEl = document.getElementById("people-section")
    this.init()
  }

  init() {
    this.standardFrom = {
      opacity: 0,
      y: 20,
    }
    this.standardTo = {
      opacity: 1,
      y: 0,
      duration: 0.75,
    }
    this.configDefault = {
      start: "top bottom-=100px",
      toggleActions: "play play play reverse",
    }
    if (window.innerWidth >= 768) {
      this.configDefault.scroller = "#people-tab-body"
    }
    this.initHero()
    this.initWorkingAt()
  }

  initDataAnims() {
    this.initManagement()
    this.initSenior()
  }

  destroyTimelines() {
    this.destroyHero()
    this.destroyManagement()
    this.destroySenior()
    this.destroyWorkingAt()
  }

  initHero() {
    const heroEl = document.getElementById("people-hero")
    this.destroyHero()
    this.heroTl.opener = gsap.timeline({})

    this.heroTl.opener.to(
      this.peopleEl.querySelector(".page-header"),
      this.standardTo,
      0
    )

    this.heroTl.opener.to(
      heroEl.querySelector("img"),
      {
        opacity: 1,
        duration: 0.75,
      },
      0
    )

    this.heroTl.opener.to(
      heroEl.querySelector(".img-wrapper__mask"),
      {
        bottom: "100%",
        duration: 0.625,
      },
      0.125
    )

    this.heroTl.opener.play()

    this.heroTl.intro = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: this.peopleEl.querySelector(".people-section__intro"),
      },
    })

    this.heroTl.intro.fromTo(
      this.peopleEl.querySelector(".people-section__intro"),
      this.standardFrom,
      this.standardTo,
      0
    )

    this.heroTl.subintro = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: this.peopleEl.querySelector(
          ".people-section__subintro-wrapper"
        ),
      },
    })

    this.heroTl.subintro.fromTo(
      this.peopleEl.querySelector(".people-section__subintro-wrapper"),
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
      },
      0
    )
  }

  initManagement() {
    this.destroyManagement()
    const managementEl = document.getElementById("people-management")
    this.managementTl.heading = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: managementEl.querySelector("h2"),
      },
    })

    this.managementTl.heading.fromTo(
      managementEl.querySelector("h2"),
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
      },
      0
    )

    const featuredPersonEl = managementEl.querySelector(".featured-person")
    if (featuredPersonEl) {
      this.managementTl.featuredPerson = gsap.timeline({
        scrollTrigger: {
          ...this.configDefault,
          trigger: featuredPersonEl,
          start: "top-=100px center",
        },
      })
      this.managementTl.featuredPerson.fromTo(
        featuredPersonEl.querySelector(".img-wrapper"),
        {
          opacity: 0,
          y: 20,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.75,
        },
        0
      )

      this.managementTl.featuredPerson.fromTo(
        featuredPersonEl.querySelector(".featured-person__details"),
        {
          opacity: 0,
          y: 20,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.75,
        },
        0.25
      )
    }

    // gsap.set(".management-figure", { y: 20 })
    // this.managementPeople = ScrollTrigger.batch(".management-figure", {
    //   interval: 0.1, // time window (in seconds) for batching to occur.
    //   // batchMax: 3,   // maximum batch size (targets). Can be function-based for dynamic values
    //   onEnter: (batch) =>
    //     gsap.to(batch, {
    //       opacity: 1,
    //       y: 0,
    //       duration: 0.75,
    //       stagger: { each: 0.15, grid: [1, 3] },
    //       overwrite: true,
    //     }),
    //   onLeaveBack: (batch) =>
    //     gsap.to(batch, { opacity: 0, y: 20, overwrite: true }),
    //   // you can also define most normal ScrollTrigger values like start, end, etc.
    //   start: "top bottom-=50px",
    //   scroller: this.configDefault.scroller,
    // })

    // ScrollTrigger.addEventListener("refreshInit", () =>
    //   gsap.set(".management-figure", { y: 0 })
    // )
  }

  initSenior() {
    const seniorEl = document.getElementById("people-leaders")
    this.seniorTl.heading = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: seniorEl.querySelector("h2"),
      },
    })

    this.seniorTl.heading.fromTo(
      seniorEl.querySelector("h2"),
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
      },
      0
    )

    // gsap.set(".senior-leaders__person", { opacity: 0 })
    // this.seniorPeople = ScrollTrigger.batch(".senior-leaders__person", {
    //   interval: 0.1, // time window (in seconds) for batching to occur.
    //   // batchMax: 3,   // maximum batch size (targets). Can be function-based for dynamic values
    //   onEnter: (batch) =>
    //     gsap.to(batch, {
    //       opacity: 1,
    //       duration: 0.75,
    //       overwrite: true,
    //     }),
    //   onLeaveBack: (batch) => gsap.to(batch, { opacity: 0, overwrite: true }),
    //   // you can also define most normal ScrollTrigger values like start, end, etc.
    //   start: "top bottom",
    //   scroller: this.configDefault.scroller,
    // })
    // ScrollTrigger.addEventListener("refreshInit", () =>
    //   gsap.set(".senior-leaders__person", { opacity: 0, paddingTop: "10px" })
    // )
  }

  destroyManagement() {
    Object.keys(this.managementTl).forEach((tlKey) => {
      this.managementTl[tlKey].kill()
    })
    if (this.managementPeople) {
      this.managementPeople.forEach((personTl) => {
        personTl.kill()
      })
    }
  }

  destroySenior() {
    Object.keys(this.seniorTl).forEach((tlKey) => {
      this.seniorTl[tlKey].kill()
    })
    if (this.seniorPeople) {
      this.seniorPeople.forEach((personTl) => {
        personTl.kill()
      })
    }
  }

  initWorkingAt() {
    const workingEl = document.getElementById("people-working-at")
    this.workingTl.heading = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: workingEl.querySelector("h2"),
      },
    })

    this.workingTl.heading.fromTo(
      workingEl.querySelector("h2"),
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
      },
      0
    )

    this.workingTl.subheading = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: workingEl.querySelector("h3"),
      },
    })

    this.workingTl.subheading.fromTo(
      workingEl.querySelector("h3"),
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
      },
      0
    )

    this.workingTl.pullquote = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: workingEl.querySelector(".pullquote"),
      },
    })

    this.workingTl.pullquote.fromTo(
      workingEl.querySelector(".pullquote"),
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
      },
      0
    )

    const jobsIntroEl = workingEl.querySelector(".working-at__open-roles-intro")
    this.workingTl.jobsIntro = gsap.timeline({
      scrollTrigger: { ...this.configDefault, trigger: jobsIntroEl },
    })

    this.workingTl.jobsIntro.fromTo(
      jobsIntroEl.querySelector("h3"),
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
      },
      0
    )
    this.workingTl.jobsIntro.fromTo(
      jobsIntroEl.querySelector("p"),
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
      },
      0.125
    )

    this.workingTl.openRoles = gsap.timeline({
      scrollTrigger: {
        ...this.configDefault,
        trigger: workingEl.querySelector(".open-roles"),
      },
    })

    this.workingTl.openRoles.fromTo(
      workingEl.querySelector(".open-roles"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.75,
      },
      0
    )

    gsap.set(".swiper-slide", { y: 10 })
    this.workingSlides = ScrollTrigger.batch(".swiper-slide", {
      interval: 0.1, // time window (in seconds) for batching to occur.
      // batchMax: 3,   // maximum batch size (targets). Can be function-based for dynamic values
      onEnter: (batch) =>
        gsap.to(batch, {
          opacity: 1,
          y: 0,
          duration: 0.75,
          stagger: { each: 0.15, grid: [1, 2] },
          overwrite: true,
        }),
      onLeaveBack: (batch) =>
        gsap.to(batch, {
          opacity: 0,
          y: 10,
          duration: 0.75,
          stagger: { each: 0.15, grid: [1, 2] },
          overwrite: true,
        }),
      // you can also define most normal ScrollTrigger values like start, end, etc.
      start: "top bottom",
      scroller: this.configDefault.scroller,
    })

    gsap.set(".text-column", { y: 10 })
    this.workingColumns = ScrollTrigger.batch(".text-column", {
      interval: 0.1, // time window (in seconds) for batching to occur.
      // batchMax: 3,   // maximum batch size (targets). Can be function-based for dynamic values
      onEnter: (batch) =>
        gsap.to(batch, {
          opacity: 1,
          y: 0,
          duration: 0.75,
          stagger: { each: 0.15, grid: [1, 3] },
          overwrite: true,
        }),
      onLeaveBack: (batch) =>
        gsap.to(batch, { opacity: 0, y: 10, duration: 0.75, overwrite: true }),
      // you can also define most normal ScrollTrigger values like start, end, etc.
      start: "top bottom",
      scroller: this.configDefault.scroller,
    })
    // ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".carousel-slide", {opacity: 0, y:10}));
  }

  destroyWorkingAt() {
    Object.keys(this.workingTl).forEach((tlKey) => {
      this.workingTl[tlKey].kill()
    })
    if (this.workingJobs) {
      this.workingJobs.forEach((tl) => {
        tl.kill()
      })
    }
  }

  destroyHero() {
    Object.keys(this.heroTl).forEach((tlKey) => {
      this.heroTl[tlKey].kill()
    })
  }
}
export default PeopleAnimations
