import gsap from "gsap"

export default {
  toggle: ({ isOpen, contentEl, mainEl, navEl, secondaryEl, tabsEl }) => {
    const tl = gsap.timeline({ repeat: false })

    const distance = isOpen ? "100vw" : "0"
    const opacity = isOpen ? 1 : 0
    const x = isOpen ? 0 : -(window.innerWidth / 4)

    tl.to(mainEl.current, {
      duration: 0.5,
      left: distance,
    })
      .to(
        navEl.current,
        {
          duration: 0.5,
          width: distance,
        },
        0
      )
      .to(
        contentEl.current,
        {
          duration: 0.15,
          opacity,
        },
        0
      )
      .to(
        tabsEl.current.children,
        {
          duration: 0.3,
          x,
          stagger: {
            each: 0.04,
            repeat: false,
          },
        },
        0
      )

    if (window.innerWidth <= 768) {
      tl.to(
        secondaryEl.current.children,
        {
          duration: 0.3,
          x,
          stagger: {
            each: 0.04,
            repeat: false,
          },
        },
        0.12
      )
    }
    return null
  },
}
