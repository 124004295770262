import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import gsap from "gsap"
import { PrismicText } from "@prismicio/react"
import { asText } from "@prismicio/helpers"

import closeIcon from "../../../images/icons/close-white.svg"
import closeBlackIcon from "../../../images/icons/close.svg"

import "./ModalNav.scss"

function ModalNav({
  items,
  labelIndex,
  setCurrentIndex,
  closeModal,
  isExpertise,
  isGoods,
  isPeople,
  isSearch,
  disabled,
}) {
  const transition = useRef(null)
  const index = useRef(labelIndex)
  const [prevLabel, setPrevLabel] = useState(null)
  const [nextLabel, setNextLabel] = useState(null)
  const [changing, setChanging] = useState(false)
  const [incomingPrev, setIncomingPrev] = useState(null)
  const [incomingNext, setIncomingNext] = useState(null)
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)

  useEffect(() => {
    if (!transition.current && labelIndex !== null) {
      const transTL = gsap.timeline({
        onComplete: () => {
          setNextLabel(
            index.current < items.length - 1 ? items[index.current + 1] : null
          )
          setPrevLabel(index.current > 0 ? items[index.current - 1] : null)
          transition.current.seek(0)
          transition.current.pause()
        },
      })

      transTL.fromTo(
        "#next-current",
        {
          opacity: 1,
          y: 0,
        },
        {
          opacity: 0,
          y: 5,
          duration: 0.25,
        },
        0
      )
      transTL.fromTo(
        "#next-incoming",
        {
          opacity: 0,
          y: 5,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.25,
        },
        0.5
      )

      transTL.fromTo(
        "#prev-current",
        {
          opacity: 1,
          y: 0,
        },
        {
          opacity: 0,
          y: 5,
          duration: 0.25,
        },
        0
      )

      transTL.fromTo(
        "#prev-incoming",
        {
          opacity: 0,
          y: 5,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.25,
        },
        0.5
      )

      transition.current = transTL
    }
  })

  useEffect(() => {
    if (labelIndex !== null) {
      setChanging(true)
      index.current = labelIndex
      setPrevDisabled(labelIndex === 0 || items[labelIndex - 1] == null)
      setNextDisabled(labelIndex >= items.length - 1)
      setIncomingPrev(labelIndex > 0 ? items[labelIndex - 1] : null)
      setIncomingNext(
        labelIndex < items.length - 1 ? items[labelIndex + 1] : null
      )
      transition.current.play(0)
      setTimeout(() => {
        setChanging(false)
      }, 1000)
    }
  }, [labelIndex])
  return (
    <nav
      className={classNames("modal-nav", {
        "modal-nav--expertise": isExpertise,
        "modal-nav--goods": isGoods,
        "modal-nav--people": isPeople,
        "modal-nav--disabled":
          !isSearch && (disabled || (nextDisabled && prevDisabled) || isGoods),
      })}
    >
      <div className="modal-nav__inner-wrapper">
        <div className="modal-nav__inner">
          <button
            type="button"
            className="modal-nav__prev"
            disabled={prevDisabled}
            onClick={() => !changing && setCurrentIndex(labelIndex - 1)}
          >
            <svg
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 13L2 7L8 1" stroke="white" strokeWidth="1.5" />
            </svg>
            <span className="current" id="prev-current">
              {isGoods && "Previous Article"}
              {isExpertise
                ? prevLabel && prevLabel.title
                : prevLabel && asText(prevLabel.name)}
            </span>
            <span className="incoming" id="prev-incoming">
              {isGoods && "Previous Article"}
              {isExpertise
                ? incomingPrev && incomingPrev.title
                : incomingPrev && asText(incomingPrev.name)}
            </span>
          </button>
          <button
            type="button"
            className="modal-nav__close"
            onClick={closeModal}
          >
            <img src={isSearch ? closeBlackIcon : closeIcon} alt="Close" />
          </button>
          <button
            type="button"
            className="modal-nav__next"
            disabled={nextDisabled}
            onClick={() => !changing && setCurrentIndex(labelIndex + 1)}
          >
            <span className="current" id="next-current">
              {isGoods && "Next Article"}
              {isExpertise
                ? nextLabel && nextLabel.title
                : nextLabel && <PrismicText field={nextLabel.name} />}
            </span>
            <span className="incoming" id="next-incoming">
              {isGoods && "Next Article"}
              {isExpertise
                ? incomingNext && incomingNext.title
                : incomingNext && <PrismicText field={incomingNext.name} />}
            </span>
            <svg
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L7 7L0.999998 13" stroke="white" strokeWidth="1.5" />
            </svg>
          </button>
        </div>
      </div>
    </nav>
  )
}

export default ModalNav
