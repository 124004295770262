import React, { useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import animations from "./animations"

import Logo from "../Logo"
import Modal from "../Modal"
import SignUp from "./SignUp"

import Contact from "../auxilllary/Contact"

import utaMedialinkLogo from "../../images/UTA_MEDIALINK.png"

import "./index.scss"

const Navigation = ({
  children,
  isOpen,
  mainEl,
  selectTab,
  tabs,
  toggleNav,
}) => {
  const contentEl = useRef(null)
  const navEl = useRef(null)
  const prevScroll = useRef(null)

  const secondaryEl = useRef(null)
  const tabsEl = useRef(null)

  const [hoveredTab, setHoveredTab] = useState(null)
  const [modalContent, setModalContent] = useState(null)

  const utilities = [
    "Privacy Policy",
    "Terms of Use",
    "Cookie Consent",
    "Client Notice",
  ]

  useEffect(() => {
    if (!mainEl || !navEl || !contentEl || !tabsEl) return

    animations.toggle({
      isOpen,
      contentEl,
      mainEl,
      navEl,
      secondaryEl,
      tabsEl,
    })
    if (!isOpen) setModalContent(null)
  }, [isOpen, mainEl])

  const handleTabClick = (tab, e) => {
    e.preventDefault()
    toggleNav()
    selectTab(tab)
  }

  const focusTab = (tabId, e) => {
    e.preventDefault()
    setHoveredTab(tabId)
  }

  const handleUtilityNavItem = (type) => {
    let auxModalContent
    switch (type) {
      case utilities[0]:
        // auxModalContent = <PrivacyPolicy />;
        navigate("/privacy-policy")
        break
      case utilities[1]:
        // auxModalContent = <Terms handleUtilityNavItem={handleUtilityNavItem} />;
        navigate("/terms-of-service")
        break
      case utilities[2]:
        // auxModalContent = <Cookies />;
        navigate("/cookie-policy")
        break
      case utilities[3]:
        // auxModalContent = <Cookies />;
        navigate("/client-privacy-policy")
        break
      default:
        break
    }
    toggleNav()
    setModalContent(auxModalContent)
  }

  useEffect(() => {
    if (window.innerWidth >= 768) return
    if (isOpen) {
      prevScroll.current = window.scrollY
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
      window.scrollTo(0, prevScroll.current)
    }
  }, [isOpen])

  return (
    <div className="main-nav" ref={navEl}>
      <div className="main-nav-hamburger">{children}</div>
      <Logo fill="#ffffff" />

      <div className="main-nav-container">
        <div className="main-nav-content" ref={contentEl}>
          <div className="main-nav-flex top">
            <div className="main-nav-col-left">
              <ul className="main-nav-tabs" ref={tabsEl}>
                {tabs.map((tab) => {
                  const { id, title } = tab
                  return (
                    <li key={id}>
                      <a
                        onMouseEnter={focusTab.bind(this, id)}
                        onMouseLeave={focusTab.bind(this, null)}
                        className={classNames("", {
                          translucent: hoveredTab,
                          emphasize: hoveredTab && hoveredTab === id,
                        })}
                        href={`#${id}`}
                        onClick={handleTabClick.bind(this, tab)}
                      >
                        {title}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="main-nav-col-right">
              <ul className="main-nav-secondary" ref={secondaryEl}>
                <li>
                  <button
                    onClick={() => setModalContent(<Contact />)}
                    type="button"
                  >
                    Contact Us
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={(e) => {
                      window.goToWorkingAt = true
                      handleTabClick(
                        {
                          id: "people",
                          path: "/people",
                          title: "People",
                          hash: "#people-working-at",
                        },
                        e
                      )
                    }}
                  >
                    Careers
                  </button>
                </li>
                <li>
                  <button type="button">
                    <a
                      target="_blank"
                      href="https://www.unitedtalent.com/"
                      rel="noreferrer"
                    >
                      About UTA
                    </a>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="main-nav-flex bottom">
            <div className="main-nav-col-left">
              <img
                src={utaMedialinkLogo}
                alt="UTA MediaLink"
                className="footerMedialinkLogo"
              />
              <SignUp isOpen={isOpen} />
            </div>
            <div className="main-nav-col-right">
              <ul className="main-nav-utility">
                {utilities.map((u) => (
                  <li key={u}>
                    <button
                      type="button"
                      title={u}
                      onClick={() => handleUtilityNavItem(u)}
                    >
                      {u}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal closeModal={() => setModalContent(null)} content={modalContent} />
    </div>
  )
}

export default Navigation
