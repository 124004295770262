import React from "react"

const SliceImage = ({ slice }) => (
  <div className="slice-image">
    <div
      className={`slice-image__wrapper slice-image__wrapper--${slice.primary.image_size.toLowerCase()} ${
        slice.primary.overflow ? "slice-image__wrapper--overflow" : ""
      }`}
    >
      <img
        className="slice-image__img"
        src={slice.primary.image.url}
        alt={slice.primary.image.alt}
      />
      {slice.primary.caption.text}
    </div>
  </div>
)

export default SliceImage
