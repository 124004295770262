import React from "react"
import FooterForm from "../FooterForm"
import "./index.scss"

const SignUp = ({ isOpen }) => (
  <div className="main-nav-signup">
    <strong>Get the Goods</strong>
    <p>
      Sign up for our newsletter. Insights and updates delivered to your inbox.
    </p>
    <div className="main-nav-input">
      <FooterForm hideForm={!isOpen} footerId="signup" />
    </div>
  </div>
)

export default SignUp
