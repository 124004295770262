import React, { useState } from "react"
import Form from "../common/Form"

import "./FooterForm.scss"

import successIcon from "../../images/icons/footer-success-icon.svg"

const formGroups = [
  {
    keys: ["Email"],
  },
]

function FooterForm({ hideForm, footerId }) {
  const [success, setSuccess] = useState(false)
  const [submitText, setSubmitText] = useState("Subscribe")

  const handleSubmission = (status) => {
    setSubmitText(status === "submitting" ? "Subscribing" : "Subscribe")
    setSuccess(status === "success")
  }

  return (
    <div className="footer-form">
      {success ? (
        <p className="success">
          YOU&apos;VE SUBSCRIBED SUCCESSFULLY
          <img alt="success" src={successIcon} />
        </p>
      ) : (
        <>
          {!hideForm && (
            <Form
              formConfig={["//592-QPU-703.mktoweb.com", "592-QPU-703", 1020]}
              formId={`${footerId}-footer`}
              submitText={submitText}
              groups={formGroups}
              setFormStatus={handleSubmission}
            />
          )}
        </>
      )}
    </div>
  )
}

export default FooterForm
