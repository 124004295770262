import React from "react"
import AnimatedNumber from "../../common/AnimatedNumber"

import "./Numbers.scss"

const Numbers = () => (
  <section className="numbers">
    <AnimatedNumber
      id="50%"
      numbers={[
        {
          number: "50%",
          caption:
            "of <i>Fortune</i>’s World’s Most Admired Companies turn to us for strategic advisory.",
        },
        {
          number: "$22B+",
          caption: "in agency contracts managed since the beginning of 2020.",
        },
      ]}
      scroller="expertise-tab-body"
      displayWhenVisible={false}
      size="big"
      sizing={{ type: "dynamic", maxWidth: "100%" }}
    />

    <div className="numbers__small">
      <div className="numbers__small-wrapper">
        <AnimatedNumber
          id="50B+"
          numbers={[
            {
              number: "$50B+",
              caption:
                "in contemplated merger and acquisition deals advised on in the last two years.",
            },
          ]}
          displayWhenVisible={{ offset: 5 }}
          size="small"
          sizing={{ type: "left", maxWidth: "100%" }}
          scroller="expertise-tab-body"
        />
      </div>
      <div className="numbers__small-wrapper">
        <AnimatedNumber
          id="7/10"
          numbers={[
            {
              number: "7/10",
              caption:
                "of the top 10 media companies in the world work with us.",
            },
          ]}
          displayWhenVisible={{ offset: 10 }}
          size="small"
          sizing={{ type: "left", maxWidth: "100%" }}
          scroller="expertise-tab-body"
        />
      </div>
    </div>
  </section>
)

export default Numbers
