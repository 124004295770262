/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import { PrismicText } from "@prismicio/react"
import { asText } from "@prismicio/helpers"
import "./index.scss"

const FeaturedPerson = ({ person, openModal, others, inEvent }) => (
  <div className="featured-person" onClick={inEvent && openModal}>
    <div className="img-wrapper">
      <div
        className="img-sizer"
        style={{
          paddingTop: `${
            inEvent
              ? 87.5
              : (person.image.dimensions.height /
                  person.image.dimensions.width) *
                100
          }%`,
        }}
      />
      <img src={person.image.url} alt={person.image.alt} />
    </div>
    <div className="featured-person__details">
      <div className="featured-person__details-nameposition">
        <span className="name">
          <PrismicText field={person.name} />
        </span>
        <span className="position">
          <PrismicText field={person.position} />
        </span>
        {inEvent && (
          <span className="company">
            {person.company ? (
              <PrismicText field={person.company} />
            ) : (
              "MediaLink"
            )}
          </span>
        )}
      </div>
      {person.bio && !inEvent && (
        <div>
          <p>
            {asText(person.bio).split(" ").slice(0, 100).join(" ")}
            ...&nbsp;
            <button
              type="button"
              className="read-more"
              onClick={() => openModal([person, ...others], 0)}
            >
              Read More.
            </button>
          </p>
        </div>
      )}
    </div>
  </div>
)

export default FeaturedPerson
