// defining the State
const initialState = {
  secondaryModalOpen: false,
  secondaryModalClose: false,
  event: null,
  searchOpen: false,
  viewAllData: null,
  fullScreenVideo: false,
  videoPlayer: null,
  visibleEvent: null,
}

// TYPES
const SET_SECONDARY_MODAL_OPEN = "SET_SECONDARY_MODAL_OPEN"
const SET_SECONDARY_MODAL_CLOSE = "SET_SECONDARY_MODAL_CLOSE"
const SET_EVENT = "SET_EVENT"
const SET_SEARCH_OPEN = "SET_SEARCH_OPEN"
const SET_FULL_SCREEN_VIDEO = "SET_FULL_SCREEN_VIDEO"
const SET_VIEWALL_DATA = "SET_VIEWALL_DATA"
const SET_VIDEO_PLAYER = "SET_VIDEO_PLAYER"
const RESET_VIEWALL_DATA = "RESET_VIEWALL_DATA"
const SET_VISIBLE_EVENT = "SET_VISIBLE_EVENT"
// ACTIONS
export const setSecondaryModalOpen = (status) => ({
  type: SET_SECONDARY_MODAL_OPEN,
  payload: status,
})
export const setCloseSecondary = (status) => ({
  type: SET_SECONDARY_MODAL_CLOSE,
  payload: status,
})
export const setEvent = (event) => ({
  type: SET_EVENT,
  payload: event,
})
export const setSearchOpen = (isOpen) => ({
  type: SET_SEARCH_OPEN,
  payload: isOpen,
})
export const setViewAllData = (data) => ({
  type: SET_VIEWALL_DATA,
  payload: data,
})
export const resetViewAllData = () => ({
  type: RESET_VIEWALL_DATA,
})
export const setFullScreenVideo = (isFullScreen) => ({
  type: SET_FULL_SCREEN_VIDEO,
  payload: isFullScreen,
})
export const setVideoPlayer = (player) => ({
  type: SET_VIDEO_PLAYER,
  payload: player,
})
export const setVisibleEvent = (isVisible) => ({
  type: SET_VISIBLE_EVENT,
  payload: isVisible,
})

// SELECTORS
export const selectSecondaryModalStatus = (state) =>
  state.utils.secondaryModalOpen
export const selectSecondaryModalClose = (state) =>
  state.utils.secondaryModalClose
export const selectEvent = (state) => state.utils.event
export const selectSearchOpen = (state) => state.utils.searchOpen
export const selectViewAllData = (state) => state.utils.viewAllData
export const selectFullScreenVideo = (state) => state.utils.fullScreenVideo
export const selectVideoPlayer = (state) => state.utils.videoPlayer
export const selectVisibleEvent = (state) => state.utils.visibleEvent

// REDUCER
const utilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VISIBLE_EVENT:
      return {
        ...state,
        visibleEvent: action.payload,
      }
    case SET_VIDEO_PLAYER:
      return {
        ...state,
        videoPlayer: action.payload,
      }
    case RESET_VIEWALL_DATA:
      return {
        ...state,
        viewAllData: null,
      }
    case SET_VIEWALL_DATA:
      return {
        ...state,
        viewAllData: action.payload,
      }
    case SET_SEARCH_OPEN:
      return {
        ...state,
        searchOpen: action.payload,
      }
    case SET_SECONDARY_MODAL_OPEN:
      return {
        ...state,
        secondaryModalOpen: action.payload,
      }
    case SET_EVENT:
      return {
        ...state,
        event: action.payload,
      }
    case SET_FULL_SCREEN_VIDEO:
      return {
        ...state,
        fullScreenVideo: action.payload,
      }
    case SET_SECONDARY_MODAL_CLOSE:
      return {
        ...state,
        secondaryModalClose: action.payload,
        secondaryModalOpen: false,
      }
    default:
      return state
  }
}

export default utilsReducer
