import React, { useEffect, useState, useRef } from "react"
import classnames from "classnames"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import HoverTooltip from "../../common/HoverTooltip"

import "./Management.scss"
import FeaturedPerson from "../FeaturedPerson"
import Manager from "../Manager"

gsap.registerPlugin(ScrollTrigger)

const PeopleManagement = ({ ceo, managers, openModal }) => {
  const [figureSizing, setFigureSizing] = useState({
    width: "100%",
    maxWidth: "calc(33.333% - 46.6667px)",
    margin: "0 10px",
  })
  const [rowCount, setRowCount] = useState(3)
  const lastCount = useRef(3)
  const managementFigures = useRef({})
  const managementAnimations = useRef(null)
  const determineFigureSizing = () => {
    const newRowCount = window.innerWidth < 768 ? 2 : 3
    if (lastCount.current !== newRowCount) {
      lastCount.current = newRowCount
      setFigureSizing(
        window.innerWidth < 1024
          ? {
              width: "100%",
              maxWidth: "calc(50% - 4px)",
              margin: "0",
            }
          : {
              width: "100%",
              maxWidth: "calc(33.333% - 46.6667px)",
              margin: "0 10px",
            }
      )
      setRowCount(newRowCount)
    }
  }

  const killAnimations = () => {
    if (managementAnimations.current) {
      managementAnimations.current.forEach((tl) => {
        tl.kill()
      })
    }
  }

  useEffect(() => {
    killAnimations()
    if (
      managers &&
      Object.keys(managementFigures.current).length === managers.length
    ) {
      // setTimeout(() => {

      gsap.set(".management-figure", { y: 20 })
      managementAnimations.current = ScrollTrigger.batch(".management-figure", {
        interval: 0.1, // time window (in seconds) for batching to occur.
        // batchMax: 3,   // maximum batch size (targets). Can be function-based for dynamic values
        onEnter: (batch) =>
          gsap.to(batch, {
            opacity: 1,
            y: 0,
            duration: 0.75,
            stagger: { each: 0.15, grid: [1, 3] },
            overwrite: true,
          }),
        onLeaveBack: (batch) =>
          gsap.to(batch, { opacity: 0, y: 20, overwrite: true }),
        // you can also define most normal ScrollTrigger values like start, end, etc.
        start: "top bottom-=50px",
        scroller: window.innerWidth >= 768 ? "#people-tab-body" : null,
      })

      ScrollTrigger.addEventListener("refreshInit", () =>
        gsap.set(".management-figure", { y: 0 })
      )
      // }, 500);
    }
  })

  useEffect(() => {
    determineFigureSizing()
    window.addEventListener("resize", determineFigureSizing, { passive: true })
    return () => {
      window.removeEventListener("resize", determineFigureSizing, {
        passive: true,
      })
    }
  })
  return (
    <div id="people-management" className={classnames("people-management", {})}>
      <h2>Management</h2>
      <div className="people-management__people">
        {ceo && (
          <FeaturedPerson
            person={ceo}
            openModal={openModal}
            others={managers}
          />
        )}
        <div className="management-rows">
          {managers &&
            [...Array(Math.ceil(managers.length / rowCount))].map((_, i) => (
              <div key={`mr-${i}`} className="management-row">
                {managers
                  .slice(rowCount * i, rowCount * (i + 1))
                  .map((manager, pi) => (
                    <HoverTooltip
                      text="Read Full Bio"
                      style={figureSizing}
                      key={`mrf${manager.id}`}
                    >
                      <button
                        label="Read Full Bio"
                        className="management-figure"
                        type="button"
                        ref={(button) => {
                          managementFigures.current[manager.id] = button
                        }}
                        onClick={() => {
                          openModal([ceo, ...managers], rowCount * i + (pi + 1))
                        }}
                      >
                        <Manager person={manager} />
                      </button>
                    </HoverTooltip>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
export default PeopleManagement
