import React from "react"
import "./ContainedButton.scss"

function ContainedButton({ outlink, text, type = "button", onClick }) {
  return (
    <button
      type={type === "submit" ? "submit" : "button"}
      className="contained-button"
      onClick={onClick || (() => {})}
    >
      <span>{text}</span>
      {outlink && (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9927 1.24255L1.36768 12.1177M11.9927 1.24255L3.50746 1.24254M11.9927 1.24255L11.9927 9.72783"
            stroke="white"
            strokeWidth="1.5"
          />
        </svg>
      )}
    </button>
  )
}

export default ContainedButton
