/* eslint-disable no-nested-ternary */
import React from "react"
import { PrismicText } from "@prismicio/react"
import { isMobile } from "react-device-detect"
import classNames from "classnames"
import moment from "moment"
import Icon from "./Icon"

import mediaUrlArrow from "../../images/icons/media-url-arrow.svg"

import { returnFormattedLink, truncateTitle } from "./helpers"

import "./MediaBlock.scss"

const MediaBlock = ({
  thumbnail,
  externalLink,
  mediaType,
  tag,
  title,
  description,
  isLarge,
  onClick,
  date,
}) => {
  const handleRedirect = () => {
    if (externalLink) {
      return window.open(externalLink, "_blank")
    }
    return onClick()
  }
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={handleRedirect}
      onClick={handleRedirect}
      className={classNames("mediaBlockWrapper", {
        wrapperLarge: isLarge,
      })}
      title={date ? moment(date).format("MMMM DD, YYYY") : null}
    >
      <div className="mediaBlockHeader">
        <div className="mediaBlockInfo">
          <p>{tag}</p>
          {(mediaType === "video" || mediaType === "podcast") && (
            <Icon type={mediaType} />
          )}
        </div>
        {thumbnail && <img alt="media-thumbnail" src={thumbnail} />}
      </div>
      <div className="mediaBlockBody">
        <h2>
          {truncateTitle(title, isLarge && !isMobile ? title.length : 60)}
        </h2>
        {description && (
          <p>
            <PrismicText field={description} />
          </p>
        )}
      </div>
      {externalLink && (
        <span>
          {returnFormattedLink(externalLink)}
          <img alt="url-arrow" src={mediaUrlArrow} />
        </span>
      )}
    </div>
  )
}

export default MediaBlock
