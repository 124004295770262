/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from "react"
import ReactPlayer from "react-player"
import classNames from "classnames"

const SliceVimeo = ({ slice }) => {
  const [playing, setPlaying] = useState(false)
  const vimeoPlayer = useRef(null)
  const vimeoReady = (player) => {
    vimeoPlayer.current = player
  }

  useEffect(() => {
    try {
      if (window.__vimeoRefresh) {
        setTimeout(() => {
          window.__vimeoRefresh()
        }, 3000)
      }
    } catch (e) {
      console.log("error refreshing video tracking")
    }
  }, [])
  return (
    <div
      className={`
        slice-vimeo
        slice-vimeo--${slice.primary.embed_size.toLowerCase()} 
        ${slice.primary.overflow ? "slice-vimeo--overflow" : ""}
      `}
    >
      <div
        className="slice-vimeo__wrapper"
        style={{
          paddingTop: `${
            (slice.primary.embed.height / slice.primary.embed.width) * 100
          }%`,
        }}
      >
        <ReactPlayer
          className="react-player"
          url={slice.primary.embed.embed_url}
          controls
          onPause={() => setPlaying(false)}
          onEnded={() => setPlaying(false)}
          onReady={vimeoReady}
          playing={playing}
          width="100%"
          height="100%"
        />
        <button
          type="button"
          className={classNames("slice-vimeo__poster", {
            "slice-vimeo__poster--playing": playing,
          })}
          style={{
            backgroundImage: `url(${
              slice.primary.poster_image.url ||
              slice.primary.embed.thumbnail_url
            })`,
          }}
          onClick={() => setPlaying(true)}
        >
          <svg
            className="slice-vimeo__play-button"
            width="65"
            height="65"
            viewBox="0 0 65 65"
            fill="none"
          >
            <circle
              cx="32.3089"
              cy="32.7406"
              r="31.1371"
              fill="white"
              stroke="white"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M38.3125 32.7402L29.3125 37.9364L29.3125 27.5441L38.3125 32.7402Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <h3>{slice.primary.title || slice.primary.embed.title}</h3>
      {slice.primary.description && (
        <div className="vimeo-description">
          {slice.primary.description.text}
        </div>
      )}
    </div>
  )
}

export default SliceVimeo
