import { useLocation } from "@reach/router"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"

import { selectEvent } from "../../state/reducers/utils"

import Hero from "./Hero"
import Footer from "../Footer/Footer"
import GetInTouch from "./GetInTouch"
import Services from "./Services"
import Numbers from "./Numbers"
import WhatWeDo from "./WhatWeDo"

import ExpertiseAnimations from "./animations"

import "./Expertise.scss"

const Expertise = ({
  setModalContent,
  showContactForm,
  goToNextSection,
  selected,
  showNav,
  setServiceOpened,
  onSelect,
}) => {
  const location = useLocation()
  const event = useSelector(selectEvent)
  const [serviceLink, setServiceLink] = useState("")
  const [animationsSet, setAnimationsSet] = useState(false)
  const [animations, setAnimations] = useState(false)
  const heroRef = useRef(null)

  useEffect(() => {
    const service = location.hash
    setServiceLink(service)
  }, [location.hash])

  useEffect(() => {
    if (
      !selected &&
      document.getElementById("expertise-tab-body") &&
      window.innerWidth > 767
    ) {
      setTimeout(() => {
        document.getElementById("expertise-tab-body").scrollTo(0, 0)
      }, 500)
    }
  }, [selected])

  useEffect(() => {
    if (selected && heroRef.current && !animationsSet) {
      setAnimationsSet(true)
      setTimeout(
        () => {
          const expertiseAnimations = new ExpertiseAnimations()
          setAnimations(expertiseAnimations)
        },
        document.body.classList.contains("intro-finished") ? 0 : 500
      )
    } else if (animations && !heroRef.current) {
      animations.destroy()
      setAnimationsSet(false)
      setAnimations(null)
    }
  }, [selected])

  return (
    <div
      className={classNames("expertise", {
        "expertise--has-event": event && event.is_public,
      })}
      id="expertise-section"
    >
      <div className="expertise__inner">
        <Hero selected={selected} heroRef={heroRef} />
        <Services
          onSelect={onSelect}
          setModalContent={setModalContent}
          showContactForm={showContactForm}
          serviceLink={serviceLink}
          setServiceOpened={setServiceOpened}
        />
        <Numbers />
        <WhatWeDo />
        <GetInTouch showContactForm={showContactForm} />
      </div>
      <Footer
        nextSection="The People"
        onSectionChange={goToNextSection}
        showNav={showNav}
        selected={selected}
      />
    </div>
  )
}
export default Expertise
