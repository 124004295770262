import React, { useRef } from "react"
import classNames from "classnames"
import { osVersion, isMobileSafari, isSafari } from "react-device-detect"

import mlLogo from "../../../images/expertise/ml-logo.svg"
import arrowDownIcon from "../../../images/icons/arrow-down-icon.svg"
import PageHeader from "../../common/PageHeader"
import headerBG from "../../../images/shared/header-bg.webp"
import headerBGPNG from "../../../images/shared/header-bg.png"

import "./Hero.scss"

const Hero = ({ selected, heroRef }) => {
  const hero = useRef(null)
  return (
    <section className={classNames("expertise-hero")} ref={heroRef}>
      <PageHeader
        text="We’re the ones who write the playbook for your next winning move."
        selected={selected}
        background={
          isSafari || (isMobileSafari && osVersion.match(/13\.|12\./g))
            ? headerBGPNG
            : headerBG
        }
      />
      <div className="expertise-hero__intro" ref={hero}>
        <img alt="MediaLink" src={mlLogo} />
        <div>
          <img alt="Arrow down icon" src={arrowDownIcon} />
          <p>
            As the media &amp; marketing industry’s most trusted and connected
            advisor, we specialize in defining the optimal path forward. To put
            it simply, we take your unique business challenges and work
            side-by-side to identify areas of opportunity, optimization, and
            ultimately growth.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Hero
