import React from "react"

import FooterForm from "../../../FooterForm"

import facebookIcon from "../../../../images/icons/footer-facebook-icon.svg"
import linkedInIcon from "../../../../images/icons/footer-linkedin-icon.svg"
import twitterIcon from "../../../../images/icons/footer-twitter-icon.svg"

const CESFooter = () => (
  <footer className="footerWrapper">
    <div className="footerContainer">
      <div className="formContainer">
        <div>
          <p className="footerHeader">Get the Goods</p>
          <p>
            Consider our newsletter a white glove insight delivery service.
            Interested?
          </p>
          <FooterForm />
        </div>
      </div>
      <div className="socialContainer">
        <div>
          <p className="footerHeader">Let&apos;s get social</p>
          <div className="socialIcons">
            <a
              target="_blank"
              href="https://linkedin.com/company/medialink/"
              rel="noreferrer"
            >
              <img alt="linkedin" src={linkedInIcon} />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/MediaLinkLLC/"
              rel="noreferrer"
            >
              <img alt="facebook" src={facebookIcon} />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/medialink?lang=en"
              rel="noreferrer"
            >
              <img alt="twitter" src={twitterIcon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default CESFooter
