import React, { useEffect, useState } from "react"
import { asText, asLink } from "@prismicio/helpers"
import { useDispatch } from "react-redux"
import Prismic from "@prismicio/client"

import MediaBlock from "../../../MediaBlock/MediaBlock"

import "./Goods.scss"
import { setViewAllData } from "../../../../state/reducers/utils"

const client = Prismic.client(process.env.GATSBY_PRISMIC_API_ENDPOINT, {
  accessToken: process.env.GATSBY_PRISMIC_API_KEY,
})

function ModalGoods({ person, personId, openGoodModal, closeModal }) {
  const dispatch = useDispatch()
  const [currentId, setCurrentId] = useState(null)
  const [firstName, setFirstName] = useState()
  const [goods, setGoods] = useState()

  useEffect(() => {
    if (!goods) {
      client
        .query(
          Prismic.Predicates.in(
            "document.id",
            person.goods
              .filter((good) => good.media.id) // remove goods without valid media id
              .map((good) => good.media.id) // then map it to an array of ids
          )
        )
        .then((goodsData) => {
          setGoods(
            goodsData.results.map((gr, i) => {
              const data = { ...gr.data }
              const good = person.goods[i]
              const tag = good.media.tags[0]
              const { id } = good.media
              data.slugs = gr.slugs
              data.tag = tag
              data.id = id
              data.publishDateForSorting =
                gr.data.publish_date_override || gr.first_publication_date
              return data
            })
          )
        })
    }
  })

  useEffect(() => {
    if (person && !firstName) {
      const fullName = asText(person.name)
      setFirstName(fullName.split(" ")[0])
    }
  }, [person, firstName, personId, currentId])

  useEffect(() => {
    if (personId !== currentId) {
      setCurrentId(personId)
      setGoods(null)
      setFirstName(null)
    }
  }, [person])

  const viewAll = (e) => {
    dispatch(setViewAllData({ tagId: asText(person.name), type: "person" }))
    closeModal(e, true)
  }

  return (
    <div className="modal-goods">
      <h3>
        Goods From {firstName}
        <button type="button" onClick={viewAll}>
          View All
        </button>
      </h3>
      <div className="modal-goods__cards">
        {goods &&
          goods
            .slice(0, 2)
            .map((good, i) => (
              <MediaBlock
                isLarge={i === 1}
                key={good.id}
                mediaType={good.media_type}
                tag={good.filter_type}
                thumbnail={good.media_image.url}
                externalLink={asLink(good.external_link)}
                title={asText(good.title)}
                onClick={() => openGoodModal(good)}
              />
            ))}
      </div>
    </div>
  )
}

export default ModalGoods
