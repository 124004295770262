import React from "react"
import { PrismicText } from "@prismicio/react"

const SliceApplePodcast = ({ slice }) => {
  const url = slice.primary.embed.embed_url.replace(
    "podcasts.apple",
    "embed.podcasts.apple"
  )
  return (
    <div
      className={`
        slice-apple
        slice-apple--${slice.primary.embed_size.toLowerCase()} 
        ${slice.primary.overflow ? "slice-apple--overflow" : ""}
      `}
    >
      <div
        className="slice-apple__wrapper"
        style={{
          paddingTop: `${
            (slice.primary.embed.height / slice.primary.embed.width) * 100
          }%`,
        }}
      >
        <iframe
          title={slice.primary.embed.title}
          src={`${url}`}
          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
          allow="autoplay *; encrypted-media *;"
          frameBorder="0"
        />
      </div>
      <h3>
        <PrismicText
          field={slice.primary.title1 || slice.primary.embed.title}
        />
      </h3>
      {slice.primary.description && (
        <div className="apple-description">
          <PrismicText field={slice.primary.description} />
        </div>
      )}
    </div>
  )
}

export default SliceApplePodcast
