import React from "react"
import classNames from "classnames"
import { navigate } from "gatsby"
import { useSelector } from "react-redux"
import { selectEvent } from "../../state/reducers/utils"

import FooterForm from "../FooterForm"

import "./Footer.scss"

import facebookIcon from "../../images/icons/footer-facebook-icon.svg"
import linkedInIcon from "../../images/icons/footer-linkedin-icon.svg"
import twitterIcon from "../../images/icons/footer-twitter-icon.svg"
import utaMedialinkLogo from "../../images/UTA_MEDIALINK.png"

const utilities = [
  "Privacy Policy",
  "Terms of Use",
  "Cookie Consent",
  "Client Notice",
]

const handleUtilityNavItem = (type) => {
  switch (type) {
    case utilities[0]:
      navigate("/privacy-policy")
      break
    case utilities[1]:
      navigate("/terms-of-service")
      break
    case utilities[2]:
      navigate("/cookie-policy")
      break
    case utilities[3]:
      navigate("/client-privacy-policy")
      break
    default:
      break
  }
}

const Footer = ({ nextSection, onSectionChange, showNav, selected }) => {
  const event = useSelector(selectEvent)
  return (
    <footer
      className={classNames("footerWrapper", {
        "footerWrapper--has-event": event && event.is_public,
      })}
    >
      <FooterNextSection
        nextSection={nextSection}
        onSectionChange={onSectionChange}
      />
      <FooterContent showNav={showNav} selected={selected} />
    </footer>
  )
}

const FooterNextSection = ({ onSectionChange, nextSection }) => (
  <button type="button" onClick={onSectionChange} className="nextContainer">
    <p>Next</p>
    <p>{nextSection}</p>
  </button>
)

const FooterContent = ({ showNav, selected, footerId = "default" }) => (
  <div className="footerContainer">
    <div className="footerImageFormContainer">
      <img src={utaMedialinkLogo} alt="UTA MediaLink" />
      <div className="formContainer">
        <div>
          <p className="footerHeader">Get the Goods</p>
          <p>
            Consider our newsletter a white glove insight delivery service.
            Interested?
          </p>
          <FooterForm hideForm={showNav || !selected} footerId={footerId} />
        </div>
      </div>
    </div>
    <div className="socialContainer">
      <div>
        <p className="footerHeader">Let&apos;s get social</p>
        <div className="socialIcons">
          <a
            target="_blank"
            href="https://linkedin.com/company/medialink/"
            rel="noreferrer"
          >
            <img alt="linkedin" src={linkedInIcon} />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/MediaLinkLLC/"
            rel="noreferrer"
          >
            <img alt="facebook" src={facebookIcon} />
          </a>
          <a
            target="_blank"
            href="https://twitter.com/medialink?lang=en"
            rel="noreferrer"
          >
            <img alt="twitter" src={twitterIcon} />
          </a>
        </div>
      </div>
      <div className="legalLinks">
        <ul>
          {utilities.map((u) => (
            <li key={u}>
              <button
                type="button"
                title={u}
                onClick={() => handleUtilityNavItem(u)}
              >
                {u}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)

export default Footer
export { FooterNextSection, FooterContent }
