import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import { TimelineMax } from "gsap/all"

function ProgressBar({ count, currentIndex }) {
  const timeline = useRef(null)
  const progressEl = useRef(null)
  const progressTrack = useRef(null)
  const progressScrubber = useRef(null)

  const [timelineReady, setTimelineReady] = useState(false)

  useEffect(() => {
    if (!timelineReady || !progressEl.current) return
    const trackTL = gsap.timeline()
    const scrubTL = gsap.timeline()
    if (currentIndex >= 0) {
      trackTL.to(
        progressTrack.current,
        {
          maxHeight: progressEl.current.offsetHeight,
          opacity: 1,
          duration: 0.75,
        },
        0.25
      )
      scrubTL.to(
        progressScrubber.current,
        {
          maxHeight:
            progressEl.current.offsetHeight * ((currentIndex + 1) / count),
          opacity: 1,
          duration: 0.5,
        },
        0.5
      )
    } else if (currentIndex < 0) {
      trackTL.to(progressTrack.current, {
        maxHeight: 0,
        opacity: 0,
        duration: 0.5,
      })
      scrubTL.to(progressScrubber.current, {
        maxHeight: 0,
        opacity: 0,
        duration: 0.5,
      })
    }
  }, [currentIndex, progressEl, timelineReady])

  useEffect(() => {
    if (timeline.current) return
    timeline.current = new TimelineMax({
      paused: true,
      onComplete: () => {
        timeline.current.timeScale(1)
      },
    })
    setTimelineReady(true)
  }, [timeline])

  return (
    <div className="progressBar" ref={progressEl}>
      <div className="progressBarTrack" ref={progressTrack} />
      <div className="progressBarScrubber" ref={progressScrubber} />
    </div>
  )
}

export default ProgressBar
